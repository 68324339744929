import React from "react";
export default function ErrorMessageComponent({
  message,
  condition,
}: {
  message: string;
  condition: boolean;
}) {
  return (
    <div>{condition && <p className="text-red-600 text-xs">{message}</p>}</div>
  );
}
