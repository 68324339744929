import { TinitialState, TReducerAction } from "./types";

export const reducer = (
  globalState: TinitialState,
  action: TReducerAction
): TinitialState => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...globalState,
        isGlobalLoading: action.payload,
      };

    case "SET_FLOW_DATA":
      return {
        ...globalState,
        flowData: action.payload,
      };

    case "SET_CUSTOMER_DATA":
      return {
        ...globalState,
        customer: action.payload,
      };

    case "SET_EXPIRATION_DATE":
      return {
        ...globalState,
        expirationDate: action.payload,
      };

    case "SET_NEXT_STEP":
      return {
        ...globalState,
        currentStep: action.payload,
      };

    default:
      return globalState;
  }
};
