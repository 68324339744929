import React from 'react';
import { HiArrowLongRight } from 'react-icons/hi2';
import usePortalData from 'hooks/usePortalData';
import { useNavigate } from 'react-router-dom';
import useFlowInstance from 'hooks/useFlowInstance';
import useFlowData from 'hooks/useFlowData';
import { getSessionItem, setSessionItem } from 'utils/session';
import Spinner from 'components/atoms/Spinner';
import { isEmpty } from 'lodash';
import logo from '../../assets/images/logo-light.png';
import useReferralCodeServices from 'hooks/referralCodes/useReferralCodeServices';
import FlowSwitchComponent from './FlowSwitchComponent';

export default function Home() {
  const { flowData, customer } = usePortalData();

  const { createFlowInstance } = useFlowInstance();
  const navigate = useNavigate();
  // const { getData } = useFlowData(getSessionItem('flowId') || process.env.REACT_APP_DEFAULT_FLOW);
  const { getReferralCode } = useReferralCodeServices();
  const [referralCode, setReferralCode] = React.useState<any>({});

  React.useEffect(() => {
    const getData = async () => {
      let referralCode = sessionStorage.getItem('referralCode');
      if (referralCode) {
        const referralCodeData = await getReferralCode({
          query: referralCode,
          fields: 'type,value,customerId,customer',
        });
        setReferralCode(referralCodeData);
      }
    };

    getData();

    if(!getSessionItem('flowId')){
      if(process.env.REACT_APP_DEFAULT_FLOW){
        setSessionItem('flowId', process.env.REACT_APP_DEFAULT_FLOW);
      }
    }
  }, []);

  const handleStart = async (flow: any = {}) => {
    let payload: any = {};
    const currentFlow = isEmpty(flow) ? flowData : flow;
    payload.flowId = sessionStorage.getItem('flowId');

    let referralCode = sessionStorage.getItem('referralCode');
    let leadId = sessionStorage.getItem('leadId');
    let flowInstanceId = sessionStorage.getItem('flowInstanceId');

    if (leadId) {
      payload['leadId'] = leadId || '';
    }

    if (referralCode) {
      payload['referralCode'] = referralCode;
    }

    if(!flowInstanceId){
      const flowInstance = await createFlowInstance(payload);
      if (flowInstance?.flowInstanceId) {
        sessionStorage.setItem('flowInstanceId', flowInstance?.flowInstanceId);
      }
    } 
    navigate('/' + currentFlow?.pathOrder[0]);
  };

  return (
    <>
      <div className='h-full flex justify-center align-center justify-center items-center background_animation_brand '>
        {isEmpty(flowData) && (
          <div className='w-full'>
            <Spinner text='Estamos preparando tu nueva experiencia' />
          </div>
        )}
        {!isEmpty(flowData) && (
          <div className='w-full sm:w-3/12  m-6 '>
            <div className='w-full flex justify-center'>
              <img src={logo} alt='' width='50%' />
            </div>
            <br />
            <div>
              <br />
              <h4 className='text-center text-white text-2xl text-bold'>
                Bienvenido <strong>{customer?.firstName}</strong>
              </h4>
              <h6 className='text-center text-white'>
                a tu nueva experiencia de Internet.
              </h6>

              {getSessionItem('flowType') === 'neutral' && (
                <FlowSwitchComponent handleStart={handleStart} />
              )}

              {getSessionItem('flowType') !== 'neutral' && (
                <>
                  <p className='text-xs text-center  text-white'>
                    Haz click aquí para iniciar tu registro.
                    <button
                      disabled={!flowData?.flowId}
                      onClick={() => handleStart()}
                      className=' w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded primary-btn'
                    >
                      CONTINUAR <HiArrowLongRight className='ml-3 text-xl' />
                    </button>
                  </p>
                </>
              )}

              {!isEmpty(referralCode) && (
                <div>
                  <br />
                  <h4 className='text-center text-sm text-white'>
                    Agradecemos a{' '}
                    <strong>{referralCode?.customer?.firstName}</strong> por su
                    recomendación.
                  </h4>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
