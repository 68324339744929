import React, { useRef } from "react";
import { termsHtmlContent } from "utils/constants/terms";
export default function Terms() {
  const myComponentRef: any = useRef(null);
  React.useEffect(() => {
    if (myComponentRef.current) {
      myComponentRef.current.scrollTop = 0;
    }
  }, []);
  return (
    <div className="p-2 lg:p-10">
      <div dangerouslySetInnerHTML={{ __html: termsHtmlContent }}></div>
    </div>
  );
}
