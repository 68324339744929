import React from 'react'
import BasicCard from 'components/atoms/BasicCard'
import { InstanceOffer } from 'pages/Checkout/types/types'
import { HiOutlineCheck } from 'react-icons/hi2'

export default function ProductValidationCard({
  offer,
  paymentMethodType
}: {
  offer: InstanceOffer
  paymentMethodType: string
}) {
  const [price, setPrice] = React.useState<any>(0)
  React.useEffect(() => {
    const pricesData =
      offer?.stripeExpandOptions?.products[0]?.extraPriceData || null

    if (pricesData) {
      let pmPrice = pricesData.find(
        (item: any) => item.billingType === paymentMethodType
      )

      let amount = pmPrice?.tiers[0]?.unitAmount || 0

      setPrice(amount)
      return
    }

    setPrice(offer?.stripeExpandOptions?.prices?.grossAmount || 0)
  }, [paymentMethodType, offer])

  return (
    offer && (
      <BasicCard>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-12 lg:col-span-12'>
            <span className='text-xs font-semibold'>Plan</span>
            <h3 className='text-md'> {offer?.name}</h3>
            <ul>
              {offer?.features?.map((feature: string, index: number) => (
                <li key={index}>
                  <span className='text-sm flex gap-2'>
                    {feature} <HiOutlineCheck />
                  </span>
                </li>
              ))}
            </ul>
            {offer?.coupon && (
              <div>
                <div>
                  <h3 className='text-sm'> {offer?.coupon?.name}</h3>
                </div>
              </div>
            )}
            <div className='bg-white p-2 rounded-md mt-5'>
              <div className='flex justify-between'>
                <p> Renta Mensual:</p>
                <p>
                  <b>${price} pesos</b>
                </p>
              </div>
              <p className='text-sm text-right'>IVA incluido</p>
            </div>
          </div>
        </div>
      </BasicCard>
    )
  )
}
