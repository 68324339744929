import React from "react";
import BasicCard from "components/atoms/BasicCard";
export default function ValidatePersonalInfoPage() {
  return (
    <div className="">
      <div className="flex justify-center h-screen">
        <div className="w-full sm:w-2/3">
          <BasicCard>
            <h2 className="text-2xl font-semibold">Valida tu información </h2>
            <br />
            <button
              type="submit"
              className=" w-full lg:w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50"
            >
              VALIDAR INFORMACIÓN
            </button>
          </BasicCard>
        </div>
      </div>
    </div>
  );
}
