import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const months = {
  "01": "Enero",
  "02": "Febrero",
  "03": "Marzo",
  "04": "Abril",
  "05": "Mayo",
  "06": "Junio",
  "07": "Julio",
  "08": "Agosto",
  "09": "Septiembre",
  "10": "Octubre",
  "11": "Noviembre",
  "12": "Diciembre",
};

const formatUnixDate = (unixDate: string) => {
  const year = unixDate.slice(0, 4);
  const month = unixDate.slice(4, 6);
  const day = unixDate.slice(6, 8);
  const hour = unixDate.slice(8, 10);
  const minute = unixDate.slice(10, 12);

  const dateFormated = new Date(`${year}-${month}-${day}T${hour}:${minute}`);

  const dayFormated = dateFormated.toLocaleDateString("es-ES", {
    weekday: "long",
  });
  const dayNumber = dateFormated.toLocaleDateString("es-ES", {
    day: "2-digit",
  });

  const monthFormated = dateFormated.toLocaleDateString("es-ES", {
    month: "long",
  });
  const hourFormated = dateFormated.toLocaleTimeString("es-ES", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { dayFormated, hourFormated, dayNumber, monthFormated };
};

export { formatUnixDate };

export const getStringDate = (
  action: string = "",
  quantity: number = 0,
  element: any = ""
) => {
  dayjs.tz.setDefault("America/Mexico_City");
  // Get the current date and time in Mexico City timezone
  let currentDate = "";
  if (action == "add") {
    currentDate = dayjs().add(quantity, element).format("YYYY-MM-DD");
  } else {
    currentDate = dayjs().format("YYYY-MM-DD");
  }

  return currentDate;
};

export const getUnixDate = (value: string) => {
  const date = dayjs.tz(value, "America/Mexico_City");
  const milliseconds = date.valueOf();
  return milliseconds;
};

export const convertUnixToDate = (unixMilliseconds: number) => {
  const date = dayjs.unix(unixMilliseconds / 1000);
  const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
  return formattedDate;
};

export const convertDateToFormat = (date: string, format: string) => {
  const dateFormated = dayjs(date).format(format);
  return dateFormated;
};

export const diffDates = (date1: number, date2: number) => {
  const date1Formatted = dayjs(date1).tz('America/Mexico_City'); // Usamos las fechas en milisegundos directamente
  const date2Formatted = dayjs(date2).tz("America/Mexico_City") // Usamos las fechas en milisegundos directamente
  return date1Formatted.diff(date2Formatted, 'day');
};
