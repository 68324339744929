import React from 'react'
import { privateAxiosSales } from 'utils/axios'
import usePortalData from './usePortalData'
import { urls } from 'utils/urls'
import { setSessionItem } from 'utils/session'
import { useNavigate } from 'react-router-dom'

const useFlowData = (flowId: any) => {
  const { setFlowData } = usePortalData()
  const navigate = useNavigate()
  React.useEffect(() => {
    getData(flowId)
  }, [])

  const getData = async (flowId: string = '') => {
    let params = {}
    const flowToken = sessionStorage.getItem('flowToken')

    if (flowToken) {
      params = {
        flowToken
      }
    }

    const flow = Boolean(sessionStorage.getItem('flowId'))
      ? sessionStorage.getItem('flowId')
      : flowId

    if (!flow) return

    try {
      const { data: response } = await privateAxiosSales.get(
        urls.API_SALES_getFlow + flow,
        {
          params
        }
      )
      if (response?.success) {
        setFlowData(response?.flow)
        setSessionItem('flowType', response?.flow?.type)
        setSessionItem('flowId', response?.flow.flowId)
        return response?.flow
      }
    } catch (error) {
      navigate('/forbidden')
    } finally {
    }
  }
  return { getData }
}

export default useFlowData
