import React from "react";
import { formatUnixDate } from "utils/date";
export default function SlotCard({ slot, isSelected, onSelect, isToday }: any) {
  const handleSelect = () => {
    onSelect(slot);
  };
  return (
    <div
      onClick={handleSelect}
      style={{ border: isSelected ? "2px solid #3b82f6" : "" }}
      className="w-full p-5 border shadow-md  rounded-lg rounded shadow-sm h-fit hover:bg-gray-50 cursor-pointer"
    >
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">
          {isToday
            ? "Hoy"
            : formatUnixDate(slot?.FromDate || slot.fromDateTime).dayFormated}
        </h3>
        <h3 className="text-xl font-bold">
          {formatUnixDate(slot?.FromDate || slot.fromDateTime).hourFormated}
        </h3>
      </div>
      <div>
        <p>
          {" "}
          {
            formatUnixDate(slot?.FromDate || slot.fromDateTime).dayNumber
          } de{" "}
          {formatUnixDate(slot?.FromDate || slot.fromDateTime).monthFormated}{" "}
        </p>
      </div>
    </div>
  );
}
