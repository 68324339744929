import axios from "axios";

const baseSalesUrl = process.env.REACT_APP_API_SALES_URL;
const tokenSales = process.env.REACT_APP_API_SALES_TOKEN;
const apimSubKey = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;

const baseCustomerUrl = process.env.REACT_APP_API_CUSTOMER_URL;
const tokenCustomer = process.env.REACT_APP_API_CUSTOMER_TOKEN;

const baseNetvaultUrl = process.env.REACT_APP_API_NETVAULT_URL;
const tokenNetvault = process.env.REACT_APP_API_NETVAULT_TOKEN;

const baseProductsUrl = process.env.REACT_APP_API_PRODUCTS_URL;
const tokenProducts = process.env.REACT_APP_API_PRODUCTS_TOKEN;

const basePaynetUrl = process.env.REACT_APP_API_PAYNET_URL;
const tokenPaynet = process.env.REACT_APP_API_PAYNET_TOKEN;

const baseFsUrl = process.env.REACT_APP_API_FS_URL;

const salesHeaders: any = {
  Authorization: `Bearer ${tokenSales}`,
  "Ocp-Apim-Subscription-Key": apimSubKey,
};

const customerHeaders = {
  Authorization: `Bearer ${tokenCustomer}`,
};

const netvaultHeaders = {
  Authorization: `Bearer ${tokenNetvault}`,
};

const productsHeaders = {
  Authorization: `Bearer ${tokenProducts}`,
};

const paynetHeaders = {
  Authorization: `Bearer ${tokenPaynet}`,
};

const privateAxiosSales = axios.create({
  baseURL: baseSalesUrl,
  headers: salesHeaders,
  withCredentials: false,
});

const privateAxiosCustomer = axios.create({
  baseURL: baseCustomerUrl,
  headers: customerHeaders,
  withCredentials: true,
});

const privateAxiosNetVault = axios.create({
  baseURL: baseNetvaultUrl,
  headers: netvaultHeaders,
  withCredentials: false,
});

const privateAxiosProducts = axios.create({
  baseURL: baseProductsUrl,
  headers: productsHeaders,
  withCredentials: false,
});

const privateAxiosPaynet = axios.create({
  baseURL: basePaynetUrl,
  headers: paynetHeaders,
  withCredentials: false,
});
const privateAxiosFieldService = axios.create({
  baseURL: baseFsUrl,
  withCredentials: false,
});

privateAxiosNetVault.interceptors.request.use((config) => {
  return config;
});

privateAxiosNetVault.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export {
  privateAxiosSales,
  privateAxiosCustomer,
  privateAxiosNetVault,
  privateAxiosProducts,
  privateAxiosPaynet,
  privateAxiosFieldService,
};
