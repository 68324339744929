import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { getSessionItem } from "utils/session";

export default function Layout({ children }: { children: ReactNode }) {
  const location = useLocation();

  const [twStyles, setTwStyles] = React.useState<string>("");

  return <div className={" h-screen" + " " + twStyles}>{children}</div>;
}
