import React, { useEffect } from 'react'
import BasicCard from 'components/atoms/BasicCard'
import GoogleMapComponent from 'components/molecules/GoogleMapComponent'
import AddressForm from 'components/molecules/Coverage/AddressForm'
import useFlowInstance from 'hooks/useFlowInstance'
import usePortalData from 'hooks/usePortalData'
import { setSessionItem, getSessionItem } from 'utils/session'
import { useNavigate } from 'react-router-dom'
import useCustomerData from 'hooks/useCustomerData'
import { removeLettersV2, removeNumbers } from 'utils/validations'
import { notify } from 'utils/notify'
import { isEmpty } from 'lodash'
import { getBrowserName } from 'utils/browser'
import useGoogleMaps from 'hooks/useGoogleMaps'
import useLeadsServices from 'hooks/leads/useLeadsServices'
import useNetvault from 'hooks/useNetvault'
import _ from 'lodash'
import { getAddressFromLatLng } from 'utils/geocoding'

export default function Coverage() {
  const [address, setAddress] = React.useState<any>({})
  const [mapZoom, setMapZoom] = React.useState(12)
  const { updateFlowInstance } = useFlowInstance()
  const { prereservatePort, getCoverage } = useNetvault()
  const { getLead, createLead } = useLeadsServices()
  const { setNextStep, setGlobalLoading } = usePortalData()
  const [acceptanceDate, setAcceptanceDate] = React.useState<string>('')
  const { getCustomer, getAddress } = useCustomerData()
  const navigate = useNavigate()
  const [isLoadingLead, setIsLoadingLead] = React.useState(true)
  const [fromLeads, setFromLeads] = React.useState(false)

  useGoogleMaps()

  useEffect(() => {
    const getLeadData = async (leadId: string) => {
      let lead = (await getLead(leadId)) as any
      if (!isEmpty(lead)) {
        let leadNames = lead.name.split(' ')
        let firstName = leadNames.splice(0, 1)[0]
        let lastName = leadNames.join(' ')
        let addressType = ''
        let addressName = ''
        let sourceAddressType = 'google'
        let xtremoAddressProps = {}
        let googleMapsPlaceId = lead?.location?.googleMapsPlaceId
        let xtremoAddressId = lead?.location?.xtremoAddressId || lead?.location?.addressId
        if (
          lead?.location?.googleMapsPlaceId &&
          lead?.location?.sourceAddressType === 'xtremo'
        ) {
          let queryAddress
          if (xtremoAddressId) {
            queryAddress = xtremoAddressId
          } else {
            queryAddress = googleMapsPlaceId
          }
          const xtremoAddress = await getAddress(queryAddress)
          if (!_.isEmpty(xtremoAddress)) {
            addressType = xtremoAddress?.addressType
            addressName = xtremoAddress?.name
            sourceAddressType = 'xtremo'
            xtremoAddressProps = {
              xtremoAddressId: xtremoAddress?.xtremoAddressId || xtremoAddress?.addressId,
              name: xtremoAddress.name,
              addressType: xtremoAddress.addressType,
              description: xtremoAddress.description,
              params: xtremoAddress?.params,
              coordinates: {
                lat: xtremoAddress.geoLocation.coordinates[1],
                lng: xtremoAddress.geoLocation.coordinates[0]
              },
              ...(xtremoAddress.address && {
                address: xtremoAddress.address
              })
            }
          }
        }
        if (googleMapsPlaceId === undefined) {
          let getAddressFromGoogle = (await getAddressFromLatLng({
            lat: lead?.location?.lat,
            lng: lead?.location?.lng
          })) as any
          googleMapsPlaceId = getAddressFromGoogle?.placeId
        }
        setAddress((prev: any) => ({
          ...prev,
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          email: lead?.email ?? '',
          telephone: `52${lead?.phoneNumber ?? ''}`,
          street: lead?.location?.street ?? '',
          extNumber: lead?.location?.extNumber ?? '',
          intNumber: lead?.location?.intNumber ?? '',
          suburb: lead?.location?.suburb ?? '',
          city: lead?.location?.city ?? '',
          state: lead?.location?.state ?? '',
          zipCode: lead?.location?.zipCode ?? '',
          lat: lead?.location?.lat ?? '',
          lng: lead?.location?.lng ?? '',
          country: lead?.location?.country ?? '',
          sourceAddressType: sourceAddressType,
          googleMapsPlaceId: googleMapsPlaceId ?? '',
          ...(addressName !== '' && {
            name: addressName
          }),
          ...(addressType !== '' && { addressType }),
          ...(!_.isEmpty(xtremoAddressProps) && { xtremoAddressProps })
        }))
        setIsLoadingLead(false)
      }
    }
    if (sessionStorage.getItem('leadId')) {
      let leadId = sessionStorage.getItem('leadId')
      if (leadId) {
        getLeadData(leadId)
        setFromLeads(true)
      } else {
        setIsLoadingLead(false)
      }
      sessionStorage.removeItem('leadId')
    } else {
      setIsLoadingLead(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e: any) => {
    const notNumberValues = ['firstName', 'lastName']
    const notLettersValues = ['extNumber', 'zipCode', 'telephone']
    if (e?.target?.name) {
      const value = notLettersValues.includes(e.target.name)
        ? removeLettersV2(e.target.value)
        : notNumberValues.includes(e.target.name)
        ? removeNumbers(e.target.value)
        : e.target.value

      setAddress((prev: any) => ({
        ...prev,
        [e.target.name]: value
      }))
    } else {
      setAddress((prev: any) => ({
        ...prev,
        ...e
      }))
    }
  }

  const handleLocationChange = (value: any) => {
    setAddress((prevEvent: any) => ({ ...prevEvent, ...value }))
    if (value?.lat && value?.lng) setMapZoom(18)
    else setMapZoom(12)
  }

  const handleSubmit = async () => {
    setGlobalLoading(true)
    const payload: any = {
      prospect: {
        firstName: address?.firstName,
        lastName: address?.lastName,
        email: address?.email,
        telephone: address?.telephone,
        customerType: getSessionItem('flowType') || 'Residential'
      },
      location: {
        street: address?.street,
        extNumber: address?.extNumber,
        intNumber: address?.intNumber,
        suburb: address?.suburb,
        city: address?.city,
        state: 'Nuevo León',
        zipCode: address?.zipCode,
        lat: address?.lat,
        lng: address?.lng,
        country: 'México',
        ...(address?.addressType && { addressType: address.addressType }),
        ...(address?.googleMapsPlaceId && {
          googleMapsPlaceId: address.googleMapsPlaceId
        }),
        ...(address?.sourceAddressType && {
          sourceAddressType: address.sourceAddressType
        })
      }
    }
    if (address?.sourceAddressType === 'xtremo') {
      payload.location = {
        ...payload.location,
        xtremoAddressId: address?.xtremoAddressProps?.xtremoAddressId,
        ...(address?.xtremoAddressProps?.name && {
          xtremoAddressName: address?.xtremoAddressProps?.name
        }),
        ...(address?.floor && { floor: address.floor }),
        ...(address?.building && { building: address.building })
      }
    }
    if (address?.businessName) {
      payload.prospect.businessName = address?.businessName
    }

    const customer = await getCustomer('customerId', address?.email)

    if (!isEmpty(customer)) {
      notify('error', 'El correo electrónico ya se encuentra registrado')
      setGlobalLoading(false)
      return
    }

    const coverage = await prereservatePort(address.lat, address.lng)
    const future_coverage = await getCoverage(address.lat, address.lng)
    if (coverage?.status === 'success') {
      setSessionItem('expirationDate', coverage?.preReservationPortExpireDate)
      setNextStep()
      validateStartOperationDate(address, coverage)
    } else {
      if(future_coverage?.prospectzone){
        setSessionItem('month', future_coverage?.prospectzone.operationStartDate.split("-")[1])
        navigate('/future-coverage')
      } else{
        navigate('/no-coverage')
      }
    }

    if (!fromLeads) {
      let localLead = await handleGenerateLead(coverage, future_coverage)
      if (localLead?.leadId) {
        payload.leadId = localLead?.leadId
      }
    }

    saveCustomerData(payload, coverage, address)
    setGlobalLoading(false)
  }

  const validateStartOperationDate = (address: any, coverage: any) => {
    let operationStartDate = null
    // VALIDA QUE EL DI TENGA FECHA DE INICIO DE COBERTURA
    if (address?.xtremoAddressProps?.startOperationDate) {
      operationStartDate = address.xtremoAddressProps.startOperationDate
    }
    // VALIDA QUE EL DI VERTICAL TENGA FECHA DE INICIO DE COBERTURA
    if (address?.xtremoAddressProps?.addressType) {
      if (address?.xtremoAddressProps?.addressType !== 'housingSociety') {
        let building = address?.xtremoAddressProps?.params?.buildings.find(
          (building: any) => building.name === address?.building
        )
        if (building?.startOperationDate) {
          operationStartDate = building?.startOperationDate
        }
      } 
    }
    // VALIDA QUE EL DP TENGA FECHA DE INICIO DE COBERTURA
    if(!operationStartDate && coverage?.startOperationDate){
      operationStartDate = coverage?.startOperationDate
    }
    if (operationStartDate) {
      setSessionItem('startOperationDate', operationStartDate)
    }
  }

  const handleGenerateLead = async (coverage: any, future_coverage: any) => {
    setGlobalLoading(true)
    const utm = JSON.parse(getSessionItem('utm') || '{}')
    const lead = await createLead({
      type: getSessionItem("flowType") === "real_estate" ? 'exchange' : 'website',
      name: address?.firstName + ' ' + address?.lastName,
      email: address?.email,
      phoneNumber: address?.telephone,
      hasCoverage: coverage?.status === 'success',
      utm_source: utm.utm_source || '',
      utm_medium: utm.utm_medium || '',
      utm_campaign: utm.utm_campaign || '',
      utm_term: utm.utm_term || '',
      utm_content: utm.utm_content || '',
      originURL: window.location.href.split('?')[0],
      ...(getSessionItem("referralCode") && { referralCode: getSessionItem("referralCode") }),
      productsOfInterest: ["INTERNET"],
      location: {
        street: address?.street,
        extNumber: address?.extNumber,
        intNumber: address?.intNumber,
        suburb: address?.suburb,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode,
        lat: address?.lat,
        lng: address?.lng,
        country: address?.country,
        ...(address?.sourceAddressType === 'xtremo' && {
          sourceAddressType: address?.sourceAddressType
        }),
        ...(address?.googleMapsPlaceId && {
          googleMapsPlaceId: address?.googleMapsPlaceId
        }),
        ...(address?.xtremoAddressProps?.name && {
          name: address?.xtremoAddressProps?.name
        }),
        ...(address?.xtremoAddressProps?.xtremoAddressId && {
          addressId: address?.xtremoAddressProps?.xtremoAddressId
        }),
        ...(coverage?.zoneId && {
          zoneId: coverage?.zoneId
        }),
        ...(coverage?.zoneName && {
          zoneName: coverage?.zoneName
        }),
        ...(future_coverage?.prospectzone && {
          prospectzone: future_coverage?.prospectzone
        })
      }
    })
    if (lead) {
      setFromLeads(true)
    }
    setGlobalLoading(false)
    return lead
  }

  const saveCustomerData = async (
    payload: any,
    coverage: any,
    address: any
  ) => {
    const response = await updateFlowInstance({
      ...payload,
      privacyAcceptedDate: acceptanceDate,
      dp: coverage || null,
      browser: getBrowserName(navigator?.userAgent)
    })
    if (response.success) {
      setSessionItem(
        'customerBasicInfo',
        JSON.stringify({
          firstName: address?.firstName,
          lastName: address?.lastName,
          email: address?.email,
          lat: address?.lat,
          lng: address?.lng
        })
      )
    }
  }

  return (
    <div className='m-6 mt-20 lg:m-20'>
      <div className='grid grid-cols-12 gap-4 '>
        {isLoadingLead && (
          <></>
          // <div className='col-span-12 lg:col-span-12'>
          //   <BasicCard>
          //     <div className='flex justify-center'>
          //       <div className='w-12 h-12 border-t-4 border-b-4 border-primary rounded-full animate-spin'></div>
          //     </div>
          //   </BasicCard>
          // </div>
        )}
        {!isLoadingLead && (
          <>
            <div className='col-span-12 lg:col-span-4 '>
              <BasicCard>
                <AddressForm
                  address={address}
                  onChange={handleChange}
                  onDragEnd={handleLocationChange}
                  onPlaceSelect={handleLocationChange}
                  onSubmit={handleSubmit}
                  setAcceptanceDate={setAcceptanceDate}
                  mapZoom={mapZoom}
                />
              </BasicCard>
            </div>
            <div className='hidden col-span-12 lg:col-span-8 lg:block '>
              <div className=' sticky top-7 w-full border border-gray-200 rounded-lg shadow-sm h-fit'>
                <GoogleMapComponent
                  initialPosition={{
                    lat: address?.lat || 25.67507,
                    lng: address?.lng || -100.31847
                  }}
                  zoom={mapZoom}
                  style={{ width: '100%', height: '80vh' }}
                  isDraggable={false}
                  onMarkerDragEnd={(value: any) => {
                    setAddress((prevEvent: any) => ({ ...prevEvent, ...value }))
                  }}
                />
                <p className='text-sm font-bold m-3 flex items-center '>
                  Ubicación aproximada 📍
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
