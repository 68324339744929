import ReactGA from "react-ga";

export const initGA = () => {
  const trackingCode = process.env.REACT_APP_GA_TRACKING_CODE;

  if (!trackingCode) {
    console.warn("El código de seguimiento de GA no está definido.");
    return;
  }

  try {
    ReactGA.initialize(trackingCode);
    console.log("Google Analytics inicializado correctamente.");
  } catch (error) {
    console.error("Error al inicializar Google Analytics:", error);
  }
};

export const logPageView = () => {
  const pagePath = window.location.pathname;

  try {
    ReactGA.set({ page: pagePath });
    ReactGA.pageview(pagePath);
    console.log(`Vista de página registrada para: ${pagePath}`);
  } catch (error) {
    console.error("Error al registrar la vista de página:", error);
  }
};
