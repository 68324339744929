export const termsHtmlContent = `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN"
"http://www.w3.org/TR/html4/loose.dtd">
<html><head><title></title>
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
</head>
<body>
<div style="page-break-before:always; page-break-after:always"><div><p style="text-align: center;"><b>AXE REDES E INFRAESTRUCTURA S.A. de C.V. <br/></b></p>
<p style="text-align: center;"><b>T&#233;rminos y Condiciones para Servicio de Internet <br/></b></p>
<p><b>Hogar y Negocios <br/></b></p>
<p><b>Cobertura <br/></b></p>
<p>Toda contrataci&#243;n deber&#225; estar dentro de la zona de cobertura que el cliente tendr&#225; que validar al inicio <br/>del proceso de venta. <br/></p>
<p>Cualquier solicitud que se encuentre fuera de la zona de cobertura no podr&#225; ser procesada. En caso de <br/>que existan restricciones que impidan la instalaci&#243;n, incluso dentro de la zona de cobertura, el cliente ser&#225; <br/>notificado oportunamente. <br/></p>
<p><b>Requisitos y proceso de contrataci&#243;n <br/></b></p>
<p>&#8226; Identificaci&#243;n oficial  <br/>&#8226; Realizar validaci&#243;n de Cobertura <br/>&#8226; Elegir alguno de los planes disponibles <br/>&#8226; Aceptar t&#233;rminos y condiciones del Contrato en L&#237;nea  <br/>&#8226; Se requiere una tarjeta cr&#233;dito o D&#233;bito para cargo recurrente de la renta mensual del servicio. <br/></p>
<p><b>Instalaci&#243;n <br/></b></p>
<p>&#8226; Una vez cubierto el proceso de contrataci&#243;n, le ofreceremos los horarios disponibles para instalar, <br/>buscando en medida de lo posible que sean en las siguientes 72 horas h&#225;biles. <br/></p>
<p>&#8226; El cliente podr&#225; seleccionar opciones de fecha y hora de visita para instalaci&#243;n, seg&#250;n su <br/>conveniencia.  <br/></p>
<p><b>Tarifas para el Servicio de Internet <br/></b></p>
<p>Consulta de tarifas para los planes Hogar y Negocios: <br/></p>
<p>https://axeredes.mx/planes/ <br/></p>
<p>https://tarifas.ift.org.mx/ift_visor/ <br/></p>
<p>Los precios publicados al cliente en la p&#225;gina web, incluyen IVA. <br/></p>
<p>La renta Mensual se factura por ciclo mensual. No hay cargos por Instalaci&#243;n o activaci&#243;n. <br/></p>
<p>Se cobrar&#225; la primera renta del servicio el d&#237;a que se ejecute la instalaci&#243;n y de forma recurrente, ese <br/>mismo d&#237;a de cada mes ser&#225; la fecha de corte y cobro a la tarjeta de cr&#233;dito o d&#233;bito. <br/></p>
<p>Fecha L&#237;mite de pago se establece 5 d&#237;as despu&#233;s del corte. <br/></p>
<p>Todos los planes se ofrecen con la misma velocidad de subida y de bajada. <br/></p>
<p>La velocidad de los planes se establece como la velocidad m&#225;xima que el Cliente puede experimentar y <br/>esta puede variar seg&#250;n la hora del d&#237;a, si est&#225; conectado por WiFi o Cableado, el n&#250;mero de dispositivos <br/>o usuarios concurrentes.  </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>Para la cobertura WiFi, cada plan tiene definido un n&#250;mero m&#225;ximo de equipos (ruteadores) WiFi <br/>incluidos. <br/></p>
<p>Puede haber escenarios donde el cliente no requiera ning&#250;n ruteador y esto NO cambia el precio del plan <br/>contratado. <br/></p>
<p>En el caso que la red WiFi o la necesidad del Cliente requiera m&#225;s ruteadores que los incluidos en el plan <br/>contratado, existir&#225; la opci&#243;n de contratarlos como Adicionales. <br/></p>
<p>Ruteadores adicionales para WiFi: La renta mensual por cada ruteador WiFi adicional a los incluidos en el <br/>plan contratado es de $89 pesos incluyendo IVA. <br/></p>
<p>Los Ruteadores WiFi son siempre propiedad de AXE REDES y el Cliente est&#225; obligado a regresarlos al <br/>terminar de su contrato de servicio. <br/></p>
<p>Todos los Ruteadores se instalan de forma inal&#225;mbrica y se conectan a la red el&#233;ctrica del cliente. <br/></p>
<p><b>Cambio de Plan <br/></b></p>
<p>Nuestros clientes pueden cambiar de plan en cualquier momento, solo considerar que pudiera haber <br/>costos adicionales por equipos no incluidos en el nuevo plan. Esto se ajustar&#225; en el siguiente cargo <br/>mensual. <br/></p>
<p>El cliente se compromete a regresar a AXE REDES los equipos que, en su caso, no sean necesarios para la <br/>prestaci&#243;n del servicio.  <br/></p>
<p>En caso de no regresar los equipos se deber&#225; pagar el costo correspondiente a la renta adicional de los <br/>equipos no devueltos. <br/></p>
<p><b> <br/></b></p>
<p><b>Estado de Cuenta <br/></b></p>
<p>En su Estado de Cuenta el cliente encontrar&#225; detalladamente la cantidad que se carga a su tarjeta de <br/>cr&#233;dito o d&#233;bito y dicho cargo se hace en la fecha de corte, que corresponde al d&#237;a del mes en que se <br/>realiza la instalaci&#243;n del servicio. <br/></p>
<p>Para emitir una factura con efectos fiscales, el cliente deber&#225; enviar su Constancia de Situaci&#243;n Fiscal al <br/>momento de generar su primer recibo, le llegar&#225;n las instrucciones por correo electr&#243;nico. <br/></p>
<p>Esta fecha de corte es informada al cliente por medio de un correo electr&#243;nico que le llega <br/>autom&#225;ticamente el d&#237;a de la instalaci&#243;n. <br/></p>
<p>El estado de cuenta mes con mes se enviar&#225; a la direcci&#243;n de correo electr&#243;nico que se indic&#243; al momento <br/>de la contrataci&#243;n del servicio de Internet. <br/></p>
<p><b> <br/></b></p>
<p><b> <br/></b></p>
<p><b> <br/></b></p>
<p><b> </b></p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p><b>Vigencia del Contrato y Cancelaci&#243;n <br/></b></p>
<p>No se contempla plazos forzosos para el Contrato de servicio de Internet, se podr&#225; dar por terminado el <br/>contrato en cualquier momento, sin responsabilidad ni penalidad alguna, haciendo la solicitud y <br/>correspondiente identificaci&#243;n por escrito v&#237;a correo electr&#243;nico. <br/></p>
<p>El proceso se completa y libera de responsabilidad al cliente de facturaci&#243;n, una vez que hayamos <br/>recolectado y constatado el buen estado de los equipos en el domicilio del cliente. <br/></p>
<p>La terminaci&#243;n del contrato no exime Cliente de cubrir los adeudos pendientes de los servicios <br/>efectivamente prestados y de realizar la devoluci&#243;n de los equipos. En caso de no devolverlos, el Cliente <br/>se obliga a pagar la tarifa por el costo de los equipos. <br/></p>
<p> <br/></p>
<p><b>Pol&#237;tica de uso justo para Internet <br/></b></p>
<p>Al utilizar los servicios de transmisi&#243;n de datos y acceso a Internet de AXE REDES, usted acepta y est&#225; de <br/>acuerdo en cumplir los t&#233;rminos de esta pol&#237;tica. <br/></p>
<p>El Servicio de Internet es comercializado para el uso razonable y justo de Internet, de acuerdo con el plan <br/>contratado, ya sea hogar o de negocios, as&#237; como con las tarifas registradas ante el Instituto Federal de <br/>Telecomunicaciones (www.ift.org.mx) y, por lo tanto, no puede ser utilizado con prop&#243;sito comercial <br/>alguno. Esta prohibici&#243;n incluye, de manera enunciativa y no limitativa, cualquier acto que implique la <br/>comercializaci&#243;n del servicio a terceros, sea en la modalidad de reventa o bien integrando el servicio de <br/>AXE REDES como componente de un producto o servicio distinto. <br/></p>
<p>El servicio contratado en un plan de Hogar queda entendido como el uso normal para un solo hogar de <br/>acuerdo con las necesidades de sus miembros, sin fines de lucro ni especulaci&#243;n comercial. De igual forma, <br/>el uso del servicio en alguno de los planes de Negocios ser&#225; aqu&#233;l consistente con la actividad razonable <br/>del cliente y no podr&#225; en momento alguno integrarse, directa o indirectamente, a un producto o servicio <br/>distinto. <br/></p>
<p>Queda estrictamente prohibido utilizar el servicio de internet de AXE REDES de forma que incumpla o viole <br/>las leyes, regulaciones, c&#243;digos aplicables, as&#237; como usos y buenas costumbres. </p>

</div></div>
</body></html>`;
