import React from 'react'
import ErrorMessageComponent from './ErrorMessageComponent'
import { validateEmail } from 'utils/validations'
import { hasNumbers, hasLetters } from 'utils/validations'
export default function BasicField({
  label,
  placeholder,
  required = false,
  type,
  onChange,
  value,
  name,
  maxLength = ''
}: any) {
  const handleChange = (event: any) => {
    onChange(event)
  }
  return (
    <div className='w-full mb-2'>
      <label className='block  text-sm text-gray-600 font-medium text-left'>
        {label} {required && <span className='text-red-500'>*</span>}{' '}
      </label>
      <input
        type={type}
        maxLength={maxLength}
        id='field'
        value={value}
        className=' border border-gray-300 text-gray-900 mb-2 p-3 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full '
        placeholder={placeholder}
        name={name}
        required={required}
        onChange={handleChange}
      />

      <ErrorMessageComponent
        message='El campo es requerido '
        condition={required && !Boolean(value)}
      />
      <ErrorMessageComponent
        message='El campo no cumple con la longitud'
        condition={maxLength && value?.length < maxLength}
      />
      <ErrorMessageComponent
        message=' El campo no es un correo válido'
        condition={type === 'email' && !validateEmail(value)}
      />

      <ErrorMessageComponent
        message='El campo solo debe ser numérico'
        condition={name === 'zipCode' && hasLetters(value)}
      />
    </div>
  )
}
