import React from 'react'
import BasicField from 'components/atoms/BasicField'

import { formatPhone } from 'utils/validations'
import { getSessionItem } from 'utils/session'
export default function CustomerDataForm({ address, onChange }: any) {
  return (
    <>
      <div className='col-span-12 lg:col-span-12'>
        <h4 className='font-semibold'>Ingresa tus datos:</h4>
      </div>
      {getSessionItem('flowType') === 'business' && (
        <div className='col-span-12 lg:col-span-12'>
          <BasicField
            label='Razón Social'
            name='businessName'
            required={true}
            value={address?.businessName}
            onChange={onChange}
          />
        </div>
      )}
      <div className='col-span-6 lg:col-span-6'>
        <BasicField
          label='Nombre'
          name='firstName'
          required={true}
          value={address?.firstName}
          onChange={onChange}
        />
      </div>
      <div className='col-span-6 lg:col-span-6'>
        <BasicField
          label='Apellidos'
          name='lastName'
          value={address?.lastName}
          required={true}
          onChange={onChange}
        />
      </div>
      <div className='col-span-12 lg:col-span-12'>
        <BasicField
          label='Correo electrónico'
          name='email'
          type='email'
          value={address?.email}
          required={true}
          onChange={onChange}
        />
      </div>
      <div className='col-span-12 lg:col-span-12'>
        <BasicField
          required={true}
          type='phone'
          maxLength='14'
          value={formatPhone(address?.telephone)}
          label='Teléfono'
          name='telephone'
          onChange={onChange}
        />
      </div>
    </>
  )
}
