import React from 'react'
import BasicSelect from 'components/atoms/BasicSelect'
import BasicField from 'components/atoms/BasicField'

export default function AddressShoppingCentreForm({ address, onChange }: any) {
  return (
    <>
      <div className='col-span-12 lg:col-span-6'>
        <BasicSelect
          label='Edificio'
          name='building'
          value={address?.building}
          onChange={onChange}
          options={address.xtremoAddressProps.params.buildings.map(
            (building: any) => ({
              value: building.name,
              name: building.name
            })
          )}
          required={true}
        />
      </div>
      <div className='col-span-12 lg:col-span-6'>
        <BasicSelect
          label='Piso'
          name='floor'
          value={address?.floor}
          onChange={onChange}
          required={true}
          options={
            address.building
              ? address.xtremoAddressProps.params.buildings
                  .find((building: any) => building.name === address?.building)
                  ?.floors.map((floor: any) => ({
                    value: floor,
                    name: floor
                  }))
              : []
          }
        />
      </div>
      <div className='col-span-12 lg:col-span-6'>
        <BasicField
          label='No. Interior'
          name='intNumber'
          value={address?.intNumber}
          onChange={onChange}
          required={true}
        />
      </div>
    </>
  )
}
