import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import useGoogleMapsContext from './useGoogleMapsContext';
const libraries: any = ['places'];
const useGoogleMaps = () => {
  const { isGMLoaded, setIsLoaded } = useGoogleMapsContext();
  const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

  const { google } = window as any;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries,
  });

  React.useEffect(() => {
    if (!isGMLoaded) {
      setIsLoaded(isLoaded);
    }
  }, [isLoaded]);
};

export default useGoogleMaps;
