import React from 'react'
import BasicCard from 'components/atoms/BasicCard'
import useProducts from 'hooks/useProducts'
import useProductsContext from 'hooks/useProductsContext'
import OfferSelector from './ProductsBetaComponents/OfferSelector'
import { getSessionItem } from 'utils/session'

import {
  serviceAdvantages,
  pageTitle,
  pageSubtitle,
  pageActionDescription
} from 'utils/constants/text/offers'
export default function OffersPage() {
  const { getProducts } = useProducts()
  const { products } = useProductsContext()
  const flowType = getSessionItem('flowType')
  React.useEffect(() => {
    getProducts()
  }, [])
  return (
    <div className='m-6 mt-20 lg:m-20'>
      <div className='flex justify-center h-screen'>
        <div className='w-full sm:w-3/4'>
          <BasicCard>
            <h2 className='text-2xl font-semibold primary_text'>{pageTitle}</h2>
            <br />

            {flowType !== 'real_estate' && (
              <>
                <h3 className='mb-3 text-md'>{pageSubtitle}</h3>
                <div className='w-full p-5 '>
                  <ul className='list-disc '>
                    {serviceAdvantages?.map((item: any, index: number) => (
                      <li key={index} className='ml-8 mb-3'>
                        <p className='text-sm'>
                          {' '}
                          <strong>{item?.strong}</strong>
                          {item.text}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            <h3 className='text-md mb-6'>
              {pageActionDescription?.text}{' '}
              <strong>{pageActionDescription?.strong}</strong>
            </h3>

            <OfferSelector offers={products} />
          </BasicCard>
        </div>
      </div>
    </div>
  )
}
