import React, { ReactElement, createContext, useReducer } from "react";
import { reducer } from "./Reducer";
import { TinitialState, TflowData } from "./types";
import { useNavigate } from "react-router-dom";

export const initialState: TinitialState = {
  flowData: {},
  currentStep: 0,
  customer: {},
  isGlobalLoading: false,
  expirationDate: "",
};

export const useFlowGlobalContext = (initialState: TinitialState) => {
  const [globalState, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const setGlobalLoading = (isGlobalLoading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      payload: isGlobalLoading,
    });
  };

  const setFlowData = (payload: TflowData) => {
    dispatch({
      type: "SET_FLOW_DATA",
      payload: payload,
    });
  };

  const setCustomerData = (payload: any) => {
    dispatch({
      type: "SET_CUSTOMER_DATA",
      payload: payload,
    });
  };

  const setExpirationDate = (date: any) => {
    dispatch({
      type: "SET_EXPIRATION_DATE",
      payload: date,
    });
  };

  const setNextStep = () => {
    const nextStep =
      (parseInt(sessionStorage.getItem("currentStep") || "0") < 0
        ? 0
        : parseInt(sessionStorage.getItem("currentStep") || "0")) + 1;
    navigate("/" + globalState.flowData?.pathOrder[nextStep]);
    sessionStorage.setItem("currentStep", nextStep.toString());
  };
  return {
    globalState,
    setFlowData,
    setCustomerData,
    setGlobalLoading,
    setNextStep,
    setExpirationDate,
  };
};

export const initContextState: ReturnType<typeof useFlowGlobalContext> = {
  globalState: initialState,
  setFlowData: (payload: TflowData) => {},
  setCustomerData: (payload: any) => {},
  setGlobalLoading: (isGlobalLoading: boolean) => {},
  setNextStep: () => {},
  setExpirationDate: (date: any) => {},
};

export const Context =
  createContext<ReturnType<typeof useFlowGlobalContext>>(initContextState);

export const Provider = ({
  children,
}: {
  children: ReactElement | ReactElement[] | undefined;
}): ReactElement => {
  return (
    <Context.Provider value={useFlowGlobalContext(initialState)}>
      {children}
    </Context.Provider>
  );
};
