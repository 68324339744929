import React from 'react';
import { PACKAGES } from 'utils/constants/objects';
import PackageItemCard from 'components/molecules/Products/PackageItemCard';
import useProductsContext from 'hooks/useProductsContext';
import useFlowInstance from 'hooks/useFlowInstance';
import usePortalData from 'hooks/usePortalData';
import BasicDialog from 'components/atoms/BasicDialog';
import Terms from 'components/atoms/Terms';
import BasicCheckBox from 'components/atoms/BasicCheckBox';
import UserRights from 'components/atoms/UserRights';
import usePaynet from 'hooks/usePaynet';
import { getSessionItem } from 'utils/session';
import { flow } from 'lodash';

export default function OfferSelector({ offers }: { offers: any[] }) {
  const { selectedOffer } = useProductsContext();
  const { updateFlowInstance } = useFlowInstance();
  const { setNextStep } = usePortalData();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openRightsDialog, setOpenRightsDialog] =
    React.useState<boolean>(false);
  const [checkTerms, setCheckTerms] = React.useState<boolean>(false);
  const [checkUserRights, setCheckUserRights] = React.useState<boolean>(false);
  const [acceptanceDate, setAcceptanceDate] = React.useState<string>('');
  const { createStripeCustomer } = usePaynet();
  const flowType: any = getSessionItem('flowType');
  const handleSubmit = async () => {
    let payload: any = {
      offer: selectedOffer,
      termsAcceptedDate: acceptanceDate,
    };

    const { firstName, lastName, email } = JSON.parse(
      getSessionItem('customerBasicInfo') || '{}'
    );
    const stripeCustomer = await createStripeCustomer({
      firstName,
      lastName,
      email,
    });

    if (flowType === 'real_estate' || flowType === 'sales_agent') {
      payload = {
        ...payload,
        payment: {
          customerId: stripeCustomer?.customerId,
          paymentMethodType: flowType === 'real_estate' ? 'bank_transfer' : 'card',
        },
      };
    }

    const request = await updateFlowInstance(payload);
    if (request.success) {
      setNextStep();
    }
  };

  React.useEffect(() => {
    if (checkTerms) {
      setAcceptanceDate(new Date().toISOString());
    }
  }, [checkTerms]);
  return (
    <div className='grid grid-cols-12 gap-4'>
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openDialog}
        closeDialog={() => {
          setOpenDialog(false);
        }}
      >
        <Terms />
      </BasicDialog>
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openRightsDialog}
        closeDialog={() => {
          setOpenRightsDialog(false);
        }}
      >
        <UserRights />
      </BasicDialog>
      {offers?.map((item: any, index: number) => {
        const isSelected = selectedOffer?.offerId === item?.offerId;
        return (
          <div key={index} className='col-span-12 lg:col-span-4 offer-selector'>
            <PackageItemCard
              data={item}
              index={index}
              isSelected={isSelected}
              style={{
                border: isSelected ? 'solid 4px black' : 'none',
              }}
            />
          </div>
        );
      })}
      <div className='col-span-12 text-center'>
        <span className='text-sm'>
          Primer cargo hasta después de un mes de la instalación
        </span>

        <span className='text-sm'>
          {' '}
          <strong>|</strong> Sin Plazo Forzoso <strong>|</strong>{' '}
        </span>

        <span className='text-sm'>
          Te puedes cambiar de plan en cualquier momento
        </span>
      </div>

      <div className='col-span-12 flex justify-center'>
        <BasicCheckBox
          value={checkTerms}
          onChange={(e: any) => {
            setCheckTerms(e.target.checked);
          }}
        >
          <p className='text-sm ml-2'>
            He leído y acepto{' '}
            <a
              onClick={() => {
                setOpenDialog(true);
              }}
              className='cursor-pointer link_text	'
            >
              <strong>Términos y Condiciones</strong>
            </a>
          </p>
        </BasicCheckBox>
      </div>
      <div className='col-span-12 flex justify-center'>
        <BasicCheckBox
          value={checkUserRights}
          onChange={(e: any) => {
            setCheckUserRights(e.target.checked);
          }}
        >
          <p className='text-sm ml-2'>
            He leído la{' '}
            <a
              onClick={() => {
                setOpenRightsDialog(true);
              }}
              className='cursor-pointer link_text'
            >
              <strong>Carta de derechos a usuarios</strong>
            </a>
          </p>
        </BasicCheckBox>
      </div>

      <div className='col-span-12 flex justify-center '>
        <button
          disabled={!selectedOffer?.offerId || !checkTerms || !checkUserRights}
          onClick={handleSubmit}
          className=' w-full lg:w-1/4 flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 primary-btn'
        >
          CONTINUAR
        </button>
      </div>
    </div>
  );
}
