import React from "react";
import { notify } from "utils/notify";
import { privateAxiosPaynet } from "utils/axios";
import { urls } from "utils/urls";
import usePortalData from "./usePortalData";
import { getSessionItem, setSessionItem } from "utils/session";
const usePaynet = () => {
  const { setGlobalLoading } = usePortalData();
  async function checkoutPayment(data: any) {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosPaynet.post(
        urls.API_PAYNET_postCheckoutPayment,
        data
      );
      return response;
    } catch (error: any) {
      notify("error", error?.response?.data?.message);
    } finally {
      setGlobalLoading(false);
    }
  }

  async function getPaymentMethod(id: string) {
    try {
      const { data: response } = await privateAxiosPaynet.get(
        urls.API_PAYNET_getPaymentMethod + "/" + id
      );
      return response?.content;
    } catch (error: any) {
      notify("error", error?.response?.data?.message);
    } finally {
    }
  }

  async function createSetupIntent(id: string) {
    try {
      const { data: response } = await privateAxiosPaynet.post(
        urls.API_PAYNET_createSetupIntent,
        {
          stripeCustomerId: id,
        }
      );
      setSessionItem("stripeClientSecret", response.clientSecret);
      return response
    } catch (error: any) {
      notify("error", error?.response?.data?.message);
    } finally {
    }
  }

  async function createStripeCustomer(data: any) {
    setGlobalLoading(true);
    try {
      if (getSessionItem("stripeCustomerId")) {
        return getSessionItem("stripeCustomerId");
      }
      const { data: response } = await privateAxiosPaynet.post(
        urls.API_PAYNET_createCustomer,
        data
      );

      if (response.success) {
        setSessionItem("stripeClientSecret", response?.content?.clientSecret);
        setSessionItem("stripeCustomerId", response?.content?.customerId);
      }

      return response.content;
    } catch (error: any) {
      console.error(error?.response?.data?.message);
    } finally {
      setGlobalLoading(false);
    }
  }
  return {
    checkoutPayment,
    createStripeCustomer,
    getPaymentMethod,
    createSetupIntent,
  };
};
export default usePaynet;
