import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import LoadingBackdrop from "components/atoms/LoadingBackdrop";
import { ToastContainer } from "react-toastify";
import Layout from "components/Layout";
import { Provider } from "context/Context";
import NavigationBar from "components/organisms/NavigationBar";
import "assets/sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import { ProductsProvider } from "context/products/context";
import { GoogleMapsProvider } from "context/googleMaps/context";
import FloatHelpButton from "components/atoms/FloatHelpButton";

import { initGA } from "utils/analytics";
import { initGTM } from "utils/gtm";

function App() {
  React.useEffect(() => {
    initGA();
    initGTM();
  }, []);

  return (
    <BrowserRouter>
      <Provider>
        <GoogleMapsProvider>
          <ProductsProvider>
            <LoadingBackdrop open={true} />
            <ToastContainer />
            <NavigationBar />
            <Layout>
              <FloatHelpButton />
              <AppRoutes />
            </Layout>
          </ProductsProvider>
        </GoogleMapsProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
