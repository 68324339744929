import React from "react";
import BasicCard from "components/atoms/BasicCard";
import { getSessionItem, setSessionItem } from "utils/session";
import useNetvault from "hooks/useNetvault";
import useFlowInstance from "hooks/useFlowInstance";
import { useNavigate } from "react-router-dom";
import { notify } from "utils/notify";
import usePortalData from "hooks/usePortalData";
import { isEmpty, set } from "lodash";
export default function ExpiredPage() {
  const { prereservatePort } = useNetvault();
  const { instanceData, getFlowInstance } = useFlowInstance();
  const navigate = useNavigate();
  const { updateFlowInstance } = useFlowInstance();
  const { flowData } = usePortalData();
  React.useEffect(() => {
    getFlowInstance();
    sessionStorage.clear();
  }, []);

  React.useEffect(() => {
    if (!isEmpty(instanceData)) {
      setSessionItem("flowId", instanceData?.flowId);
      setSessionItem("flowInstanceId", instanceData?.flowInstanceId);
    }
  }, [instanceData]);

  const validateCoverage = async () => {
    const currentCoordinates = JSON.parse(
      getSessionItem("customerBasicInfo") || "{}"
    );
    const response = await prereservatePort(
      currentCoordinates.lat,
      currentCoordinates.lng
    );

    return response;
  };

  const backToLastStep = async () => {
    const pathList = flowData.pathOrder;
    navigate(`/${instanceData.lastPathInteracted}`);
    if ("dp" in instanceData) {
      const validation = await validateCoverage();
      if (validation.status === "success") {
        setSessionItem(
          "expirationDate",
          validation?.preReservationPortExpireDate
        );
        const update = await updateFlowInstance({ dp: validation });
        if (update.success) {
          navigate(
            `/${
              pathList[pathList.indexOf(instanceData.lastPathInteracted) + 1]
            }`
          );
        }
      } else {
        navigate(`/${pathList[0]}`);
      }
    }
  };

  const endProcess = () => {
    sessionStorage.clear();
    window.location.href = "https://www.axeredes.mx";
  };

  const retryProcess = () => {
    const flowId = getSessionItem("flowId");
    navigate("/flow/" + flowId);
    sessionStorage.clear();
  };

  return (
    <div className="flex justify-center h-screen m-6 mt-10 lg:m-20">
      <div className="w-full sm:w-1/4">
        <BasicCard>
          <h3 className="text-2xl font-semibold flex items-center primary_text">
            El tiempo para realizar su solicitud del servicio de Internet se ha
            agotado ⏰
          </h3>
          <br />
          <p className="text-lg">
            Agradeceremos intentar de nuevo <br />
          </p>
          <br />
          {/* <button
            onClick={backToLastStep}
            type="submit"
            className=" w-full lg:w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50"
          >
            VOLVER AL PROCESO
          </button> */}
          <button
            onClick={retryProcess}
            type="submit"
            className=" w-full lg:w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50 primary-btn"
          >
            VOLVER A INTENTAR
          </button>
        </BasicCard>
      </div>
    </div>
  );
}
