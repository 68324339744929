import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import usePortalRoutes from 'hooks/usePortalRoutes'
import Home from 'pages/Home/Home'
import DataValidationBeta from 'pages/DataValidation/DataValidationBeta'
import ProductSelector from 'pages/Products/ProductsBeta'
import Payment from 'pages/Payment/Payment'
import Checkout from 'pages/Checkout/Checkout'
import SuccessPage from 'pages/SuccessPage/SuccessPage'
import useFlowData from 'hooks/useFlowData'
import Coverage from 'pages/Location/Coverage'
import NotFound from 'pages/NotFound/NotFound'
import { getSessionItem } from 'utils/session'
import { useNavigate } from 'react-router-dom'
import OffersPage from 'pages/Products/OffersPage'
import InstallationSlotsPage from 'pages/InstallationSlots/InstallationSlotsPage'
import ValidatePersonalInfoPage from 'pages/ValidatePersonalInfo/ValidatePersonalInfoPage'
import NoCoveragePage from 'pages/Location/NoCoveragePage'
import FutureCoveragePage from 'pages/Location/FutureCoveragePage'
import ExpiredPage from 'pages/Expired/ExpiredPage'
import FlowLoadingPage from 'pages/Home/FlowLoadingPage'
import LandingPage from 'pages/Landing/LandingPage'
import SuccessLeadRegister from 'pages/Landing/SuccessLeadRegister'
import LandinPageLeads from 'pages/Landing/LandinPageLeads'
import LandingPageSupport from 'pages/Landing/LandingPageSupport'
import AxtelCampaignLanding from 'pages/Landing/AxtelCampaignLanding'
import ForbiddenPage from 'pages/Forbidden/ForbiddenPage'
import CheckoutSales from 'pages/SalesAgent/CheckoutSales'
import CheckoutCustomer from 'pages/Customer/CheckoutCustomer'
import { useLocation } from "react-router-dom";
import { logPageView } from "utils/analytics";

const AppRoutes = () => {
  const location = useLocation();

  const navigate = useNavigate()
  const { getData } = useFlowData('')
  const { setCurrentRoute } = usePortalRoutes()
  React.useEffect(() => {
    if (!getSessionItem('flowId')) {
      /* navigate("/"); */
    }
  }, [])

  React.useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes>
      <Route
        path='/'
        element={<FlowLoadingPage />}
      ></Route>
      <Route
        path='/e7a42518-6fe6-465a-9e3e-464e4b6579a8'
        element={<LandingPage />}
      ></Route>
      <Route
        path='/e7a42518-6fe6-4658-9e3e-464e4b657999'
        element={<AxtelCampaignLanding />}
      ></Route>
      <Route
        path='/e7a42518-6fe6-465a-9e3e-464e4b65789488'
        element={<LandinPageLeads form={'v2'} />}
      ></Route>
      <Route
        path='/e7a42518-6fe6-465a-9e3e-464e4b65789489'
        element={<LandinPageLeads form={'v3'} />}
      ></Route>
      <Route
        path='/e7a42518-6fe6-465a-9e3e-464e4b65789490'
        element={<LandinPageLeads form={'v2.1'} />}
      ></Route>
      <Route
        path='/e7a42518-6fe6-465a-9e3e-464e4b65789488/success'
        element={<SuccessLeadRegister />}
      ></Route>

      <Route
        path='/a5cf7862-a93b-44e7-9a62-c2a2bed98546'
        element={<LandingPageSupport />}
      ></Route>

      <Route path='/home' element={<Home />}></Route>
      <Route path='/:flowId/:customerId' element={<FlowLoadingPage />}></Route>
      <Route path='/flow/:flowId' element={<FlowLoadingPage />}></Route>
      <Route path='/validation-beta' element={<DataValidationBeta />}></Route>
      <Route path='/products-beta' element={<ProductSelector />}></Route>
      <Route path='/payment_source' element={<Payment />}></Route>
      <Route path='/checkout' element={<Checkout />}></Route>
      <Route path='/forbidden' element={<ForbiddenPage />}></Route>
      <Route
        path='/success'
        element={
          <SuccessPage
            title='¡Bienvenido! Ahora eres nuestro Cliente Pionero'
            paragraph=' Te hemos enviado un correo electrónico con los detalles de tu
            servicio y la fecha del primer cargo mensual a tu tarjeta.'
            actionText='Por favor, suscríbete a nuestro canal de WhatsApp y danos de alta
            como contacto para que puedas comunicarte fácilmente con nosotros.'
            callToAction={true}
          />
        }
      ></Route>
      <Route
        path='/success_new'
        element={
          <SuccessPage
            title='
            ¡Bienvenido!
            Ahora eres parte de nuestra Comunidad de Nuevos Clientes'
            paragraph='Te hemos enviado un correo electrónico con la  
            fecha de instalación de tu nuevo servicio, detalles del plan contratado,
            así como de la fecha de tu primer cargo mensual a tu tarjeta.'
            actionText='Por favor, suscríbete a nuestro canal de WhatsApp y
            danos de alta como contacto para que puedas
            comunicarte fácilmente con nosotros.'
            callToAction={true}
          />
        }
      ></Route>
      <Route path='/coverage' element={<Coverage />}></Route>
      <Route path='/select-offer' element={<OffersPage />}></Route>
      <Route path='/select-date' element={<InstallationSlotsPage />}></Route>
      <Route path='/payment-method' element={<Payment />}></Route>
      <Route
        path='/validate-info'
        element={<ValidatePersonalInfoPage />}
      ></Route>
      <Route path='/no-coverage' element={<NoCoveragePage />}></Route>
      <Route path='/future-coverage' element={<FutureCoveragePage />}></Route>
      <Route path='/timeover' element={<ExpiredPage />}></Route>
      {/* SALES AGENT FLOW PAGES */}
      <Route path='/checkout-sales-agent' element={<CheckoutSales />}></Route>
      <Route
        path='/success_sales'
        element={
          <SuccessPage
            title='
            ¡Proceso de contratación guardado exitosamente!'
            paragraph='Hemos copiado la liga de contratación al portapapeles, también puedes encontrar la liga en el portal, en el detalle del prospecto.'
            actionText='Ahora puedes compartir la liga de contratación al cliente.'
            callToAction={false}
          />
        }
      ></Route>
      {/* CUSTOMERS CHECKOUT FLOW PAGES */}
      <Route path='/checkout-customer' element={<CheckoutCustomer />}></Route>

    </Routes>
  )
}

export default AppRoutes
