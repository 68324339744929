import { privateAxiosSales } from 'utils/axios'
import usePortalData from 'hooks/usePortalData'
import { notify } from 'utils/notify'
const useLeadsServices = () => {
  const { setGlobalLoading } = usePortalData()
  async function createLead(lead: any) {
    setGlobalLoading(true)
    try {
      const { data: response } = await privateAxiosSales.post('lead', lead)

      if (response.success) {
        return response
      }
    } catch (error) {
      notify('error', 'Ocurrió un problema al realizar el registro')
      console.error(error)
    } finally {
      setGlobalLoading(false)
    }
  }

  async function getLead(leadId: string) {
    //setGlobalLoading(true)
    try {
      const { data: lead } = await privateAxiosSales.get(`lead/${leadId}`)
      return lead
    } catch (error) {
      console.error(error)
      return {}
    } finally {
      //return {}
      //setGlobalLoading(false)
    }
  }

  return {
    createLead,
    getLead
  }
}

export default useLeadsServices
