import React from "react";
import BasicCard from "components/atoms/BasicCard";
import useFlowInstance from "hooks/useFlowInstance";
import InstanceValidationSales from "./InstanceValidationSales";

export default function CheckoutSales() {
  const { getFlowInstance, instanceData } = useFlowInstance();
  React.useEffect(() => {
    async function getData() {
      await getFlowInstance();
    }
    getData();
  }, []);
  return (
    <div className="flex justify-center h-screen m-6 mt-20 lg:m-20">
      <div className="w-full sm:w-1/3 mt-0">
        <BasicCard>
          <h3 className="text-xl font-semibold">
            Confirma los datos de contratación de <div>{instanceData?.prospect?.firstName} {instanceData?.prospect?.lastName}</div>
          </h3>
          <br />
          <InstanceValidationSales instance={instanceData} />
        </BasicCard>
      </div>
    </div>
  );
}