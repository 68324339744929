import React from "react";

export default function ({ label, value }: { label: string; value: string }) {
  return (
    <>
      <label className="block mb-2 text-sm text-gray-600 font-medium text-left">
        {label}
      </label>
      <p className="text-sm	">{value}</p>
    </>
  );
}
