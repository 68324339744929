import React from "react";

export default function Footer() {
  return (
    <footer className="bg-white rounded-lg shadow p-4 dark:bg-gray-800">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between text-center">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © {new Date().getFullYear()} Axe Redes, SA de CV. Derechos reservados
        </span>
        <br />
        <span className="text-sm text-gray-500">
          PROGRAMA PARA USO INTERNO EXLUSIVO DE AXTEL y Subsidiarias.
        </span>
      </div>
    </footer>
  );
}
