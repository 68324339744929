import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getSessionItem } from "utils/session";

import usePaynet from "hooks/usePaynet";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";

//Hooks
import usePortalData from "hooks/usePortalData";
import useFlowInstance from "hooks/useFlowInstance";
//Components
import PayWithSpeiComponent from "components/molecules/Payments/PayWithSpeiComponent";
import PaymentForm from "components/molecules/Payments/PaymentFormElement";
import BasicCard from "components/atoms/BasicCard";
import PaymentMethodSwitch from "components/molecules/Payments/PaymentMethodSwitch";
import OfferSummary from "components/molecules/Payments/OfferSummary";
import CardHelpDialog from "components/molecules/Payments/CardHelpDialog";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
export default function Payment() {
  const { createSetupIntent } = usePaynet();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [useBank, setUseBank] = React.useState<boolean>(false);
  const [paymentMethodType, setPaymentMethodType] =
    React.useState<string>("card");
  const stripeCustomerId = getSessionItem('stripeCustomerId')
  const [stripeClientSecret, setStripeClientSecret] = React.useState<string | null>(null);

  const { flowData } = usePortalData();

  const { getFlowInstance, instanceData } = useFlowInstance();
  React.useEffect(() => {
    const getData = async () => {
      await getFlowInstance();
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      if(stripeCustomerId){
        const response = await createSetupIntent(stripeCustomerId);
        setStripeClientSecret(response?.clientSecret)
      }
    }
    getData()
  }, [stripeCustomerId])

  if (!stripeClientSecret) {
    return <div>Cargando...</div>;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: stripeClientSecret,
        appearance: {
          variables: { colorPrimaryText: "#262626" },
        },
      }}
    >
      <div className="flex justify-center h-screen m-6 mt-20 lg:m-20">
        <CardHelpDialog
          isOpen={isOpen}
          closeDialog={() => {
            setIsOpen(false);
          }}
        />
        <div className="w-full sm:w-2/6">
          <BasicCard>
            <h3 className="text-xl font-semibold flex align-center primary_text">
              Registro de la forma de pago
              <button
                type="button"
                className="ml-auto"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <HiOutlineQuestionMarkCircle className="text-2xl" />
              </button>
            </h3>
            <OfferSummary
              offer={instanceData.offer}
              paymentMethodType={paymentMethodType}
            />
            {flowData?.paymentMethodTypes?.includes("bank_transfer") && (
              <PaymentMethodSwitch
                value={paymentMethodType}
                handleChange={(value: string) => {
                  setPaymentMethodType(value);
                }}
              />
            )}

            {paymentMethodType === "bank_transfer" && <PayWithSpeiComponent />}
            {paymentMethodType === "card" && <PaymentForm />}
          </BasicCard>
        </div>
      </div>
    </Elements>
  );
}
