import React from 'react';
import Stepper from 'components/molecules/Stepper';
import { getSessionItem } from 'utils/session';
import Timer from 'components/atoms/Timer';
import usePortalData from 'hooks/usePortalData';
import logo from '../../assets/images/logo-dark.png';
import { useLocation } from 'react-router-dom';

export default function NavigationBar() {
  const { expirationDate, flowData } = usePortalData();
  const [hideLogo, setHideLogo] = React.useState(false);
  const location = useLocation();

  const forbiddenRoutes = [
    '/home',
    '/referir',
    '/e7a42518-6fe6-465a-9e3e-464e4b6579a8',
    '/e7a42518-6fe6-465a-9e3e-464e4b65789488',
    '/e7a42518-6fe6-465a-9e3e-464e4b65789490',
    '/a5cf7862-a93b-44e7-9a62-c2a2bed98546',
    '/e7a42518-6fe6-4658-9e3e-464e4b657999',
  ];

  React.useEffect(() => {
    if (forbiddenRoutes?.includes(location.pathname)) {
      setHideLogo(true);
    } else {
      setHideLogo(false);
    }
  }, [location.pathname]);

  return !hideLogo ? (
    <nav className=' flex items-center gap-6 justify-end backdrop-blur-xl color-black py-4 px-8  bg-zinc-100items-center fixed w-full top-0 z-50'>
      <div className='w-1/2'>
        {!hideLogo && (
          <img
            src={logo}
            alt=''
            style={{
              width: 100,
            }}
            className='mr-auto '
          />
        )}
      </div>
      <div className='w-1/2 align-right lg:flex items-center justify-end'>
        {getSessionItem('expirationDate') && <Timer />}
        <Stepper />
      </div>
    </nav>
  ) : (
    <></>
  );
}
