import React from 'react'
import BasicCard from 'components/atoms/BasicCard'
import { InstanceLocation, InstanceProspect } from 'pages/Checkout/types/types'

export default function CustomerValidationCard({
  data,
  location
}: {
  data: InstanceProspect
  location: InstanceLocation
}) {
  return data ? (
    <>
      <BasicCard>
        <div className='grid grid-cols-12 gap-4'>
          {data?.businessName && (
            <div className='col-span-12 lg:col-span-6'>
              <span className='text-xs font-semibold'>Razón Social</span>
              <p className='text-sm'>{data?.businessName}</p>
            </div>
          )}
          <div className='col-span-12 lg:col-span-6'>
            <span className='text-xs font-semibold'>Nombre</span>
            <p className='text-sm'>
              {data?.firstName} {data?.lastName}
            </p>
          </div>
          <div className='col-span-12 lg:col-span-6'>
            <span className='text-xs font-semibold'>Contacto</span>
            <p className='text-sm'>{data?.email}</p>
            <p className='text-sm'>{data?.telephone}</p>
          </div>
          <div className='col-span-12 lg:col-span-12'>
            <span className='text-xs font-semibold'>Dirección</span>
            {location?.sourceAddressType === 'xtremo' &&
              location?.xtremoAddressName && (
                <p className='text-sm font-bold'>
                  {location?.xtremoAddressName}
                </p>
              )}
            <p className='text-sm'>
              {`${location?.street} ${location?.extNumber}`}
            </p>
            {location?.building && location?.floor && (
              <p className='text-sm'>{`${location?.building} Piso ${location.floor}`}</p>
            )}
            {location?.intNumber && (
              <p className='text-sm'>{`${location?.intNumber}`}</p>
            )}
            <p className='text-sm'>
              {`${location?.suburb} ${location?.city} ${location?.state} ${location?.zipCode}`}
            </p>
          </div>
        </div>
      </BasicCard>
    </>
  ) : null
}
