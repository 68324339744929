import React from 'react';

export default function CoveragesModule({
  title = '1. Revisa la cobertura donde puedes ofrecer X-tremo',
}: {
  title?: string;
}) {
  const coverages = [
    {
      id: 1,
      imageUrl:
        'https://stanitroprodscus001.blob.core.windows.net/sales-images/cobertura-san-jeronimo.png',
      image: (
        <img
          src='https://stanitroprodscus001.blob.core.windows.net/sales-images/cobertura-san-jeronimo.png'
          width='100%'
        ></img>
      ),
    },
    {
      id: 2,
      imageUrl:
        'https://stanitroprodscus001.blob.core.windows.net/sales-images/cobertura-lagos-del-bosque.png',
      image: (
        <img
          src='https://stanitroprodscus001.blob.core.windows.net/sales-images/cobertura-lagos-del-bosque.png'
          width='100%'
        ></img>
      ),
    },
    {
      name: 'Simple queues',
      description:
        'Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.',
      icon: (
        <img
          src='https://stanitroprodscus001.blob.core.windows.net/sales-images/smartphone-3.png'
          width='40%'
        ></img>
      ),
    },
    {
      name: 'Advanced security',
      description:
        'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
      icon: (
        <img
          src='https://stanitroprodscus001.blob.core.windows.net/sales-images/smartphone-4.png'
          width='40%'
        ></img>
      ),
    },
  ];
  return (
    <div className='bg-white pt-20 pb-20 '>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center'>
          <h2 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
            {title}
          </h2>
        </div>
        <div className='mx-auto mt-16  max-w-2xl lg:max-w-4xl'>
          <dl className='grid max-w-xl grid-cols-1 lg:gap-x-8 lg:gap-y-10 sm:gap-y-6 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
            {coverages.map((coverage) => (
              <div
                key={coverage.id}
                className='relative cursor-pointer '
                onClick={() => {
                  window.open(coverage.imageUrl);
                }}
              >
                {coverage.image}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
