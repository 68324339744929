import React from "react";
import { CiCircleCheck } from "react-icons/ci";

export default function MessageComponent({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <>
      <div className="flex justify-center pt-5  lg:p-10 "></div>
      <div className="flex justify-center  p-6 lg:p-10">
        <div className="w-full text-center pt-10">
          <div className="text-center flex justify-center mb-10">
            <CiCircleCheck className="text-6xl" />
          </div>

          <h3 className="font-bold text-3xl">{title}</h3>
          <p className="text-xl">{description}</p>
        </div>
      </div>
    </>
  );
}
