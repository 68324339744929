import React from 'react';
import QRCodeGenerator from 'components/atoms/QrCodeGenerator';

export default function SupportDialogBody() {
  return (
    <div className='flex flex-col items-center justify-center w-full'>
      <div className='flex flex-col justify-center w-4/5'>
        <h4 className='text-center font-semibold text-xl primary_text'>
          ¿Necesitas ayuda?
        </h4>
        <br />
        <div className='hidden sm:block'>
          <p className='text-lg text-justify'>
            Escanea el QR a continuación y uno de nuestros representantes se
            comunicará contigo en breve.
          </p>
          <br />
          <QRCodeGenerator
            urlCode={`https://wa.me/18709003692?text=Necesito%20soporte%20de%20ventas`}
          />
        </div>
        <div className='block sm:hidden'>
          <p className='text-lg text-justify'>
            Presiona el botón a continuación y uno de nuestros representantes se
            comunicará contigo en breve.
          </p>
          <br />
          <a
            href='https://wa.me/18709003692?text=Necesito%20soporte%20de%20ventas'
            target='_blank'
            rel='noreferrer'
          >
            <button className='flex items-center justify-center w-full text-white font-bold py-2 px-4 rounded bg-green-500 hover:bg-green-700'>
              Abrir Whatsapp
            </button>
          </a>
        </div>
        <br />
      </div>
      {/*
      <p className="text-center text-lg text-justify">
        Presiona el botón a continuación y uno de nuestros representantes se
        comunicará contigo en breve.
      </p>
      <br />
      <div>
        <button
          onClick={sendNotification}
          className=" w-full lg:w-full  flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded primary-btn"
        >
          Solicitar Ayuda <IoSendSharp className="ml-2" />
        </button>
      </div>
      */}
    </div>
  );
}
