import React from "react";
import { formatUnixDate } from "utils/date";
export default function SlotCardInfo({ slot, isToday = false }: any) {
  return (
    <div className="w-full p-5 border shadow-md  rounded-lg rounded shadow-sm h-fit ">
      <h3 className="text-xs font-semibold">Fecha de Instalación:</h3>
      <br />
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-xl font-semibold">
            {isToday
              ? "Hoy"
              : formatUnixDate(slot?.FromDate || slot.fromDateTime).dayFormated}
          </h3>
          <p>
            {" "}
            {
              formatUnixDate(slot?.FromDate || slot.fromDateTime).dayNumber
            } de{" "}
            {formatUnixDate(slot?.FromDate || slot.fromDateTime).monthFormated}{" "}
          </p>
        </div>
        <div>
          <h3 className="text-xl font-bold">
            {formatUnixDate(slot?.FromDate || slot.fromDateTime).hourFormated}
          </h3>
        </div>
      </div>
    </div>
  );
}
