import React from 'react'
import CenteredGrid from './components/CenteredGrid'
import LeadForm from './components/LeadForm'
import CoveragesModule from './components/CoveragesModule'
import OffersModule from './components/OffersModule'
import Footer from './components/Footer'
import ProgramBases from './components/ProgramBases'
export default function LandingPage() {
  return (
    <>
      <div
        className='flex justify-center pt-5  lg:p-10 '
        style={{
          background:
            'linear-gradient(90deg, rgba(255,42,13,1) 59%, rgba(253,189,14,1) 100%)'
        }}
      >
        <img
          src='https://stanitroprodscus001.blob.core.windows.net/sales-images/nitro-landing-banner.png'
          className=' rounded-md w-full lg:w-2/3	'
        />
      </div>
      <div className='flex justify-center  p-6 lg:p-10'>
        <div className='w-full sm:w-1/2'>
          <CenteredGrid />
          <CoveragesModule />
          <OffersModule />
          <LeadForm />
          <ProgramBases />
        </div>
      </div>
      <Footer />
    </>
  )
}
