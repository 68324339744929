import React from "react";
import useFlowInstance from "hooks/useFlowInstance";
import { BsBank } from "react-icons/bs";
import usePortalData from "hooks/usePortalData";
import { getSessionItem } from "utils/session";

export default function PayWithSpeiComponent() {
  const { updateFlowInstance } = useFlowInstance();
  const { setNextStep } = usePortalData();

  const handleContinue = async () => {
    const update = await updateFlowInstance({
      payment: {
        paymentMethodType: "bank_transfer",
        customerId: getSessionItem("stripeCustomerId") || "",
      },
    });

    if (update) {
      setNextStep();
    }
  };

  return (
    <div className="w-full">
      <br />
      <div>
        <BsBank className="text-4xl text-gray-800"></BsBank>
      </div>
      <br />
      <h4 className="text-lg font-medium flex align-center text-gray-800">
        Realiza el pago de tu servicio mediante transferencia bancarias
      </h4>
      <br />
      <p className="text-sm text-gray-800">
        <strong>Importante:</strong> Recibirás un correo con la información
        necesaria para realizar el pago.
      </p>
      <br />
      <div className="w-full">
        <button
          onClick={handleContinue}
          className=" w-full lg:w-full  flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded primary-btn"
        >
          Continuar
        </button>
      </div>
    </div>
  );
}
