// Regular expression to validate if a string has numbers
const hasNumbersRegex = /\d+/

// Regular expression to validate if a string has letters
const hasLettersRegex = /[a-zA-Z]+/

export const notNumberValues = ['firstName', 'lastName', 'cardHolderName']
export const notLettersValues = [
  'extNumber',
  'intNumber',
  'zipCode',
  'telephone'
]

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const formatPhone = (telephone: string) => {
  return telephone?.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2 $3')
}

export const cleanPhone = (telephone: string) => {
  return telephone?.replace(/[^0-9]/g, '')
}

export const removeNumbers = (str: string) => {
  return str.replace(/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '')
}

export const removeLettersV2 = (str: string) => {
  return str.replace(/[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '')
}

export const removeSpecialChars = (str: string) => {
  return str.replace(/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/g, '')
}

export const hasNumbers = (str: string) => {
  return hasNumbersRegex.test(str)
}

export const hasLetters = (str: string) => {
  return hasLettersRegex.test(str)
}

export const validateAddress = (address: any) => {
  let isAddressValid =
    !hasLetters(address?.zipCode) &&
    address?.street?.length > 0 &&
    address?.extNumber?.length > 0 &&
    address?.suburb?.length > 0 &&
    address?.city?.length > 0
  //&&address?.state?.length > 0

  if (address?.addressType === 'housingSociety') {
    //isAddressValid = isAddressValid
  }
  if (
    address?.addressType === 'apartmentsBuilding' ||
    address?.addressType === 'shoppingCentre'
  ) {
    isAddressValid =
      isAddressValid &&
      address?.intNumber?.length > 0 &&
      address.building?.length > 0 &&
      address.floor?.length > 0
  }
  return isAddressValid
}
