import { useState, CSSProperties } from "react";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import BounceLoader from "react-spinners/BounceLoader";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "blue",
};

function Spinner({ text }: { text: string }) {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");

  return (
    <div className="flex flex-col justify-center items-center	">
      <BounceLoader size={120} color={"#00063d"} />
      <p className="w-3/4 text-center text-sm text-white	">{text}</p>
    </div>
  );
}

export default Spinner;
