import React from "react";
import BasicCard from "components/atoms/BasicCard";
import useProducts from "hooks/useProducts";
import usePortalData from "hooks/usePortalData";
import useCustomerData from "hooks/useCustomerData";
import CurrentOfferConfirmation from "./ProductsBetaComponents/CurrentOfferConfirmation";

export default function ProductsBeta() {
  const { flowData, customer } = usePortalData();
  const { getCustomer } = useCustomerData();
  const { getWifiDevices, getCustomerOffer } = useCustomerData();

  React.useEffect(() => {
    const getData = async () => {
      const promises: Promise<void>[] = [];
      promises.push(getWifiDevices());
      promises.push(getCustomerOffer());
    };
    if (sessionStorage.getItem("customerId")) {
      getData();
    }
  }, []);

  React.useEffect(() => {
    const getData = () => {
      getCustomer("currentOffer,customerId");
    };
    getData();
  }, []);

  return (
    <div className="m-6 mt-10 lg:m-20">
      <div className="flex justify-center h-screen">
        <div className="w-full sm:w-1/3">
          <BasicCard>
            <h3 className="text-xl font-semibold">
              El plan que seleccionaste es:
            </h3>
            <br />
            <CurrentOfferConfirmation offer={customer?.currentOffer || null} />
            <br />
          </BasicCard>
        </div>
      </div>
    </div>
  );
}
