import React from "react";
import { getBrowserName } from "utils/browser";
import { privateAxiosSales } from "utils/axios";
import { urls } from "utils/urls";
import { notify } from "utils/notify";
import usePortalData from "./usePortalData";
import { getSessionItem } from "utils/session";

const useNotifications = () => {
  const { setGlobalLoading, flowData } = usePortalData();

  async function sendHelpNotification(step: string) {
    setGlobalLoading(true);
    const browser = getBrowserName(navigator?.userAgent);
    try {
      const { data: response } = await privateAxiosSales.post(
        urls.API_SALES_sendHelpNotification,
        {
          flowInstanceId: getSessionItem("flowInstanceId") || "",
          step: step || "",
          browser: browser || "",
        }
      );
      if (response.success) {
        notify(
          "success",
          " En breve alguien de nuestro equipo se pondrá en contacto contigo para brindarte el soporte que necesitas."
        );
      }
      return response;
    } catch (error) {
      notify("error", "Ocurrió un error al enviar la solicitud de ayuda.");
    } finally {
      setGlobalLoading(false);
    }
  }

  return {
    sendHelpNotification,
  };
};

export default useNotifications;
