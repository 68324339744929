import React from "react";
import BasicCard from "components/atoms/BasicCard";
import CustomerValidationFormBeta from "./CustomerValidationFormBeta";
import useCustomerData from "hooks/useCustomerData";

export default function DataValidationBeta() {
  const { getCustomer } = useCustomerData();
  React.useEffect(() => {
    const getData = () => {
      getCustomer(
        "customerId,firstName,lastName,email,telephone,lat,lng,street,extNumber,intNumber,suburb,city,state,zipCode,customerType,businessName"
      );
    };
    getData();
  }, []);
  return (
    <div className="flex justify-center h-screen p-6 lg:p-10	">
      <div className="w-full sm:w-1/2">
        <BasicCard>
          <h3 className="text-xl font-semibold primary_text">
            Confirma tus datos
          </h3>
          <br />
          <CustomerValidationFormBeta />
        </BasicCard>
      </div>
    </div>
  );
}
