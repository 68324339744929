import { privateAxiosCustomer } from 'utils/axios'
import usePortalData from './usePortalData'
import { urls } from 'utils/urls'
import useProductsContext from './useProductsContext'
import { getSessionItem, setSessionItem } from 'utils/session'
const useCustomerData = () => {
  const { setCustomerData } = usePortalData()
  const { setCurrentAccessPoints, setCurrentOffer } = useProductsContext()
  const getCustomer = async (fields: string, query = false) => {
    try {
      const { data: response } = await privateAxiosCustomer.get(
        urls.API_CUSTOMER_getCustomer,
        {
          params: {
            fields: fields,
            query: !query ? getSessionItem('customerId') : query
          }
        }
      )
      setCustomerData(response)
      if (getSessionItem('customerId')) {
        setSessionItem('customerId', response.customerId)
      }
      return response
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getWifiDevices = async () => {
    try {
      const { data: response } = await privateAxiosCustomer.get(
        urls.API_CUSTOMER_getCustomerWifi,
        {
          params: {
            fields: 'accessPoints',
            query: getSessionItem('customerId')
          }
        }
      )

      setCurrentAccessPoints(response.accessPoints)
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const getCustomerOffer = async () => {
    try {
      const { data: response } = await privateAxiosCustomer.get(
        urls.API_CUSTOMER_getCustomerOffer,
        {
          params: {
            fields: 'bandwidth,name',
            query: getSessionItem('customerId')
          }
        }
      )
      setCurrentOffer(response)
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  async function getAddresses(query: string) {
    try {
      const { data: response } = await privateAxiosCustomer.get(
        urls.API_CUSTOMER_getAddresses,
        {
          params: {
            query, 
            limit: 5
          }
        }
      )

      return response
    } catch (error) {
      console.error(error)
      console.log(error)
    }
  }

  async function getAddress(query: string) {
    try {
      const { data: response } = await privateAxiosCustomer.get(
        urls.API_CUSTOMER_getAddress,
        {
          params: {
            query
          }
        }
      )

      return response
    } catch (error) {
      console.error(error)
      console.log(error)
    }
  }

  return {
    getCustomer,
    getWifiDevices,
    getCustomerOffer,
    getAddresses,
    getAddress
  }
}

export default useCustomerData
