import React from "react";
import BasicCard from "components/atoms/BasicCard";
import useFlowInstance from "hooks/useFlowInstance";
import InstanceValidation from "./InstanceValidation";
import { getSessionItem } from "utils/session";
export default function Checkout() {
  const { getFlowInstance, instanceData } = useFlowInstance();
  React.useEffect(() => {
    async function getData() {
      getFlowInstance();
    }
    getData();
  }, []);
  return (
    <div className="flex justify-center  h-screen m-6 mt-20 lg:m-20">
      <div className="w-full sm:w-1/3 mt-0">
        <BasicCard>
          <h3 className="text-xl font-semibold">
            {getSessionItem("customerId")
              ? "Termina tu registro"
              : "Completemos tu registro"}
          </h3>
          <br />
          <InstanceValidation instance={instanceData} />
        </BasicCard>
      </div>
    </div>
  );
}
