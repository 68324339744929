import React from 'react';
import { ImBlocked } from 'react-icons/im';

export default function ForbiddenPage() {
  return (
    <div className='p-6'>
      <div className='grid grid-cols-12 mt-60'>
        <div className='col-span-12'>
          <div className='flex justify-center'>
            <ImBlocked className='text-2xl' />
          </div>
        </div>
        <div className='col-span-12 text-center'>
          <h3 className='font-semibold text-xl'>
            Por el momento no tienes acceso a esta página
          </h3>
          <p> Contacta a tu administrador para obtener acceso.</p>
        </div>
      </div>
    </div>
  );
}
