import { privateAxiosProducts, privateAxiosSales } from "utils/axios";

import { urls } from "utils/urls";
import usePortalData from "./usePortalData";
import useProductsContext from "./useProductsContext";
import React from "react";
const useProducts = () => {
  const { flowData, setGlobalLoading } = usePortalData();
  const { setProducts } = useProductsContext();

  async function getProducts() {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosSales.get(
        `/flow/${sessionStorage.getItem("flowId")}/offers`
      );

      setProducts(response);
    } catch (error) {
      console.log(error);
    } finally {
      setGlobalLoading(false);
    }
  }
  return { getProducts };
};

export default useProducts;
