import React, { useRef } from "react";
import { htmlContent } from "utils/constants/objects";
export default function Privacy() {
  const myComponentRef: any = useRef(null);
  React.useEffect(() => {
    if (myComponentRef.current) {
      myComponentRef.current.scrollTop = 0;
    }
  }, []);
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
    </div>
  );
}
