import React from "react";
import BasicCard from "components/atoms/BasicCard";
import BasicField from "components/atoms/BasicField";
import { isEmpty } from "lodash";
export default function ReferralCodeValidationCard({
  referralCode,
  setReferralCode,
  validReferralCode,
  onHandleValidate,
  data,
}: any) {
  return (
    <BasicCard>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-12">
          <span className="text-xs font-semibold">
            Aplicar código de réferido
          </span>
          <div className="grid grid-cols-12 gap-2 mt-2 mb-2">
            <div className="col-span-7 lg:col-span-8">
              <BasicField
                required={false}
                value={referralCode || ""}
                label=""
                name="referralCode"
                placeholder="COU1234"
                onChange={(e: any) => setReferralCode(e.target.value)}
              />
            </div>
            <div className="col-span-5 lg:col-span-4">
              <button
                onClick={() => onHandleValidate(referralCode)}
                disabled={!referralCode}
                className="w-full mb-2 text-white font-bold py-2 px-4 rounded secondary-btn"
              >
                Validar
              </button>
            </div>
            <div className="col-span-12 lg:col-span-12">
              <div className="grid grid-cols-12 gap-4">
                {validReferralCode && !isEmpty(data) && (
                  <div className="col-span-12 lg:col-span-12">
                    <span className="text-xs font-semibold">Réferido por:</span>
                    <div className="flex items-center gap-3">
                      <p className="text-sm">
                        {data?.customer?.firstName} {data?.customer?.lastName}
                      </p>
                      <p className="text-sm font-bold text-green-500">
                        {data?.referralCode}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicCard>
  );
}
