import { useContext } from "react";
import { Context } from "context/Context";

type TusePortalData = {
  flowData: any;
  customer: any;
  currentStep: number;
  isGlobalLoading: boolean;
  expirationDate: string;
  setGlobalLoading: (isGlobalLoading: boolean) => void;
  setCustomerData: (payload: any) => void;
  setFlowData: (payload: any) => void;
  setNextStep: () => void;
  setExpirationDate: (date: string) => void;
};
const usePortalData = (): TusePortalData => {
  const {
    globalState: {
      flowData,
      customer,
      currentStep,
      isGlobalLoading,
      expirationDate,
    },
    setFlowData,
    setCustomerData,
    setGlobalLoading,
    setNextStep,
    setExpirationDate,
  } = useContext(Context);
  return {
    flowData,
    customer,
    currentStep,
    isGlobalLoading,
    expirationDate,
    setFlowData,
    setCustomerData,
    setGlobalLoading,
    setNextStep,
    setExpirationDate,
  };
};

export default usePortalData;
