import React from 'react';
import AxtelLeadForm from './components/AxtelLeadForm';
import CoveragesModule from './components/CoveragesModule';
import ProgramBases from './components/ProgramBases';
import Footer from './components/Footer';
import useLeadsServices from 'hooks/leads/useLeadsServices';
export default function AxtelCampaignLanding() {
  const bases = [
    'Para obtener la  promoción de dos meses sin costo, solo se consideran los colaboradores que hayan hecho su registro hasta el 31 de Enero del 2024',
    'El programa solo aplica para colaboradores activos de Axtel y subsidiarias.',
    'Cualquier duda enviar un correo a dinguanzov@axtel.com.mx',
  ];
  return (
    <>
      <div
        className='flex justify-center pt-5  lg:p-10 '
        style={{
          background:
            'linear-gradient(90deg, rgba(255,42,13,1) 59%, rgba(253,189,14,1) 100%)',
        }}
      >
        <img
          src='https://stanitroprodscus001.blob.core.windows.net/sales-images/nitro-landing-banner.png'
          className=' rounded-md w-full lg:w-2/3	'
        />
      </div>

      <div className='flex justify-center'>
        <div className=' w-full lg:w-1/2'>
          <div className='grid grid-cols-12 mb-10'>
            <div className='col-span-12 p-6'>
              <AxtelLeadForm />
            </div>

            <div className='col-span-12'>
              <CoveragesModule title='Esta es la cobertura de X-tremo' />
            </div>

            <div className='col-span-12'>
              <ProgramBases bases={bases} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
