import React, { createContext, useReducer } from "react";
import { TproductsInitialState } from "context/types";
import reducer from "./reducer";
export const ProductsContext: any = createContext<any>("");
export const ProductsProvider = (props: any): any => {
  const initialState: TproductsInitialState = {
    products: [],
    selectedProducts: [],
    selectedOffer: {},
    currentOffer: {},
    currentAccessPoints: [],
    extraNodes: 0,
  };

  const [globalState, dispatch]: any = useReducer(reducer, initialState); //
  const { children } = props;

  const setProducts = async (products: any[]) => {
    try {
      dispatch({
        type: "SET_PRODUCTS",
        payload: products,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedProducts = async (selectedProducts: string[]) => {
    try {
      dispatch({
        type: "SET_SELECTED_PRODUCTS",
        payload: selectedProducts,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setCurrentAccessPoints = async (accessPoints: any[]) => {
    try {
      dispatch({
        type: "SET_CURRENT_ACCESS_POINTS",
        payload: accessPoints,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setSelectedOffer = async (offer: any) => {
    try {
      dispatch({
        type: "SET_SELECTED_OFFER",
        payload: offer,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setCurrentOffer = async (offer: any) => {
    try {
      dispatch({
        type: "SET_CURRENT_OFFER",
        payload: offer,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProductsContext.Provider
      value={{
        products: globalState.products,
        extraNodes: globalState.extraNodes,
        currentOffer: globalState.currentOffer,
        currentAccessPoints: globalState.currentAccessPoints,
        selectedProducts: globalState.selectedProducts,
        selectedOffer: globalState.selectedOffer,
        setProducts,
        setSelectedProducts,
        setCurrentAccessPoints,
        setCurrentOffer,
        setSelectedOffer,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
