import React from "react";
import BasicField from "components/atoms/BasicField";
import { formatPhone } from "utils/validations";
export default function BasicCustomerDataForm({
  customerData,
  onChange,
}: {
  customerData: any;
  onChange: any;
}) {
  return (
    <>
      {customerData?.customerType === "business" && (
        <div className="col-span-12 lg:col-span-12">
          <BasicField
            required={true}
            value={customerData?.businessName}
            label="Razón Social"
            name="businessName"
            onChange={onChange}
          />
        </div>
      )}

      <div className="col-span-6 lg:col-span-6">
        <BasicField
          required={true}
          value={customerData?.firstName}
          label="Nombre"
          name="firstName"
          onChange={onChange}
        />
      </div>
      <div className="col-span-6 lg:col-span-6">
        <BasicField
          required={true}
          value={customerData?.lastName}
          label="Apellidos"
          name="lastName"
          onChange={onChange}
        />
      </div>
      <div className="col-span-12 lg:col-span-6 ">
        <BasicField
          type="email"
          required={true}
          value={customerData?.email}
          label="Correo electrónico"
          name="email"
          onChange={onChange}
        />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <BasicField
          required={true}
          type="phone"
          maxLength="14"
          value={formatPhone(customerData?.telephone)}
          label="Teléfono"
          name="telephone"
          onChange={onChange}
        />
      </div>
    </>
  );
}
