import React, { createContext, useReducer } from 'react'
import reducer from './reducer'
export const GoogleMapsContext: any = createContext<any>('')
export const GoogleMapsProvider = (props: any): any => {
  const initialState: any = {
    isGMLoaded: false
  }

  const [globalState, dispatch]: any = useReducer(reducer, initialState) //
  const { children } = props

  const setIsLoaded = async (isLoaded: any) => {
    try {
      dispatch({
        type: 'SET_IS_LOADED',
        payload: isLoaded
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <GoogleMapsContext.Provider
      value={{
        isGMLoaded: globalState.isGMLoaded,
        setIsLoaded
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  )
}
