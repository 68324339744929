import React from 'react'
import LeadFormV2 from './LeadFormV2'
import LeadFormV3 from './LeadFormV3'
import LeadFormV2_1 from './LeadFormV2.1'
export default function LandinPageLeads({ form }: { form?: string }) {
  return (
    <>
      <div className='flex justify-center lg:p-10 '></div>
      <div className='flex justify-center lg:p-10'>
        <div className='w-full sm:w-full'>
          {form === 'v2.1' ? (
            <LeadFormV2_1 />
          ) : form === 'v3' ? (
            <LeadFormV3 />
          ) : (
            <LeadFormV2 />
          )}
          {/* {form === 'v3' ? <LeadFormV3 /> : <LeadFormV2 />} */}
        </div>
      </div>
    </>
  )
}
