import React from "react";
import BasicCard from "components/atoms/BasicCard";
import MasterCard from "../../../assets/images/mastercard.png";
import Visa from "../../../assets/images/visa.png";

//Icons
import { BsBank } from "react-icons/bs";
export default function PaymentSourceValidation({
  data,
  type = "",
}: {
  data: any;
  type?: string;
}) {
  const icons: any = {
    visa: <img src={Visa} alt="Visa" width="30" />,
    mastercard: <img src={MasterCard} alt="MasterCard" width="30" />,
  };
  return (
    data && (
      <>
        <BasicCard>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-12">
              <span className="text-xs font-semibold">Método de pago</span>
              {type === "card" && (
                <div className="flex items-center gap-10">
                  <p className="text-sm">***{data?.card?.last4}</p>{" "}
                  {icons[data?.card?.brand] ? icons[data?.card?.brand] : null}
                </div>
              )}

              {type === "bank_transfer" && (
                <div className="flex items-center gap-3">
                  <BsBank /> <p className="text-sm">Transferencia bancaria</p>
                </div>
              )}
            </div>
          </div>
        </BasicCard>
      </>
    )
  );
}
