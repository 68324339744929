import React from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { pathsToRequiredHelp } from 'utils/constants/mailNotifications';
import AskFoSupportDialog from 'components/molecules/AskForSupportDialog';
import useNotifications from 'hooks/useNotifications';
import usePortalData from 'hooks/usePortalData';
const FloatHelpButton = () => {
  const [showButton, setShowButton] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [disabledButton, setDisableButton] = React.useState(false);
  const { sendHelpNotification } = useNotifications();
  const location = useLocation();
  const { flowData } = usePortalData();

  React.useEffect(() => {
    const pathname: any = location.pathname.substring(1);
    setShowButton(flowData?.supportSteps?.includes(pathname));
  }, [location.pathname, flowData]);

  const submitNotification = async () => {
    const sendNotification = await sendHelpNotification(
      location.pathname.substring(1)
    );
    if (!sendNotification) return;

    setDisableButton(true);
    setTimeout(() => {
      setDisableButton(false);
    }, 180000);

    setOpenConfirmation(false);
  };
  return (
    <>
      {showButton && (
        <>
          <AskFoSupportDialog
            open={openConfirmation}
            setOpen={setOpenConfirmation}
          />
          <button
            disabled={disabledButton}
            onClick={() => {
              setOpenConfirmation(true);
            }}
            className='fixed flex items-center justify-center bottom-14 h-10 w-fit right-4 p-4 bg-blue-500 rounded-full cursor-pointer shadow-md z-50 hover:bg-blue-700 disabled:opacity-50 primary-btn-yellow '
          >
            <BiHelpCircle className='text-2xl' />
            <span className=' text-sm'>Ayuda</span>
          </button>
        </>
      )}
    </>
  );
};

export default FloatHelpButton;
