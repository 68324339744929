const urls: { [key: string]: any } = {
  API_SALES_getFlow: 'flow/',
  API_SALES_createFlowInstance: 'flow/instance',
  API_SALES_updateFlowInstance: 'flow/instance',
  API_SALES_getFlowInstance: 'flow/instance',
  API_SALES_createFlowInstanceCheckout: 'flow/instance/checkout',
  API_SALES_sendHelpNotification: 'mail/help/send',
  API_SALES_getReferralCode: 'referral-code',
  API_SALES_createFlowInstanceCheckoutHiringUrl: 'flow/instance/checkout/hiring-url',

  API_CUSTOMER_getCustomer: 'customer',
  API_CUSTOMER_getCustomerWifi: 'customer/wifi',
  API_CUSTOMER_getCustomerOffer: 'customer/offer',

  API_NETVAULT_prereservatePort: 'reservations/dp/port/prereservate',
  API_NETVAULT_getCoverage: 'location/service',
  API_CUSTOMER_getAddresses: 'addresses',
  API_CUSTOMER_getAddress: 'address',

  API_PRODUCTS_getProducts: 'products',
  API_PAYNET_postCheckoutPayment: 'customer/checkout',
  API_PAYNET_createCustomer: 'customer',
  API_PAYNET_createSetupIntent: 'setup-intent',
  API_PAYNET_getPaymentMethod: 'payment-method',

  API_FS_getAvailableSlots: '/slots/available',
  API_FS_reserveSlot: '/slots/reserve'
}

export { urls }
