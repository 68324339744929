import axios from 'axios'

interface LatLng {
  lat: number
  lng: number
}

export async function getAddressFromLatLng(latLng: LatLng): Promise<string> {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${API_KEY}`

  try {
    const response = await axios.get(URL)

    if (response.data.status === 'OK') {
      const result: any = response.data.results[0]
      const address: any = {
        street_number: result?.address_components[0]?.long_name,
        street: result?.address_components[1]?.long_name,
        suburb: result?.address_components[2]?.long_name,
        city: result?.address_components[3]?.long_name,
        state: result?.address_components[4]?.long_name,
        country: result?.address_components[5]?.long_name,
        zipCode: result?.address_components[6]?.long_name,
        placeId: result?.place_id
      }
      return address
    } else {
      throw new Error('Geocoding request failed')
    }
  } catch (error) {
    throw new Error('Error fetching address')
  }
}

// export async function getPlacePredictions(query: string): Promise<string> {
//   const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY
//   const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${API_KEY}`

//   try {
//     const response = await axios.get(URL)

//     if (response.data.status === 'OK') {
//       const result: any = response.data.results[0]
//       const address: any = {
//         street_number: result?.address_components[0]?.long_name,
//         street: result?.address_components[1]?.long_name,
//         suburb: result?.address_components[2]?.long_name,
//         city: result?.address_components[3]?.long_name,
//         state: result?.address_components[4]?.long_name,
//         country: result?.address_components[5]?.long_name,
//         zipCode: result?.address_components[6]?.long_name
//       }
//       return address
//     } else {
//       throw new Error('Geocoding request failed')
//     }
//   } catch (error) {
//     throw new Error('Error fetching address')
//   }
// }
