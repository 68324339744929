import React, { useState } from "react";
import QRCode from "qrcode.react";

function QRCodeGenerator({ urlCode }: { urlCode: string }) {
  React.useEffect(() => {
    setUrl(urlCode);
  }, [urlCode]);
  const [url, setUrl] = useState("");
  const handleInputChange = (event: any) => {
    setUrl(event.target.value);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {url && <QRCode value={url} />}
    </div>
  );
}

export default QRCodeGenerator;
