import React from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import useCustomerData from 'hooks/useCustomerData'
import useFlowInstance from "hooks/useFlowInstance";
//import useFlowData from 'hooks/useFlowData'
import { setSessionItem, clearSession } from 'utils/session'
import Spinner from 'components/atoms/Spinner'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

export default function FlowLoadingPage() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const referralCode = queryParams.get('ref')
  const leadId = queryParams.get('leadId')
  const flowToken = queryParams.get('flowToken')
  const flowInstanceId = queryParams.get('flowInstanceId')
  const utm = {
    ...(queryParams.get('utm_source') && { utm_source: queryParams.get('utm_source') }),
    ...(queryParams.get('utm_medium') && { utm_medium: queryParams.get('utm_medium') }),
    ...(queryParams.get('utm_campaign') && { utm_campaign: queryParams.get('utm_campaign') }),
    ...(queryParams.get('utm_term') && { utm_term: queryParams.get('utm_term') }),
    ...(queryParams.get('utm_content') && { utm_content: queryParams.get('utm_content') })
  }
  
  const { flowId, customerId }: any = useParams()
  const { getFlowInstance } = useFlowInstance();

  if (leadId) {
    clearSession()
    sessionStorage.setItem('leadId', leadId)
  }

  if(!flowId){
    let defaultFlow = process.env.REACT_APP_DEFAULT_FLOW
    if(defaultFlow){
      sessionStorage.setItem('flowId', defaultFlow)
    }
  } else {
    sessionStorage.setItem('flowId', flowId)
  }

  if (flowToken) {
    sessionStorage.setItem('flowToken', flowToken)
  }

  if(!_.isEmpty(utm)){
    sessionStorage.setItem('utm', JSON.stringify(utm))
  }

  const { getCustomer } = useCustomerData()
  const navigate = useNavigate()

  React.useEffect(() => {
    const getData = async () => {
      setSessionItem('currentStep', '0')
      if (customerId) {
        sessionStorage.setItem('customerId', customerId)
        await getCustomer('customerId,firstName')
      }
      if (referralCode) {
        sessionStorage.setItem('referralCode', referralCode)
      }
      navigate('/home')
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const getData = async () => {
      if(flowInstanceId){
        sessionStorage.setItem('flowInstanceId', flowInstanceId)
        await getFlowInstance();
      }
    }
    getData()
  }, [flowInstanceId])

  return (
    <>
      <div className='h-full flex align-center justify-center items-center background_animation_brand '>
        <div className='w-full'>
          <Spinner text='Estamos preparando tu nueva experiencia' />
        </div>
      </div>
    </>
  )
}
