import React, { useState, useEffect } from "react";
import usePortalData from "hooks/usePortalData";
import { getSessionItem } from "utils/session";
import { useNavigate, useLocation } from "react-router-dom";

const Timer: React.FC = () => {
  const targetDate: any = new Date(
    new Date(getSessionItem("expirationDate") || "" + "UTC")
  );
  const currentDate: any = new Date();
  const initialTime: any = (targetDate - currentDate) / 1000; // 15 minutes in seconds
  const [time, setTime] = useState<number>(Number(parseInt(initialTime)));
  const { expirationDate } = usePortalData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const targetDate: any = new Date(
      new Date(getSessionItem("expirationDate") || "" + "UTC")
    );
    const currentDate: any = new Date();
    const initialTime: any = (targetDate - currentDate) / 1000;
    setTime(Number(parseInt(initialTime)));
  }, [ getSessionItem("expirationDate"), location ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      } else {
        clearInterval(interval);
        navigate("/timeover");
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  useEffect(() => {
    // Save the time in local storage
    localStorage.setItem("timer", JSON.stringify(time));
  }, [time]);

  return (
    <div className=" flex items-center gap-3  ">
      <span className="text-xs hidden lg:block">Cuentas con</span>
      <div className="text-2xl font-bold text-red-500	">
        {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
      </div>
      <span className="text-xs hidden lg:block ">
        para completar el proceso
      </span>
    </div>
  );
};

export default Timer;
