export const setSessionItem = (key: string, value: string): any => {
  return sessionStorage.setItem(key, value);
};
export const getSessionItem = (key: string): any => {
  return sessionStorage.getItem(key);
};

export const removeSessionItem = (key: string): any => {
  return sessionStorage.removeItem(key);
};

export const clearSession = (): any => {
  return sessionStorage.clear();
};