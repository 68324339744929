import React from "react";
import SlotCardInfo from "components/atoms/InstallationSlots/SlotCafdInfo";
export default function InstallationValidation({ data }: { data: any }) {
  return (
    data?.reservationId && (
      <>
        <SlotCardInfo slot={data?.slot} />
      </>
    )
  );
}
