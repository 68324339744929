import React from "react";
export default function BasicDatePicker({ value, onChange, min }: any) {

  return (
    <div className="w-full">
      <input
        className=" border border-gray-300 text-gray-900 mb-2 p-3 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
        type="date"
        id="datePicker"
        name="datePicker"
        lang="es"
        value={value}
        min={min}
        onChange={onChange}
        onKeyDown={(e) => e.preventDefault()}
        // Add any additional attributes or event handlers here
      />
    </div>
  );
}
