import { getSessionItem } from "utils/session";

export const getTimer = () => {
  const expirationDate: any = new Date(
    new Date(getSessionItem("expirationDate") || "" + "UTC")
  );
  const currentDate: any = new Date();
  const initialTime: any = (expirationDate - currentDate) / 1000; // 15 minutes in seconds
  const time = Number(parseInt(initialTime));
  return time;
};
