import React from "react";
import { privateAxiosSales } from "utils/axios";
import { urls } from "utils/urls";
import { useNavigate } from "react-router-dom";
import usePortalData from "./usePortalData";
import { notify } from "utils/notify";
import { getSessionItem, setSessionItem } from "utils/session";
import { sendGTMEvent } from "utils/js/gtm";
import { getTimer } from "utils/timer";
const useFlowInstance = () => {
  const [instanceData, setInstanceData] = React.useState<any>({});
  const navigate = useNavigate();
  const { setGlobalLoading, flowData } = usePortalData();

  async function createFlowInstance(payload: any) {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosSales.post(
        urls.API_SALES_createFlowInstance,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }

  async function createFlowInstanceCheckout() {
    setGlobalLoading(true);

    const currentPath: number = parseInt(getSessionItem("currentStep"));
    
    // sendGTMEvent('service_contracting_process', {
    //   "lastPathInteracted": flowData?.pathOrder[currentPath] || "none",
    //   "flowId": sessionStorage.getItem("flowId") || "none",
    // })
    
    const timer = getTimer();
    
    if(timer < 0){
      navigate("/timeover");
      return;
    }

    try {
      const { data: response } = await privateAxiosSales.post(
        urls.API_SALES_createFlowInstanceCheckout,
        {
          flowInstanceId: sessionStorage.getItem("flowInstanceId"),
        }
      );

      if (response.success) {
        if (getSessionItem("customerId")) {
          navigate("/success");
        } else {
          navigate("/success_new");
        }

        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }

  async function getFlowInstance() {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosSales.get(
        urls.API_SALES_getFlowInstance +
          "/" +
          sessionStorage.getItem("flowInstanceId")
      );

      if (getSessionItem("flowType") === "customer_payment") {
        setSessionItem(
          "stripeCustomerId",
          response?.flowInstance?.payment?.customerId
        );
        if(response?.flowInstance?.dp?.preReservationPortExpireDate){
          setSessionItem("expirationDate", response?.flowInstance?.dp?.preReservationPortExpireDate)
        }
      }

      setInstanceData(response?.flowInstance);
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }

  async function updateFlowInstance(
    payload: any,
    referralCode: boolean = false
  ) {
    setGlobalLoading(true);

    if (!referralCode) {
      const currentPath: number = parseInt(getSessionItem("currentStep"));
      payload.lastPathInteracted = flowData?.pathOrder[currentPath] || "none";
    }

    // sendGTMEvent('service_contracting_process', {
    //   "lastPathInteracted": payload.lastPathInteracted || "none",
    //   "flowId": sessionStorage.getItem("flowId") || "none",
    // })

    const timer = getTimer();
    
    if(timer < 0){
      navigate("/timeover");
      return;
    }

    try {
      const { data: response } = await privateAxiosSales.put(
        urls.API_SALES_updateFlowInstance +
          "/" +
          sessionStorage.getItem("flowInstanceId"),
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }

  async function createFlowInstanceCheckoutHiringUrl() {
    const timer = getTimer();
    
    if(timer < 0){
      navigate("/timeover");
      return;
    }

    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosSales.post(
        urls.API_SALES_createFlowInstanceCheckoutHiringUrl,
        {
          flowInstanceId: sessionStorage.getItem("flowInstanceId"),
          hiringPath: `${process.env.REACT_APP_SALES_PORTAL_URL}/flow/${process.env.REACT_APP_FLOW_CUSTOMERS_CHECKOUT}`,
        }
      );
      if (response.success) {
        notify(
          "success",
          "Liga de contratación copiada al portapapeles"
        );
        await navigator.clipboard.writeText(response.hiring_url);
        navigate("/success_sales");
        
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  }
  return {
    createFlowInstance,
    updateFlowInstance,
    getFlowInstance,
    createFlowInstanceCheckout,
    createFlowInstanceCheckoutHiringUrl,
    instanceData,
  };
};

export default useFlowInstance;
