import React from 'react';
import BasicDialog from 'components/atoms/BasicDialog';
import GoogleMapComponent from '../GoogleMapComponent';
import BasicCard from 'components/atoms/BasicCard';
export default function LocationConfirmationDialog({
  open,
  setOpen,
  handleChange,
  coordinates,
  onConfirm,
  address,
}: {
  open: boolean;
  setOpen: Function;
  handleChange: Function;
  coordinates: any;
  onConfirm: Function;
  address: any;
}) {
  return (
    <BasicDialog isOpen={open} closeDialog={() => setOpen(false)} style={{}}>
      <div className='grid grid-cols-12 gap-3'>
        <div className='col-span-12 text-center'>
          <h3 className='text-xl font-semibold'>Confirma Tu ubicación</h3>
          <p>
            Asegurate de que el pin se encuentre en en el sitio donde deseas que
            instalemos el servicio.{' '}
          </p>
        </div>

        <div className='col-span-12'>
          <BasicCard>
            <p className='text-stone-600	 text-xs'>
              {address.street} {address.extNumber} {address.suburb}{' '}
              {address.city} {address.state} {address.country} {address.zipCode}
            </p>
          </BasicCard>
        </div>
        <div className='col-span-12'>
          <GoogleMapComponent
            isDraggable={true}
            initialPosition={{
              lat: coordinates.lat || 25.67507,
              lng: coordinates.lng || -100.31847,
            }}
            zoom={20}
            onMarkerDragEnd={(value: any) => {
              handleChange(value);
            }}
          />
        </div>
        <div className=' col-span-12'>
          <span className='text-xs'>
            Mueve el pin en el mapa para ajustar tu ubicación 📍
          </span>
        </div>
        <div className=' col-span-12'>
          <button
            className='bg-primary_text primary-btn text-white rounded-lg p-2 w-full'
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
          >
            CONFIRMAR
          </button>
        </div>
      </div>
    </BasicDialog>
  );
}
