import React from "react";

export default function BasicChip({
  children,
  background,
}: {
  children: any;
  background: string;
}) {
  return (
    <div className="rounded-lg p-1 w-fit" style={{ background: background }}>
      {children}
    </div>
  );
}
