import React from 'react';
import usePortalData from 'hooks/usePortalData';
import useGoogleMaps from 'hooks/useGoogleMaps';
import GoogleMapComponent from 'components/molecules/GoogleMapComponent';
import useFlowInstance from 'hooks/useFlowInstance';
import { setSessionItem, getSessionItem } from 'utils/session';
import BasicTextLabel from 'components/atoms/BasicTextLabel';
import {
  CUSTOMER_FIELDS,
  CUSTOMER_BUSINESS_FIELDS,
} from 'utils/constants/formValidations';
import { validateEmail } from 'utils/validations';
import BasicCheckBox from 'components/atoms/BasicCheckBox';
import usePaynet from 'hooks/usePaynet';
import { Customer } from './types/types';
import { formatPhone, cleanPhone, removeNumbers } from 'utils/validations';
import BasicCustomerDataForm from 'components/molecules/DataValidation/BasicCustomerDataForm';
import BasicDialog from 'components/atoms/BasicDialog';
import Privacy from 'components/atoms/Privacy';
import { notify } from 'utils/notify';
import { getBrowserName } from 'utils/browser';

export default function CustomerValidationFormBeta() {
  const { customer, setNextStep } = usePortalData();
  const [customerData, setCustomerData] = React.useState<Customer>(
    customer || {}
  );
  const { updateFlowInstance } = useFlowInstance();
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [checkPrivacy, setCheckPrivacy] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [acceptanceDate, setAcceptanceDate] = React.useState<string>('');
  const { createStripeCustomer } = usePaynet();
  const flowType: any = getSessionItem('flowType');

  useGoogleMaps();

  React.useEffect(() => {
    if (customerData) {
      validateFields(customerData);
    }
  }, [customerData]);

  React.useEffect(() => {
    setCustomerData(customer);
  }, [customer]);

  React.useEffect(() => {
    if (checkPrivacy) {
      setAcceptanceDate(new Date().toISOString());
    }
  }, [checkPrivacy]);

  function validateFields(obj: any) {
    const keys =
      customer.customerType === 'business'
        ? CUSTOMER_BUSINESS_FIELDS
        : CUSTOMER_FIELDS;
    const warnings = [];
    for (const key of keys) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        warnings.push('No deben existir campos vacíos');
      }
      if (key === 'email' && !validateEmail(obj[key])) {
        warnings.push('El correo electrónico no es válido');
      }
      if (key === 'telephone' && customerData[key]?.length < 10) {
        warnings.push('El teléfono no es válido');
      }
    }
    setErrorMessages(warnings);
    return true;
  }

  const handleSubmit = async () => {
    const payload: any = {
      prospect: {
        customerId: customerData?.customerId,
        firstName: customerData?.firstName.trim(),
        lastName: customerData?.lastName.trim(),
        email: customerData?.email.trim(),
        telephone: customerData?.telephone.trim().replace(/[\s()]/g, ''),
      },
      location: {
        street: customerData?.street,
        extNumber: customerData?.extNumber,
        intNumber: customerData?.intNumber,
        suburb: customerData?.suburb,
        city: customerData?.city,
        state: customerData?.state,
        country: customerData?.country,
        zipCode: customerData?.zipCode,
        lat: customerData?.lat,
        lng: customerData?.lng,
      },
      browser: getBrowserName(navigator?.userAgent),
      privacyAcceptedDate: acceptanceDate,
    };

    if (customer?.customerType === 'business') {
      payload.prospect.businessName = customerData?.businessName.trim();
    }

    if (!getSessionItem('stripeClientSecret')) {
      const stripeCustomer = await createStripeCustomer(payload.prospect);
      if (!stripeCustomer) {
        notify('error', 'Error al validar datos');
        return;
      }
    }

    const response = await updateFlowInstance(payload);
    if (response.success) {
      setSessionItem('customerData', JSON.stringify(payload.prospect));
      setNextStep();
    }
  };
  const handleChange = (event: any) => {
    const notNumberValues: string[] = ['firstName', 'lastName'];
    const validatePhone: string[] = ['telephone'];
    const nameValue = event.target.name;
    if (notNumberValues.includes(nameValue)) {
      event.target.value = removeNumbers(event.target.value);
    }
    if (validatePhone.includes(nameValue)) {
      event.target.value = cleanPhone(event.target.value);
    }
    setCustomerData((prevEvent: any) => ({
      ...prevEvent,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <div className='w-full'>
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openDialog}
        closeDialog={() => {
          setOpenDialog(false);
        }}
      >
        <Privacy></Privacy>
      </BasicDialog>
      <div className='grid grid-cols-12 gap-4'>
        <BasicCustomerDataForm
          customerData={customerData}
          onChange={handleChange}
        />
        <div className='col-span-12 lg:col-span-12 mt-3'>
          <BasicTextLabel
            label='Dirección'
            value={`${customerData.street},${customerData.extNumber} ${
              customerData.intNumber || ''
            }, ${customerData.suburb}, ${customerData.city}, ${
              customerData.state
            }, ${customerData.zipCode} `}
          />
        </div>
        <div className='col-span-12'>
          <GoogleMapComponent
            initialPosition={{
              lat: customerData?.lat || 25.67507,
              lng: customerData?.lng || -100.31847,
            }}
            onMarkerDragEnd={() => {}}
          />
        </div>
        <div className='col-span-12'>
          <BasicCheckBox
            value={checkPrivacy}
            onChange={(e: any) => {
              setCheckPrivacy(e.target.checked);
            }}
          >
            <p className='text-sm ml-2'>
              He leído y acepto el{' '}
              <a
                onClick={() => {
                  setOpenDialog(true);
                }}
                className='cursor-pointer link_text	'
              >
                <strong>Aviso de Privacidad</strong>
              </a>
            </p>
          </BasicCheckBox>
        </div>

        <div className='col-span-12 flex justify-end lg:justify-center'>
          <button
            onClick={handleSubmit}
            type='submit'
            disabled={errorMessages?.length > 0 || !checkPrivacy}
            className=' w-full lg:w-2/4 flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3 rounded disabled:opacity-50 primary-btn'
          >
            CONFIRMAR
          </button>
        </div>
      </div>
    </div>
  );
}
