import React from 'react';

export default function ProgramBases({
  bases = [
    'Solo se considerarán referidos registrados por el colaborador de Axtel que contraten el servicio antes del 31 de Diciembre del 2023.',
    'Podrás canjear tu equipo una vez que tu cliente haya sido instalado.',
    'Fecha límite de canje es el 31 de Enero del 2024.',
    'Los equipos están sujetos a disponibilidad de inventario y podrán cambiar a un modelo similar disponible',
    'Cualquier duda con la promoción y para el canje de tu equipo, enviar un correo a dinguanzov@axtel.com.mx',
    'Los equipos serán enviados al domicilio que indique el colaborador al momento de solicitar el canje.',
    'El referido se asocia al primero que lo registre, para el caso de duplicados. Así que no esperes más, ¡registra a tus referidos!',
    'No olvides avisarles a tus referidos que les llamaremos para explicarle los planes y el proceso de contratación.',
    'El programa solo aplica para colaboradores activos de Axtel y subsidiarias.',
  ],
}: {
  bases?: string[];
}) {
  return (
    <div className='bg-white '>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center'>
          <h2 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
            Bases del Programa:
          </h2>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
          <ul className=''>
            {bases.map((base) => (
              <li className='flex gap-10 mt-3 mb-3'>
                <span className='text-black text-xs'>✔️</span>
                <p>{base}</p>
                <br />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
