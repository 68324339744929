import React from "react";
import PackageItemCard from "components/molecules/Products/PackageItemCard";
import useFlowInstance from "hooks/useFlowInstance";
import usePortalData from "hooks/usePortalData";
import EmptyStateCard from "components/molecules/EmptyStateCard";
import BasicCheckBox from "components/atoms/BasicCheckBox";
import { HiArrowRight } from "react-icons/hi2";
import BasicDialog from "components/atoms/BasicDialog";
import Terms from "components/atoms/Terms";
export default function CurrentOfferConfirmation({ offer }: { offer: any }) {
  const { updateFlowInstance } = useFlowInstance();
  const { setNextStep } = usePortalData();
  const [checkTerms, setCheckTerms] = React.useState<boolean>(false);
  const [acceptanceDate, setAcceptanceDate] = React.useState<string>("");

  React.useEffect(() => {
    if (checkTerms) {
      setAcceptanceDate(new Date().toISOString());
    }
  }, [checkTerms]);

  const handleSubmit = async () => {
    const request = await updateFlowInstance({
      offer: offer,
      termsAcceptedDate: acceptanceDate,
    });
    if (request.success) {
      setNextStep();
    }
  };
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  return (
    <div className="grid grid-cols-12 gap-4">
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openDialog}
        closeDialog={() => {
          setOpenDialog(false);
        }}
      >
        <Terms />
      </BasicDialog>
      {offer ? (
        <div className="col-span-12 lg:col-span-12  ">
          <PackageItemCard
            data={offer}
            index={0}
            isSelected={false}
            style={{}}
          />
        </div>
      ) : (
        <div className="col-span-12 lg:col-span-12  ">
          <EmptyStateCard message="Aún no tienes una oferta asignada" />
        </div>
      )}

      <div className="col-span-12">
        <BasicCheckBox
          value={checkTerms}
          onChange={(e: any) => {
            setCheckTerms(e.target.checked);
          }}
        >
          <p className="text-sm ml-2">
            He leído y acepto{" "}
            <a
              onClick={() => {
                setOpenDialog(true);
              }}
              className=" cursor-pointer link_text	"
            >
              <strong>Términos y Condiciones</strong>
            </a>
          </p>
        </BasicCheckBox>
      </div>
      <div className="col-span-12 flex justify-end lg:justify-center ">
        <button
          disabled={!offer || !checkTerms}
          onClick={handleSubmit}
          className=" w-full lg:w-2/4 gap-3 flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 primary-btn"
        >
          CONTINUAR <HiArrowRight />
        </button>
      </div>
    </div>
  );
}
