import React from 'react';
import { useState } from 'react';
import { notify } from 'utils/notify';
import { Switch } from '@headlessui/react';
import useLeadsServices from 'hooks/leads/useLeadsServices';
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}
export default function LeadForm() {
  const [agreed, setAgreed] = useState(false);

  const [lead, setLead] = React.useState<any>({
    type: 'referred_lead',
  });

  const { createLead } = useLeadsServices();

  const handleChange = (e: any) => {
    setLead((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const leadRequest = await createLead(lead);
    if (leadRequest) {
      setLead({
        name: '',
        employeeNumber: '',
        referrerName: '',
        phoneNumber: '',
      });
      notify('success', 'Tu referido ha sido registrado');
    }
  };
  const validateForm = () => {
    return (
      lead?.name &&
      lead?.employeeNumber &&
      lead?.referrerName &&
      lead?.phoneNumber
    );
  };

  return (
    <div
      className='isolate  mt-20 mb-20  pt-10 pb-10 p-6'
      style={{
        background: '#e5e5e7',
        color: 'white',
        borderRadius: 10,
      }}
    >
      <div
        className='absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]'
        aria-hidden='true'
      >
        <div className='relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg]  opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]' />
      </div>
      <div className='mx-auto max-w-2xl text-center'>
        <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
          <strong style={{ color: '#fe2b0c' }}>3. </strong>Registra a tu
          referido para que nos pongamos en contacto
        </h2>
        <p className='text-lg text-gray-900 m-3'>
          No olvides avisarle a tu referido
        </p>
      </div>
      <form
        action='submit'
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
        }}
        className='mx-auto max-w-xl mt-16 '
      >
        <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
          <div className='sm:col-span-2'>
            <label
              htmlFor='first-name'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Nombre del Colaborador:
            </label>
            <div className='mt-2.5'>
              <input
                type='text'
                name='referrerName'
                value={lead?.referrerName}
                onChange={handleChange}
                id='first-name'
                autoComplete='given-name'
                className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='sm:col-span-2'>
            <label
              htmlFor='last-name'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Número de Empleado:
            </label>
            <div className='mt-2.5'>
              <input
                type='text'
                name='employeeNumber'
                value={lead?.employeeNumber}
                onChange={handleChange}
                id='last-name'
                autoComplete='family-name'
                className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='sm:col-span-2'>
            <label
              htmlFor='company'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Nombre del Referido:
            </label>
            <div className='mt-2.5'>
              <input
                type='text'
                name='name'
                id='company'
                value={lead?.name}
                onChange={handleChange}
                autoComplete='organization'
                className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='sm:col-span-2'>
            <label
              htmlFor='company'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Teléfono del Referido para contactarlo por WhatsApp o llamada:
            </label>
            <div className='mt-2.5'>
              <input
                type='text'
                name='phoneNumber'
                id='company'
                value={lead?.phoneNumber}
                onChange={handleChange}
                maxLength={10}
                autoComplete='organization'
                className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
        </div>
        <div className='mt-10'>
          <button
            disabled={!validateForm()}
            type='submit'
            className='  primary-btn-red block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            REGISTRAR
          </button>
        </div>
      </form>
    </div>
  );
}
