const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_IS_LOADED":
      return {
        ...state,
        isGMLoaded: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
