import React from "react";
import { HiOutlineCreditCard } from "react-icons/hi";
import { AiOutlineBank } from "react-icons/ai";

export default function PaymentMethodSwitch({ handleChange, value }: any) {
  const types = [
    {
      name: "card",
      icon: (
        <HiOutlineCreditCard
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
      text: "Tarjeta",
    },
    {
      name: "bank_transfer",
      icon: (
        <AiOutlineBank
          style={{
            fontSize: "1.5rem",
          }}
        />
      ),
      text: "Transferencia",
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-2 mt-3 mb-3">
      {types?.map((item: any) => (
        <div className="col-span-6 lg:col-span-6">
          <button
            className={`w-full flex items-center justify-center bg-cyan-50 py-2 px-4 rounded  ${
              value === item?.name ? "primary-btn" : "secondary-btn"
            }`}
            onClick={() => {
              handleChange(item.name);
            }}
            style={{
              backgroundColor: value === item?.name ? "#90e2ed" : "",
            }}
          >
            {item.icon} <p>{item.text}</p>
          </button>
        </div>
      ))}
    </div>
  );
}
