import React, { useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function BasicDialog({
  children,
  isOpen,
  closeDialog,
  style = {},
}: {
  children: any;
  isOpen: boolean;
  closeDialog: any;
  style: any;
}) {
  const myComponentRef: any = useRef(null);
  React.useEffect(() => {
    if (myComponentRef.current) {
      myComponentRef.current.scrollTop = 0;
    }
  }, []);
  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 " onClose={closeDialog}>
        <div className="min-h-screen flex items-center justify-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="bg-white mt-6 p-4 rounded w-5/6 lg:w-2/6 overflow-scroll	shadow-lg"
              style={style}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
