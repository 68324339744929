import usePortalData from "hooks/usePortalData";
import React from "react";
import { privateAxiosFieldService } from "utils/axios";
import { notify } from "utils/notify";
import { urls } from "utils/urls";

const useFieldService = () => {
  const { setGlobalLoading } = usePortalData();
  async function getAvailableSlots(payload: any) {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosFieldService.post(
        urls.API_FS_getAvailableSlots,
        payload
      );
      return response;
    } catch (error) {
    } finally {
      setGlobalLoading(false);
    }
  }

  async function reserveSlot(payload: any) {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosFieldService.post(
        urls.API_FS_reserveSlot,
        payload
      );
      return response;
    } catch (error: any) {
      if (error.response.data.Error === "Service time window not found.") {
        notify("error", "Este horario ya no se encuentra disponible");
      }
    } finally {
      setGlobalLoading(false);
    }
  }
  return { getAvailableSlots, reserveSlot };
};

export default useFieldService;
