import React from "react";
import CompleteScreenDialog from "components/atoms/CompleteScreenDialog";
import { HiOutlineXMark } from "react-icons/hi2";

export default function CardHelpDialog({
  isOpen,
  closeDialog,
}: {
  isOpen: boolean;
  closeDialog: () => void;
}) {
  return (
    <CompleteScreenDialog isOpen={isOpen} closeDialog={closeDialog}>
      <div className="grid grid-cols-12 gap-6 lg:pl-5 lg:pr-5  ">
        <div className="col-span-12 lg:col-span-12 flex justify-center pt-16 text-right">
          <button type="button" className="ml-auto" onClick={closeDialog}>
            <HiOutlineXMark className="text-2xl" />
          </button>
        </div>
        <div className="col-span-12 lg:col-span-12 flex justify-center">
          <p className="font-semibold text-xl text-center w-full sm:w-1/3">
            El código de seguridad de tu tarjeta (CVC) es el número de 3 o 4
            dígitos que se encuentra en el frente o reverso de la mayoría de las
            tarjetas.
          </p>
        </div>
        <div className="col-span-12 lg:col-span-12 flex justify-center">
          <img
            src="https://stanitroprodscus001.blob.core.windows.net/sales-files/card1.png"
            width={"300px"}
            alt="card1"
          />
        </div>
        <div className="col-span-12 lg:col-span-12 flex justify-center">
          <img
            src="https://stanitroprodscus001.blob.core.windows.net/sales-files/card4.png"
            width={"300px"}
            alt="card2"
          />
        </div>
      </div>
    </CompleteScreenDialog>
  );
}
