import React from "react";
import BasicCard from "components/atoms/BasicCard";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import useFlowInstance from "hooks/useFlowInstance";
import QRCodeGenerator from "components/atoms/QrCodeGenerator";
export default function SuccessPage({
  title,
  paragraph,
  actionText,
  callToAction,
}: {
  title: string;
  paragraph: string;
  actionText: string;
  callToAction: boolean;
}) {
  const { getFlowInstance, instanceData } = useFlowInstance();
  React.useEffect(() => {
    getFlowInstance();
    sessionStorage.clear();
  }, []);

  return (
    <div className="flex mt-10 justify-center h-screen p-4 lg:p-10">
      <div className="w-full sm:w-1/4">
        <BasicCard>
          <h3 className="text-2xl font-bold primary_text">{title} </h3>
          <br />
          <p>{paragraph}</p>
          <br />
          <br />
          <p className="font-medium">{actionText}</p>
          <br />
          {callToAction && (
            <div>
              <div className="hidden md:block lg:block xl:block">
                <QRCodeGenerator
                  urlCode={`https://wa.me/18709003692?text=Registro%20cliente%20nuevo:%20${instanceData?.prospect?.email}`}
                />
              </div>

              <a
                className="sm:block md:hidden lg:hidden xl:hidden"
                href={`https://wa.me/18709003692?text=Registro%20cliente%20nuevo:%20${instanceData?.prospect?.email}`}
                target="_blank"
              >
                <button className=" w-full fleXx items-center justify-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  Suscribir a Whatsapp
                </button>
              </a>
            </div>
          )}
        </BasicCard>
      </div>
    </div>
  );
}
