import React from "react";
import { getAddressFromLatLng } from "utils/geocoding";
import useGoogleMapsContext from "hooks/useGoogleMapsContext";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

interface DraggableMapProps {
  initialPosition: google.maps.LatLngLiteral;
  onMarkerDragEnd: (position: google.maps.LatLngLiteral) => void;
  style?: any;
  isDraggable?: boolean;
}

const GoogleMapComponent: any = ({
  initialPosition,
  isDraggable = false,
  zoom = 12,
  style = { width: "100%", height: "30vh" },
  onMarkerDragEnd,
}: any) => {
  const { isGMLoaded } = useGoogleMapsContext();

  const handleMarkerDragEnd = React.useCallback(
    async (event: google.maps.MapMouseEvent) => {
      let newPosition: any = {
        lat: event.latLng?.lat(),
        lng: event.latLng?.lng(),
      };
      const address: any = await getAddressFromLatLng(newPosition);
      newPosition.address = address;

      newPosition = {
        ...newPosition,
        street: address?.street,
        extNumber: address?.street_number,
        suburb: address?.suburb,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        zipCode: address?.zipCode,
      };

      onMarkerDragEnd(newPosition);
    },
    [onMarkerDragEnd]
  );
  return isGMLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={style}
        center={initialPosition}
        zoom={zoom}
        onClick={handleMarkerDragEnd}
      >
        <Marker
          draggable={isDraggable}
          onDragEnd={handleMarkerDragEnd}
          position={initialPosition}
        />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default GoogleMapComponent;
