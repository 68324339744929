import React from "react";
import CustomerValidationCard from "components/molecules/Checkout/CustomerValidationCard";
import ProductValidationCard from "components/molecules/Checkout/ProductValidationCard";
import InstallationValidation from "components/molecules/Checkout/InstallationValidation";
import useFlowInstance from "hooks/useFlowInstance";
import usePaynet from "hooks/usePaynet";
import { Instance } from "../Checkout/types/types";
import useReferralCodeServices from "hooks/referralCodes/useReferralCodeServices";
import ReferralCodeValidationCard from "components/molecules/Checkout/ReferralCodeValidationCard";
import { isEmpty } from "lodash";
import usePortalData from "hooks/usePortalData";
import BasicDialog from 'components/atoms/BasicDialog';
import BasicCheckBox from "components/atoms/BasicCheckBox";
import Terms from 'components/atoms/Terms';
import UserRights from 'components/atoms/UserRights';

//Iconst
export default function InstanceValidationCustomer({
  instance,
}: {
  instance: Instance;
}) {
  const { createFlowInstanceCheckoutHiringUrl, updateFlowInstance } =
    useFlowInstance();

  const [paymentMethod, setPaymentMethod] = React.useState<any>({});
  const [referralCodeData, setReferralCodeData] = React.useState<any>({});
  const [referralCode, setReferralCode] = React.useState<any>("");
  const [validReferralCode, setValidReferralCode] =
    React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openRightsDialog, setOpenRightsDialog] =
    React.useState<boolean>(false);
  const [checkTerms, setCheckTerms] = React.useState<boolean>(false);
  const [checkUserRights, setCheckUserRights] = React.useState<boolean>(false);
  const [acceptanceDate, setAcceptanceDate] = React.useState<string>("");

  const { getPaymentMethod } = usePaynet();
  const { getReferralCode } = useReferralCodeServices();
  const { setNextStep, setGlobalLoading } = usePortalData();

  const onHandleReferralCodeData = async (code: string) => {
    const responde = await onFetchReferralCodeData(code);
    if (!isEmpty(responde)) {
      const updatedFlowInstance = await onFetchSetReferralCodeData(code);
      if (updatedFlowInstance.success) {
        sessionStorage.setItem("referralCode", code);
      }
    }
  };

  const onFetchSetReferralCodeData = async (code: string) => {
    const response = await updateFlowInstance(
      {
        referralCode: code,
      },
      true
    );
    return response;
  };

  const onFetchReferralCodeData = async (code: string) => {
    setValidReferralCode(false);
    setReferralCodeData({});
    const referralCodeData = await getReferralCode({
      query: code,
      fields: "type,value,customerId,customer,referralCode",
    });
    if (!isEmpty(referralCodeData)) {
      setReferralCodeData(referralCodeData);
      setValidReferralCode(true);
      return referralCodeData;
    }
    return setValidReferralCode(false);
  };

  React.useEffect(() => {
    async function getData() {
      if (instance?.payment?.paymentMethod) {
        const pm = await getPaymentMethod(instance.payment.paymentMethod);
        setPaymentMethod(pm);
      }
      if (instance?.referralCode) {
        setReferralCode(instance?.referralCode);
        await onFetchReferralCodeData(instance?.referralCode);
      }
    }

    getData();
  }, [instance]);

  const handleSubmit = async () => {
    setGlobalLoading(true);
    const payload: any = {
      termsAcceptedDate: acceptanceDate,
    };

    const request = await updateFlowInstance(payload);
    if (request.success) {
      setNextStep();
    }
    setGlobalLoading(false);
  };

  React.useEffect(() => {
    if (checkTerms) {
      setAcceptanceDate(new Date().toISOString());
    }
  }, [checkTerms]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openDialog}
        closeDialog={() => {
          setOpenDialog(false);
        }}
      >
        <Terms />
      </BasicDialog>
      <BasicDialog
        style={{ height: 500 }}
        isOpen={openRightsDialog}
        closeDialog={() => {
          setOpenRightsDialog(false);
        }}
      >
        <UserRights />
      </BasicDialog>
      <div className="col-span-12 lg:col-span-12">
        <ProductValidationCard
          offer={instance?.offer}
          paymentMethodType={instance?.payment?.paymentMethodType}
        />
      </div>
      <div className="col-span-12 lg:col-span-12">
        <ReferralCodeValidationCard
          referralCode={referralCode}
          setReferralCode={setReferralCode}
          validReferralCode={validReferralCode}
          data={referralCodeData}
          onHandleValidate={onHandleReferralCodeData}
        />
      </div>
      <div className="col-span-12 lg:col-span-12 ">
        <InstallationValidation data={instance?.installation} />
      </div>
      <div className="col-span-12 lg:col-span-12 ">
        <CustomerValidationCard
          data={instance?.prospect}
          location={instance?.location}
        />
      </div>
      <div className="col-span-12 flex justify-center">
        <BasicCheckBox
          value={checkTerms}
          onChange={(e: any) => {
            setCheckTerms(e.target.checked);
          }}
        >
          <p className="text-sm ml-2">
            He leído y acepto{" "}
            <a
              onClick={() => {
                setOpenDialog(true);
              }}
              className="cursor-pointer link_text	"
            >
              <strong>Términos y Condiciones</strong>
            </a>
          </p>
        </BasicCheckBox>
      </div>
      <div className="col-span-12 flex justify-center">
        <BasicCheckBox
          value={checkUserRights}
          onChange={(e: any) => {
            setCheckUserRights(e.target.checked);
          }}
        >
          <p className="text-sm ml-2">
            He leído la{" "}
            <a
              onClick={() => {
                setOpenRightsDialog(true);
              }}
              className="cursor-pointer link_text"
            >
              <strong>Carta de derechos a usuarios</strong>
            </a>
          </p>
        </BasicCheckBox>
      </div>
      <div className="col-span-12 flex justify-end lg:justify-center">
        <button
          disabled={!checkTerms || !checkUserRights}
          onClick={() => {
            handleSubmit();
          }}
          className='w-full lg:w-2/4 flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 primary-btn'
        >
          CONTINUAR
        </button>
      </div>
    </div>
  );
}
