const reducer = (globalState: any, action: any) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...globalState,
        products: action.payload,
      };

    case "SET_SELECTED_PRODUCTS":
      return {
        ...globalState,
        selectedProducts: action.payload,
      };

    case "SET_CURRENT_OFFER":
      return {
        ...globalState,
        currentOffer: action.payload,
      };

    case "SET_SELECTED_OFFER":
      return {
        ...globalState,
        selectedOffer: action.payload,
      };

    case "SET_CURRENT_ACCESS_POINTS":
      return {
        ...globalState,
        currentAccessPoints: action.payload,
      };

    default:
      return globalState;
  }
};
export default reducer;
