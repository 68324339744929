import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import usePortalData from "hooks/usePortalData";

const LoadingBackdrop = ({ open = true }: { open: boolean }) => {
  const { setGlobalLoading, isGlobalLoading } = usePortalData();
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: "100" }} open={isGlobalLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoadingBackdrop;
