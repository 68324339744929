import React from "react";
import usePortalData from "hooks/usePortalData";
import { getSessionItem } from "utils/session";
export default function Stepper() {
  const { flowData } = usePortalData();

  return (
    <div className="flex">
      {flowData?.pathOrder?.map((item: any, index: number) => {
        const isActive: boolean =
          index <= parseInt(getSessionItem("currentStep") || "0");

        return (
          <div
            key={item}
            className={`bg-gray-400 h-3 w-3 m-1 rounded-xl background_animation_brand ${
              index === parseInt(getSessionItem("currentStep") || "0")
                ? "animate-bounce "
                : ""
            }`}
            style={{
              background: isActive ? "" : "#F1F1F1",
            }}
          ></div>
        );
      })}
    </div>
  );
}
