import React from 'react'
import BasicField from 'components/atoms/BasicField'

export default function AddressHousingSocietyForm({ address, onChange }: any) {
  return (
    <>
      <div className='col-span-12 lg:col-span-6'>
        <BasicField
          label='Calle'
          name='street'
          value={address?.street}
          onChange={onChange}
          required={true}
        />
      </div>
      <div className='col-span-12 lg:col-span-6'>
        <BasicField
          label='No. Exterior'
          name='extNumber'
          required={true}
          value={address?.extNumber}
          onChange={onChange}
        />
      </div>
    </>
  )
}
