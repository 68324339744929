export const serviceAdvantages = [
  {
    strong: "WiFi Diseñado a tus Necesidades: ",
    text: "Personalizamos la cobertura de WiFi para cubrir las áreas que más importan en tu hogar, para que no vuelvas a preocuparte.",
  },
  {
    strong: "Atención Ágil y Proactiva: ",
    text: "Comunicación rápida y sencilla a través de WhatsApp. Además, tu red WiFi es monitoreada para detectar proactivamente cualquier problema y resolverlo.",
  },
  {
    strong: "Internet de Alto Desempeño: ",
    text: "100% a través de fibra óptica y con alta velocidad para subir y bajar información.",
  },
];

export const pageTitle =
  "Te confirmamos disponibilidad del servicio en tu domicilio";

export const pageSubtitle = "Ventajas únicas de nuestro servicio de internet";

export const pageActionDescription = {
  strong: "Escoge el que mejor se adecúe:",
  text: "Nuestros planes fueron creados para adaptarse a tu estilo de vida y necesidades de tu hogar.",
};
