import React from 'react'
import BasicCard from 'components/atoms/BasicCard'

export default function OfferSummary({
  offer,
  paymentMethodType
}: {
  offer: any
  paymentMethodType: string
}) {
  const [price, setPrice] = React.useState<any>('')

  React.useEffect(() => {
    const pricesData =
      offer?.stripeExpandOptions?.products[0]?.extraPriceData || null
    if (pricesData) {
      let pmPrice = pricesData.find(
        (item: any) => item.billingType === paymentMethodType
      )

      let amount = pmPrice?.tiers[0]?.unitAmount || 0

      setPrice(amount)
      return
    }

    setPrice(offer?.stripeExpandOptions?.prices?.grossAmount || 0)
  }, [paymentMethodType])

  return (
    <div className='grid grid-cols-12 gap-2 mt-3 mb-3'>
      <div className='col-span-12 lg:col-span-12'>
        <BasicCard>
          <span className='text-xs font-semibold'>Plan seleccionado</span>
          <div className=' flex align-center'>
            <div>
              <h3 className='text-xl'> {offer?.name}</h3>
            </div>
            <div className='ml-auto text-right'>
              <h3 className='text-2xl font-semibold '>
                {' '}
                $ {price || offer?.stripeExpandOptions?.prices?.grossAmount}
              </h3>
              <p>IVA incluido</p>
              {paymentMethodType === 'card' && (
                <p className='text-xs text-right'>Pagando con tarjeta</p>
              )}
              {paymentMethodType === 'bank_transfer' && (
                <p className='text-xs text-right'>
                  Pagando con transferencia bancaria
                </p>
              )}
            </div>
          </div>
        </BasicCard>
      </div>
    </div>
  )
}
