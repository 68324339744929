import React, { useState, useEffect } from 'react'
import useLeadsServices from 'hooks/leads/useLeadsServices'
import AutocompletePlaces from 'components/atoms/AutoCompletePlaces'
import useGoogleMaps from 'hooks/useGoogleMaps'
import useNetvault from 'hooks/useNetvault'
import BasicCheckBox from 'components/atoms/BasicCheckBox'
import marcianito from 'assets/images/marcianito.png'
import { FaWhatsapp } from 'react-icons/fa'

interface ZFAdvLeadType {
  utmPValObj: Record<string, string>
  utmcustPNameArr: string[]
  isSameDomian: boolean
  zfautm_ini: () => void
  // Add other methods or properties you might use
}

declare global {
  interface Window {
    ZFAdvLead: ZFAdvLeadType
  }
}

export default function LeadFormV2() {
  const { getCoverage } = useNetvault()
  const [checkPrivacy, setCheckPrivacy] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  useGoogleMaps()
  const [lead, setLead] = React.useState<any>({
    type: 'campaign_lead',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  })
  const { createLead } = useLeadsServices()

  // Validate if the user has coverage
  const validateCoverage = async () => {
    const coverage = await getCoverage(lead?.location?.lat, lead?.location?.lng)
    return coverage?.hasCoverage && coverage?.hasCapacity
  }

  useEffect(() => {
    // Handle messages received from the parent
    function handleMessage(event: any) {
      // Check the origin of the message for security
      if (event.origin !== process.env.REACT_APP_LANDING_URL) {
        // replace with your parent's origin
        return
      }

      // The URL of the parent frame is in event.data.url
      const parentUrl = new URL(event.data.url)
      const params = new URLSearchParams(parentUrl.search)

      setLead((prev: any) => ({
        ...prev,
        utm_source: params.get('utm_source') || '',
        utm_medium: params.get('utm_medium') || '',
        utm_campaign: params.get('utm_campaign') || '',
        utm_term: params.get('utm_term') || '',
        utm_content: params.get('utm_content') || ''
      }))
    }

    // Listen for messages from the parent frame

    window.addEventListener('message', handleMessage)
    const script = document.createElement('script')
    script.src =
      'https://stanitroprodscus001.blob.core.windows.net/sales-files/hotjar_sales.js'
    script.async = true
    document.head.appendChild(script)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage)
      document.head.removeChild(script)
    }
  }, [])

  const handleChange = (e: any) => {
    if (e.target.name === 'phoneNumber') {
      e.target.value = e.target.value.replace(/[^\d]/g, '')
    }

    if (e.target.name === 'name') {
      //necesito que si escribe numeros no los acepte

      e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')
    }

    setLead((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = async () => {
    // Validate if the lead has coverage
    setIsSubmiting(true)
    const isValidCoverage = await validateCoverage()

    //create lead
    lead.hasCoverage = isValidCoverage
    const leadRequest = await createLead(lead)
    if (leadRequest) {
      // setLead({
      //   type: 'lead',
      //   name: '',
      //   email: '',
      //   phoneNumber: '',
      //   zipCode: ''
      // })
      if (isValidCoverage) {
        // redirect to parent coverage page
        //setIsSubmiting(false)
        if (window.top) {
          window.top.location.href =
            process.env.REACT_APP_LANDING_URL +
            '/confirmacion-cobertura?leadId=' +
            leadRequest.leadId
        }
      } else {
        if (window.top) {
          window.top.location.href =
            process.env.REACT_APP_LANDING_URL +
            '/sin-cobertura?leadId=' +
            leadRequest.leadId
        }
      }
    }
  }
  const validateForm = () => {
    // eslint-disable-next-line no-useless-escape
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    return (
      lead?.name?.length > 0 &&
      //validar que email sean validos
      lead?.email?.length > 0 &&
      lead?.email.match(mailformat) &&
      lead?.phoneNumber?.length === 10 &&
      lead?.location?.lat &&
      lead?.location?.lng &&
      checkPrivacy
    )
  }
  return (
    <div className='isolate mt-1 mb-1  pt-[15px] pb-[15px] px-[32px]'>
      <div
        className='absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]'
        aria-hidden='true'
      >
        <div className='relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg]  opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]' />
      </div>
      <form
        action='submit'
        autoComplete='off'
        autoCorrect='off'
        onSubmit={(e: any) => {
          e.preventDefault()
          handleSubmit()
        }}
        className='mx-auto max-w-xl mt-2 '
      >
        <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
          {/* <div className='sm:col-span-2'>
            <div className='grid grid-cols-12 col-span-1'>
              <div className='col-start-12 col-span-2'>
                <img src={marcianito} alt='marcianito' className='w-full' />
              </div>
            </div>
          </div> */}
          <div className='sm:col-span-2'>
            <div className='grid-cols-8 grid gap-2'>
              <div className='col-span-6'>
                <h2
                  className='text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-3xl'
                  style={{
                    color: '#ec3112'
                  }}
                >
                  Verifica la cobertura en tu zona
                </h2>
              </div>
              <div className='col-span-2'>
                <img src={marcianito} alt='marcianito' className='w-full' />
              </div>
            </div>
          </div>
          <div className='sm:col-span-2'>
            <label
              htmlFor='first-name'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Nombre:
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='name'
                value={lead?.name}
                onChange={handleChange}
                id='first-name'
                className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              />
            </div>
          </div>
          <div className='sm:col-span-1'>
            <label
              htmlFor='last-name'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Correo electrónico:
            </label>
            <div className='mt-1'>
              <div className='relative'>
                <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
                  <svg
                    className='w-4 h-4 text-gray-500 dark:text-gray-400'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 20 16'
                  >
                    <path d='m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z' />
                    <path d='M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z' />
                  </svg>
                </div>
                <input
                  type='email'
                  name='email'
                  value={lead?.email}
                  onChange={handleChange}
                  id='email'
                  autoComplete='new-password'
                  className=' border border-gray-300 text-gray-900 text-sm rounded-md  focus:ring-2 focus:ring-inset focus:ring-indigo-600 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='example@hello.com'
                />
              </div>
            </div>
          </div>
          <div className='sm:col-span-1'>
            <label
              htmlFor='company'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Número de WhatsApp:
            </label>
            <div className='mt-1'>
              <div className='relative'>
                <div className='absolute inset-y-0 start-0 flex items-center  pointer-events-none m-3'>
                  <FaWhatsapp className=' text-green-600' />
                  <span className='text-sm font-semibold ml-[2px]'>+52</span>
                </div>
                <input
                  type='phone'
                  name='phoneNumber'
                  id='company'
                  value={lead?.phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  autoComplete='new-password'
                  className='border border-gray-300 text-gray-900 text-sm rounded-md  focus:ring-2 focus:ring-inset focus:ring-indigo-600 block w-full ps-[60px] p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  placeholder='8112345678'
                />
              </div>
            </div>
          </div>
          <div className='sm:col-span-2'>
            <label
              htmlFor='company'
              className='block text-sm font-semibold leading-6 text-gray-900'
            >
              Dirección donde requieres el servicio:
            </label>
            <div className='mt-1'>
              <AutocompletePlaces
                onSelectPlace={(value) => {
                  setLead((prev: any) => ({
                    ...prev,
                    location: {
                      lat: value?.geometry?.location?.lat(),
                      lng: value?.geometry?.location?.lng(),
                      street: value?.address.street,
                      extNumber: value?.address.street_number,
                      suburb: value?.address.suburb,
                      zipCode: value?.address.zipcode,
                      city: value?.address.city,
                      state: value?.address.state,
                      country: value?.address.country
                    }
                  }))
                }}
                currentAddress={''}
              />

              <div className='bg-blue-50 rounded-md mt-3 p-3'>
                <span className='text-blue-900 text-xs'>
                  captura tu dirección y la función de autocompletar te dará
                  opciones, asegúrate de seleccionar alguna de ellas para
                  ubicarte
                </span>
              </div>
            </div>
          </div>
          <div className='sm:col-span-2'>
            <BasicCheckBox
              value={checkPrivacy}
              onChange={(e: any) => {
                setCheckPrivacy(e.target.checked)
              }}
            >
              <p className='text-sm'>
                Al enviar este formulario, acepto el{' '}
                <strong>
                  {' '}
                  <a
                    href='https://stanitroprodscus001.blob.core.windows.net/sales-files/Aviso_de_Privacidad.pdf'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Aviso de privacidad
                  </a>
                </strong>
              </p>
            </BasicCheckBox>
          </div>
        </div>
        <div className='mt-2'>
          <button
            disabled={!validateForm() || isSubmiting}
            type='submit'
            //disabled
            // className='primary-btn-red block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-50'
            className={`${
              validateForm() ? 'primary-btn-red' : 'bg-gray-400'
            } primary-btn-redblock w-full rounded-md  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 flex items-center justify-center`}
          >
            {isSubmiting && (
              <svg
                className='mr-3 h-5 w-5 animate-spin text-white'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  stroke-width='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            )}
            Verificar Cobertura
          </button>
        </div>
      </form>
    </div>
  )
}
