import React from "react";
import { IoHomeOutline } from "react-icons/io5";
import { IoBusinessOutline } from "react-icons/io5";

export default function OffersModule() {
  const offers = [
    {
      id: 2,
      title: "PLANES RESIDENCIALES",
      icon: <IoHomeOutline className="text-xl" />,
      pdfUrl:
        "https://stanitroprodscus001.blob.core.windows.net/sales-files/XtremoResidencialPlanes.pdf",
    },
    {
      id: 1,
      title: "PLANES PARA NEGOCIO",
      icon: <IoBusinessOutline className="text-xl" />,
      pdfUrl:
        "https://stanitroprodscus001.blob.core.windows.net/sales-files/XtremoNegociosPlanesv2.pdf",
    },
  ];
  return (
    <div className="bg-white bg-blue-500 pt-10 pb-20">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            <strong style={{ color: "#fe2b0c" }}>2. </strong>Comparte con ellos
            la información de los planes
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {offers.map((offer) => (
              <div key={offer.id} className="relative cursor-pointer ">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(offer?.pdfUrl, "_blank", "noopener,noreferrer");
                  }}
                  className=" flex items-center justify-center gap-4 primary-btn block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {offer.title} {offer.icon}
                </a>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
