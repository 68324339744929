import React from "react";
import usePortalData from "hooks/usePortalData";
import { getSessionItem, setSessionItem } from "utils/session";
import useFlowData from "hooks/useFlowData";
export default function FlowSwitchComponent({ handleStart }: any) {
  const { flowData, setFlowData } = usePortalData();
  const { getData } = useFlowData(getSessionItem("flowId") || "");
  const [disable, setDisable] = React.useState<boolean>(false);

  const handleChangeFlow = async (flowId: string) => {
    setDisable(true);
    setSessionItem("flowId", flowId);
    const flow = await getData(getSessionItem("flowId"));
    if (flow) {
      handleStart(flow);
      setDisable(false);
    }
  };

  return (
    <div className="w-full  ">
      <br />
      <div className="w-full">
        <p className="text-sm text-center  text-white">
          ¿Qué tipo de servicio estás buscando?
        </p>
      </div>
      <br />
      <div className="w-full flex justify-center gap-4">
        {flowData?.flows?.map((flow: any, index: number) => (
          <div
            key={index}
            className="w-1/2"
            onClick={() => {
              handleChangeFlow(flow?.flowId);
            }}
          >
            <button
              disabled={disable}
              className=" w-full flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded primary-btn disabled:opacity-50"
            >
              {flow?.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
