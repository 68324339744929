import React from 'react';
import BasicDialog from 'components/atoms/BasicDialog';
import SupportDialogBody from './SupportDialogBody';

export default function AskForSupportDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) {
  return (
    <BasicDialog
      style={{ height: 'fit-content' }}
      isOpen={open}
      closeDialog={() => {
        setOpen(false);
      }}
    >
      <SupportDialogBody />
    </BasicDialog>
  );
}
