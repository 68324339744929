import React from "react";

export default function BasicCard({ children, style, twStyles = "" }: any) {
  return (
    <div
      className={
        `w-full p-5 border backdrop-blur-xl border-gray-200 bg-white rounded-lg rounded shadow-sm h-fit basic-card ` +
        " " +
        twStyles
      }
      style={style}
    >
      {children}
    </div>
  );
}
