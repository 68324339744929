import React, { useRef } from "react";
import { privateAxiosSales } from "utils/axios";
import { urls } from "utils/urls";
import { useNavigate } from "react-router-dom";
import usePortalData from "hooks/usePortalData";
import { getSessionItem, removeSessionItem } from "utils/session";
import { notify } from "utils/notify";
const useReferralCodeServices = () => {
  const navigate = useNavigate();
  const { setGlobalLoading, flowData } = usePortalData();

  async function getReferralCode(payload: any) {
    setGlobalLoading(true);
    try {
      const { data: response } = await privateAxiosSales.get(
        urls.API_SALES_getReferralCode,
        {
          params: payload,
        }
      );

      return response;
    } catch (error) {
      console.error(error);
      removeSessionItem("referralCode");
      notify("error", "No se pudo obtener el código de referido");
    } finally {
      setGlobalLoading(false);
    }
  }
  return {
    getReferralCode,
  };
};

export default useReferralCodeServices;
