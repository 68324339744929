import React from "react";
import BasicCard from "components/atoms/BasicCard";
import { HiOutlineInbox } from "react-icons/hi2";

export default function EmptyStateCard({ message }: { message: string }) {
  return (
    <BasicCard>
      <div className="text-center">
        <br />
        <div className="flex justify-center">
          <HiOutlineInbox className="text-2xl" />
        </div>
        <br />
        <h5 className="text-sm">{message}</h5>
        <br />
      </div>
    </BasicCard>
  );
}
