import React from "react";
import MessageComponent from "./components/MessageComponent";
export default function SuccessLeadRegister() {
  return (
    <>
      <MessageComponent
        title="Te has registrado exitosamente"
        description="Pronto recibirás noticias de nuestra parte"
      />
    </>
  );
}
