import React from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/ui/select'
import ErrorMessageComponent from './ErrorMessageComponent'
export default function BasicSelect({
  label,
  name,
  required = false,
  onChange,
  options,
  value
}: any) {
  const handleChange = (event: any) => {
    if (event !== value) {
      onChange({ [name]: event })
    }
  }
  return (
    <div className='w-full mb-2'>
      <label className='block text-sm text-gray-600 font-medium text-left'>
        {label} {required && <span className='text-red-500'>*</span>}{' '}
      </label>
      <Select onValueChange={handleChange} value={value}>
        <SelectTrigger className='w-full h-[46px] '>
          <SelectValue placeholder='' />
        </SelectTrigger>
        <SelectContent>
          {options.map((option: any) => (
            <SelectItem key={option.value} value={option.value}>
              {option.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <ErrorMessageComponent
        message='El campo es requerido '
        condition={required && !Boolean(value)}
      />
    </div>
  )
}
