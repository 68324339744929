import React from "react";
import { privateAxiosSales } from "utils/axios";
import usePortalData from "./usePortalData";
import { useLocation } from "react-router-dom";
import { setSessionItem } from "utils/session";

const usePortalRoutes = () => {
  const { flowData } = usePortalData();
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (flowData?.pathOrder) {
      setCurrentRoute(location.pathname);
    }
  }, [location, flowData]);

  const setCurrentRoute = async (route: string) => {
    const baseRoute = route.split("/")[1];
    setSessionItem(
      "currentStep",
      JSON.stringify(
        flowData?.pathOrder.findIndex((item: string) => item === baseRoute) || 0
      )
    );
  };
  return { setCurrentRoute };
};

export default usePortalRoutes;
