import React from 'react';
import LeadFormV2 from './LeadFormV2';
import SupportDialogBody from 'components/molecules/SupportDialogBody';

export default function LandingPageSupport() {
  return (
    <>
      <div className='flex justify-center p-10'></div>
      <div className='flex justify-center p-10'>
        <div className='w-4/5 h-full align-center'>
          <SupportDialogBody />
        </div>
      </div>
    </>
  );
}
