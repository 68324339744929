export const userRightsHtmlContent = `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN"
"http://www.w3.org/TR/html4/loose.dtd">
<html><head><title></title>
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
</head>
<body>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p><b>Derecho de Usuarios <br/></b></p>
<p>En AXE REDES estamos comprometidos contigo y en apego a lo establecido en la <br/>regulaci&#243;n aplicable hacemos p&#250;blica la &#8220;Carta de Derechos M&#237;nimos de los Usuarios&quot;, <br/>para servicios de telecomunicaciones prestados al amparo de un Contrato de <br/>Adhesi&#243;n. <br/></p>
<p> <br/>CARTA DE DERECHOS M&#205;NIMOS DE LOS USUARIOS DE LOS SERVICIOS <br/>P&#218;BLICOS DE TELECOMUNICACIONES. <br/></p>
<p>I. LIBERTAD DE ELEGIR <br/></p>
<p>Tienes derecho a elegir: <br/></p>
<p>1. El servicio, el proveedor y el equipo o dispositivo que desees contratar. <br/>2. Paquete, plan o tarifa y forma de pago en prepago o pospago y en este &#250;ltimo, <br/></p>
<p>decidir si tu consumo ser&#225; libre o controlado. <br/>3. El o los servicios que desees contratar, sin que el proveedor te condicione o te <br/></p>
<p>obligue a contratar servicios o productos adicionales al principal. Para ello, el <br/>proveedor debe obtener tu consentimiento expreso. <br/></p>
<p>4. No recibir llamadas publicitarias de tu proveedor, a menos de que exista tu <br/>consentimiento expreso. <br/></p>
<p>5. El sistema de atenci&#243;n con los que cuenta el proveedor, para presentar tu queja, <br/>reportar anomal&#237;as del servicio, formular consultas, cancelar los servicios o <br/>cualquier tr&#225;mite relacionado con estos. <br/></p>
<p>II. ACCESO A LA INFORMACI&#211;N <br/></p>
<p>Tienes derecho a que te informen a trav&#233;s de medios f&#237;sicos o electr&#243;nicos o digitales o <br/>de cualquier otra tecnolog&#237;a que lo permita: <br/></p>
<p>6. Las tarifas, planes y/o paquetes de los servicios, sus caracter&#237;sticas y su n&#250;mero <br/>de registro ante el IFT, en un formato simplificado, as&#237; como cualquier otro cargo <br/>que se pueda efectuar por la prestaci&#243;n de los servicios. <br/></p>
<p>7. El monto total a pagar por el servicio o producto que te ofrezcan, de manera <br/>notoria y visible. <br/></p>
<p>Dicho monto deber&#225; incluir impuestos, comisiones, intereses, seguros y cualquier otro <br/>costo, cargo, gasto o erogaci&#243;n adicional que se requiera cubrir con motivo de la <br/>adquisici&#243;n o contrataci&#243;n respectiva, sea esta al contado o a cr&#233;dito. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>8. Los requisitos y procedimientos de contrataci&#243;n de los servicios, as&#237; como los <br/>t&#233;rminos y condiciones del contrato tales como: penalidades, calidad, tarifa, <br/>opciones de pago, cancelaci&#243;n anticipada y pol&#237;ticas de uso, para la utilizaci&#243;n <br/>de los servicios y la cobertura donde se presta el servicio antes de firmarlo. <br/></p>
<p>La pol&#237;tica de uso justo establece los criterios, condiciones, pol&#237;ticas, reglas, l&#237;mites y <br/>restricciones que se aplicar&#225;n al uso de los servicios. <br/></p>
<p>9. Tus derechos como usuario de servicios de telecomunicaciones y a que te <br/>entreguen esta Carta. <br/></p>
<p>10. Las velocidades m&#237;nimas de Internet garantizadas. <br/>11. Tu saldo y su vigencia de manera gratuita, as&#237; como las promociones y <br/></p>
<p>condiciones de uso. <br/>12. Los medios para efectuar recargas de saldo, y una vez realizadas, el monto, <br/></p>
<p>fecha de expiraci&#243;n y la forma para consultar el saldo, as&#237; como la oferta <br/>comercial activada o beneficios incluidos. <br/></p>
<p>13. Las opciones de los planes o paquetes de consumo controlado y libre, al <br/>contratar un servicio en pospago. <br/></p>
<p>14. La notificaci&#243;n oportuna de que est&#225;s pr&#243;ximo a consumir lo incluido en tu plan o <br/>paquete, as&#237; como el costo por cargos adicionales en el caso de excederte en tu <br/>consumo contratado; ello cuando se trate de servicios de pospago abierto. <br/></p>
<p>15. Si el equipo que adquieres est&#225; bloqueado y la forma de desbloquearlo en los <br/>servicios de telefon&#237;a. <br/></p>
<p>16. Si requieres de un equipo en particular para acceder al servicio, o si ya cuentas <br/>con uno, si tiene las caracter&#237;sticas m&#237;nimas necesarias para recibirlo. En caso <br/>de que no cuente con dichas caracter&#237;sticas debe informar por escrito tal <br/>situaci&#243;n. <br/></p>
<p>17. La ubicaci&#243;n de los centros de atenci&#243;n del proveedor y los medios para <br/>presentar quejas. <br/></p>
<p>18. Tu consumo exacto con fecha y hora de corte de manera gratuita cuando as&#237; lo <br/>solicites. <br/></p>
<p>19. En caso de que adquieras un equipo de forma financiada, el costo total, la <br/>mensualidad, el detalle de cualquier cargo adicional, intereses, n&#250;mero de pagos <br/>a realizar, la opci&#243;n de liquidarlo anticipadamente y el derecho a desbloquearlo. <br/></p>
<p>20. Los procedimientos expeditos (eficientes y r&#225;pidos) para solicitar la suspensi&#243;n <br/>y/o cancelaci&#243;n de los servicios, as&#237; como el bloqueo del IMEI del equipo en <br/>caso de robo y/o extrav&#237;o, de conformidad con la normatividad aplicable. <br/></p>
<p>Una vez que reportaste tu equipo como robado o extraviado, el proveedor no podr&#225; <br/>seguirte cobrando el servicio durante los 10 d&#237;as naturales siguientes a tu reporte, <br/>siempre y cuando no hagas uso del servicio. <br/></p>
<p>21. Previo a la contrataci&#243;n de los servicios adicionales proporcionados por el <br/>proveedor o servicios adicionales proporcionados por terceros, el costo, las <br/>caracter&#237;sticas del servicio y los mecanismos disponibles para su cancelaci&#243;n. <br/></p>
<p>22. D&#243;nde puedes consultar el aviso de privacidad, mismo que debe establecer de <br/>manera clara la forma en que utilizar&#225;n tus datos personales. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>23. Si eres usuario prepago, tu proveedor debe informarte a trav&#233;s del empaque que <br/>contiene tu chip o equipo terminal, la p&#225;gina de Internet en donde puedes <br/>consultar el contrato de adhesi&#243;n que te aplica y, en su caso, la car&#225;tula, los <br/>mecanismos para manifestar el consentimiento, la mec&#225;nica de activaci&#243;n del <br/>servicio y la aceptaci&#243;n de los t&#233;rminos y condiciones. <br/></p>
<p>III. CONDICIONES DE CONTRATACI&#211;N, CLARAS, JUSTAS Y EQUITATIVAS <br/></p>
<p>24. El contrato y su car&#225;tula deben estar en idioma espa&#241;ol, con caracteres legibles <br/>a simple vista y contener el objeto del contrato, las principales caracter&#237;sticas del <br/>servicio contratado y las condiciones aplicables, mismas que deber&#225;n ser <br/>equitativas y no discriminatorias. <br/></p>
<p>25. Al momento de contratar tienes derecho a que se te presente la car&#225;tula del <br/>contrato de adhesi&#243;n y te la entreguen por escrito. <br/></p>
<p>El proveedor con el cual contrataste es el &#250;nico responsable de las obligaciones <br/>asumidas, por lo que no puede trasladarlas a terceros. <br/></p>
<p>26. El contrato de adhesi&#243;n y su car&#225;tula deber&#225;n estar disponibles en la p&#225;gina de <br/>Internet de tu proveedor y contener cuando menos: la descripci&#243;n del servicio <br/>que contratar&#225;s, las obligaciones de las partes, los t&#233;rminos y condiciones bajo <br/>las cuales se prestar&#225;n, tarifas registradas, las penas convencionales, los <br/>supuestos de terminaci&#243;n y modificaci&#243;n, los medios disponibles de atenci&#243;n a <br/>clientes y sus horarios. <br/></p>
<p>El contrato tambi&#233;n deber&#225; contener las especificaciones t&#233;cnicas y comerciales, <br/>incluyendo la calidad, la cual no deber&#225; ser contraria a los par&#225;metros definidos por el <br/>IFT y, en su caso, velocidad del servicio de telecomunicaciones contratada, as&#237; como <br/>las causales de suspensi&#243;n o interrupci&#243;n del servicio. <br/></p>
<p>27. Tienes derecho a que se te indiquen los d&#237;as y horas h&#225;biles para la instalaci&#243;n, <br/>la cual no podr&#225; ser mayor a 10 (diez) d&#237;as h&#225;biles. Para ello te debe entregar un <br/>folio para el seguimiento de la entrega y/o instalaci&#243;n del equipo y te debe <br/>indicar un rango de horario no mayor a 5 horas para efectuar la visita. <br/></p>
<p>28. Cuando el proveedor te solicite una garant&#237;a para asegurar el cumplimiento de <br/>las obligaciones, si no tienes adeudo alguno al terminar tu contrato, tienes <br/>derecho a que el proveedor te la devuelva o la cancele. En consecuencia, te <br/>deber&#225; informar de la existencia de la garant&#237;a que hayas otorgado, as&#237; como el <br/>mecanismo para la devoluci&#243;n de la misma. <br/></p>
<p>29. Tienes derecho a exigir el cumplimiento forzoso del contrato de adhesi&#243;n <br/>conforme a lo contratado o impl&#237;cito en la publicidad o informaci&#243;n publicada. <br/></p>
<p>30. Tienes derecho a que en un plazo no mayor a 5 (cinco) d&#237;as h&#225;biles, tu <br/>proveedor de servicios te confirme lo que hayas contratado de manera verbal o <br/>electr&#243;nica. <br/></p>
<p>Modificaciones del contrato </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>31. Tienes derecho a que te notifiquen de cualquier cambio en las condiciones <br/>originalmente contratadas. El contrato &#250;nicamente se podr&#225; modificar con tu <br/>consentimiento y previo aviso a trav&#233;s de medios f&#237;sicos o de cualquier otra <br/>tecnolog&#237;a que lo permita, para lo cual deber&#225; notificarte con al menos 15 <br/>(quince) d&#237;as naturales de anticipaci&#243;n. <br/></p>
<p>32. Tienes derecho a que te notifiquen la terminaci&#243;n anticipada del contrato con la <br/>finalidad de sustituirlo por otro nuevo. El proveedor debe obtener tu <br/>consentimiento expreso. <br/></p>
<p>33. Si contratas a plazo forzoso, el proveedor no puede modificar el plazo, precio o <br/>tarifa contratada bajo ning&#250;n supuesto, a menos de que te genere un beneficio. <br/></p>
<p>34. Tienes derecho a exigir el cumplimiento forzoso en los t&#233;rminos acordados o a <br/>solicitar dentro de los 30 (treinta) d&#237;as naturales siguientes al aviso de <br/>modificaci&#243;n, la rescisi&#243;n sin penalizaci&#243;n alguna y con derecho a la bonificaci&#243;n <br/>correspondiente, cuando no hayas otorgado tu consentimiento expreso para <br/>modificarlo o sustituirlo. En este caso, el proveedor se har&#225; acreedor a una <br/>penalizaci&#243;n. <br/></p>
<p>35. Tienes derecho a cambiarte de paquete o plan, aunque sea de menor monto, o <br/>cancelarlo de manera anticipada, pagando en su caso, el costo remanente de tu <br/>equipo o los cargos adicionales que se generen. <br/></p>
<p>Contrato registrado ante PROFECO e inscrito en el IFT. <br/></p>
<p>36. El contrato de adhesi&#243;n debe estar autorizado y registrado ante la PROFECO y <br/>posteriormente inscrito ante el IFT, el cual deber&#225; de coincidir con el que te <br/>entregue el proveedor y estar disponible en su portal de Internet. Cualquier <br/>alteraci&#243;n al mismo no ser&#225; v&#225;lida. <br/></p>
<p>37. Tienes derecho a que el contrato de adhesi&#243;n tenga condiciones claras, justas y <br/>equitativas como penas razonables por terminaci&#243;n anticipada, entre otros. <br/></p>
<p>Vigencia <br/></p>
<p>38. Tienes derecho a que el contrato establezca la vigencia y cuando esta sea a un <br/>plazo forzoso, los supuestos por los cuales se te obliga a dicho plazo. <br/></p>
<p>En ning&#250;n caso el proveedor deber&#225; obligarte a renovar tu contrato de plazo forzoso de <br/>manera autom&#225;tica. <br/></p>
<p>39. En el esquema de prepago, no te podr&#225;n imponer plazo forzoso alguno. <br/>40. En el esquema de prepago, tienes derecho a que te abonen en tu pr&#243;xima <br/></p>
<p>recarga el saldo que no utilizaste durante el periodo de su vigencia, siempre y <br/>cuando la realices dentro del a&#241;o siguiente y le sea aplicable al servicio. <br/></p>
<p>Terminaci&#243;n del Contrato <br/></p>
<p>41. Tienes derecho a que se te informe sobre los requisitos y procedimientos <br/>detallados para la cancelaci&#243;n de los servicios y sobre cualquier cargo que se <br/>pueda generar por la terminaci&#243;n anticipada del contrato. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>42. Tienes derecho a terminar el contrato de manera anticipada en cualquier <br/>momento y atendiendo las penas convencionales que correspondan mediante <br/>mecanismos expeditos (eficientes y r&#225;pidos), incluidos los medios f&#237;sicos o <br/>electr&#243;nicos o digitales o de cualquier otra tecnolog&#237;a que lo permita, y que te <br/>sea otorgado un folio o registro de cancelaci&#243;n, que podr&#225; ser entregado por el <br/>medio que solicites. <br/></p>
<p>43. En el esquema de pospago, una vez concluido el plazo forzoso, puedes dar por <br/>terminado en cualquier momento el contrato sin necesidad de recabar la <br/>autorizaci&#243;n del proveedor, lo cual no te libera de pagar tus adeudos. <br/></p>
<p>44. En el esquema de pospago, es tu derecho que el proveedor de servicios te <br/>comunique de manera fehaciente, a trav&#233;s de la factura, mensaje de texto o <br/>medios f&#237;sicos o electr&#243;nicos o digitales o de cualquier otra tecnolog&#237;a que lo <br/>permita, que el plazo forzoso est&#225; por concluir con al menos 30 (treinta) d&#237;as <br/>naturales de anticipaci&#243;n. <br/></p>
<p>45. Tienes derecho a negarte a la instalaci&#243;n o activaci&#243;n del servicio si el personal <br/>del proveedor no se identifica o muestra la orden de trabajo, sin responsabilidad <br/>alguna para ti. <br/></p>
<p>46. Tienes derecho a cancelar tu contrato si el proveedor no instala o activa el <br/>servicio en los tiempos convenidos o establecidos, y a la devoluci&#243;n del monto <br/>total pagado por concepto de anticipo, dep&#243;sito, instalaci&#243;n o renta dentro de los <br/>10 (diez) d&#237;as h&#225;biles siguientes a tu solicitud de cancelaci&#243;n. <br/></p>
<p>47. En el caso de prepago en el servicio m&#243;vil, tienes derecho a que el proveedor te <br/>describa en el contrato el ciclo de vida del n&#250;mero asignado para recibir el <br/>servicio, es decir, cuando se encuentre activo, se suspenda o se cancele. <br/></p>
<p>Tu servicio solo podr&#225; ser cancelado cuando no tengas saldo pendiente de ser <br/>abonado, o bien, si transcurren 365 (trescientos sesenta y cinco) d&#237;as naturales a partir <br/>de la fecha de su vencimiento. <br/></p>
<p>Penas razonables, proporcionales, rec&#237;procas y equitativas. <br/></p>
<p>48. Tienes derecho a que las penas por terminaci&#243;n anticipada y por suspensi&#243;n <br/>temporal del servicio por falta de pago establecidas en el contrato de adhesi&#243;n <br/>sean razonables, rec&#237;procas, equitativas y proporcionales. Estas no deben <br/>superar el monto insoluto (adeudo pendiente) de la obligaci&#243;n principal. En <br/>ning&#250;n caso el proveedor podr&#225; exigir doble penalizaci&#243;n por el mismo <br/>incumplimiento. <br/></p>
<p>Servicios adicionales <br/></p>
<p>49. Cuando solicites la contrataci&#243;n de servicios de costo adicional proporcionados <br/>por terceros, el proveedor debe obtener tu consentimiento expreso previamente <br/>e informarte: <br/></p>
<p>Que la responsabilidad en la prestaci&#243;n de dicho servicio es exclusiva del <br/>tercero y te deber&#225; indicar los datos del proveedor como: nombre comercial, <br/>domicilio y tel&#233;fono de atenci&#243;n, descripci&#243;n de los servicios, tarifas vigentes, </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>restricciones, t&#233;rminos, condiciones, limitaciones aplicables, procedimientos de <br/>cobro y facturaci&#243;n. <br/></p>
<p>Que los cobros se realizar&#225;n con cargo a la factura del proveedor. <br/></p>
<p>Los mecanismos disponibles para la cancelaci&#243;n del servicio. <br/></p>
<p>50. Si solicitas la cancelaci&#243;n de servicios adicionales proporcionados por el <br/>proveedor o servicios adicionales proporcionados por terceros, esta surtir&#225; <br/>efectos a los 5 (cinco) d&#237;as naturales posteriores a la solicitud sin que ello <br/>implique la cancelaci&#243;n del servicio principal. <br/></p>
<p>Renovaci&#243;n del contrato <br/></p>
<p>51. Tienes derecho a que cuando renueves tu contrato de servicio y no adquieras un <br/>nuevo equipo o dispositivo, el pago est&#233; integrado solamente por las tarifas <br/>registradas ante el IFT aplicables a los servicios que renueves sin contemplar <br/>monto alguno por el equipo. El proveedor debe integrar la factura o estado de <br/>cuenta &#250;nicamente con el costo de los servicios contratados. <br/></p>
<p>Tarifas registradas ante el IFT <br/></p>
<p>52. Tienes derecho a que te respeten el precio o la tarifa contratada ofrecida o <br/>convenida y que coincida con la registrada y publicada ante el IFT. <br/></p>
<p>53. Tienes derecho a que no se te incremente injustificadamente el precio por <br/>fen&#243;menos naturales, meteorol&#243;gicos o contingencias sanitarias. <br/></p>
<p>Devoluciones y Bonificaciones <br/></p>
<p>54. En caso de cobros indebidos o haber hecho pagos en exceso, el proveedor <br/>deber&#225; devolver la cantidad dentro de 5 (cinco) d&#237;as h&#225;biles siguientes a la <br/>reclamaci&#243;n. <br/></p>
<p>Si no lo hace, adem&#225;s de la sanci&#243;n que corresponda, estar&#225; obligado a pagar los <br/>intereses. <br/></p>
<p>Tienes hasta un a&#241;o para solicitar la devoluci&#243;n. <br/></p>
<p>55. Tienes derecho a la bonificaci&#243;n o descuento por fallas en el servicio o cargos <br/>indebidos atribuibles al proveedor conforme a lo contratado o cuando as&#237; lo <br/>determine la autoridad. <br/></p>
<p>56. Tienes derecho a que el proveedor te compense la parte proporcional del <br/>servicio, plan o paquete de telecomunicaciones que se dej&#243; de prestar, y como <br/>bonificaci&#243;n, al menos el veinte por ciento del monto del periodo de afectaci&#243;n <br/>en la prestaci&#243;n del servicio de telecomunicaciones, cuando el proveedor no te <br/>preste el servicio conforme a lo contratado o impl&#237;cito en la publicidad o porque <br/>no te preste el servicio por causas atribuibles al proveedor. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>El proveedor debe establecer en el contrato e informarte sobre los mecanismos para <br/>compensar y/o bonificar. <br/></p>
<p>IV. DESBLOQUEO DE EQUIPO TERMINAL <br/></p>
<p>57. Tienes derecho a solicitar y obtener el desbloqueo del equipo terminal de <br/>manera gratuita sin que te exijan mayores requisitos que tu solicitud, cuando <br/>concluya la vigencia del contrato o se haya liquidado su costo o venza el plazo <br/>inicial de contrataci&#243;n o de financiamiento, debi&#233;ndote proporcionar la clave de <br/>desbloqueo. <br/></p>
<p>Los proveedores de servicio deben contar con mecanismos &#225;giles que permitan realizar <br/>el desbloqueo del equipo o dispositivo en un plazo m&#225;ximo de 24 horas, siempre y <br/>cuando la solicitud se haya realizado en un d&#237;a y hora h&#225;bil. <br/></p>
<p>Cuando se realice en d&#237;a y hora inh&#225;bil, se inicia la cuenta a partir del siguiente d&#237;a <br/>h&#225;bil. En caso de negativa, el proveedor debe informarte las causas por escrito. <br/></p>
<p>V. PORTABILIDAD DEL N&#218;MERO TELEF&#211;NICO <br/></p>
<p>58. Tienes derecho a cambiarte gratuitamente de compa&#241;&#237;a telef&#243;nica conservando <br/>tu n&#250;mero. <br/></p>
<p>59. Tienes derecho a solicitar y recibir tu NIP de confirmaci&#243;n para realizar la <br/>portabilidad, su entrega no deber&#225; condicionarse a tener saldo o que el servicio <br/>no se encuentre suspendido. <br/></p>
<p>60. Tienes derecho a que la portabilidad se realice durante las 24 horas siguientes a <br/>tu solicitud o dentro de los siguientes 5 (cinco) d&#237;as h&#225;biles si as&#237; lo decides. <br/></p>
<p>61. Es tu derecho que al solicitar la portabilidad num&#233;rica se cancele <br/>autom&#225;ticamente el servicio de telefon&#237;a, pero no los dem&#225;s servicios que <br/>tengas contratados. <br/></p>
<p>Ning&#250;n contrato puede contener condiciones o pr&#225;cticas que limiten tu derecho a <br/>cambiarte de compa&#241;&#237;a conservando tu mismo n&#250;mero. <br/></p>
<p>62. En caso de que no se cumplan los plazos de portabilidad correspondientes, <br/>tienes derecho a recibir el pago de las penas convencionales previstas en el <br/>contrato. <br/></p>
<p>63. Tienes derecho a acceder al sistema de informaci&#243;n para consultar tu tr&#225;mite de <br/>portabilidad y que el proveedor te otorgue la informaci&#243;n necesaria para la <br/>consulta. <br/></p>
<p>64. Al realizar tu portabilidad, tu anterior proveedor no podr&#225; contactarte para <br/>retenerte u ofrecerte sus servicios. <br/></p>
<p>65. Tienes derecho a cancelar la portabilidad en cualquier momento, o bien, en caso <br/>de que no se realice, la cancelaci&#243;n debe ser sin ninguna penalizaci&#243;n. <br/></p>
<p>66. Tienes derecho a solicitar la portabilidad aun si tienes adeudos con el proveedor, <br/>pero no te exenta de pagarlos y cubrir las penalidades que apliquen, as&#237; como a <br/>devolver los equipos que no sean de tu propiedad. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>67. La portabilidad sin tu consentimiento est&#225; prohibida, tienes derecho a solicitar a <br/>tu proveedor recupere tu n&#250;mero dentro de los 15 (quince) d&#237;as h&#225;biles <br/>siguientes a la fecha en que se port&#243; sin tu consentimiento. <br/></p>
<p>68. Tienes derecho a recibir orientaci&#243;n para realizar tu portabilidad o recuperaci&#243;n <br/>de tu n&#250;mero. <br/></p>
<p>69. Cuando hayas cancelado tu servicio, tienes derecho a la recuperaci&#243;n de tu <br/>n&#250;mero telef&#243;nico dentro de los 40 (cuarenta) d&#237;as naturales siguientes, para <br/>ello, debes acudir con tu nuevo proveedor. <br/></p>
<p>70. Tienes derecho a realizar cambios de domicilio de los servicios contratados <br/>conservando tu n&#250;mero, siempre y cuando el proveedor cuente con cobertura y <br/>capacidad para garantizar la continuidad de tu servicio. <br/></p>
<p>VI. EQUIPOS TERMINALES Y GARANT&#205;AS <br/></p>
<p>Equipos terminales <br/></p>
<p>71. Tienes derecho a que el proveedor te informe si el equipo terminal de tu <br/>propiedad o el que &#233;l te proporcione se encuentran homologados conforme a las <br/>disposiciones jur&#237;dicas aplicables, as&#237; como a que te informe si el equipo <br/>terminal de tu propiedad es apto para la prestaci&#243;n de sus servicios. <br/></p>
<p>Garant&#237;as <br/></p>
<p>72. Tienes derecho a que el equipo terminal nuevo o reconstruido que te <br/>proporcione el proveedor, cuente con una garant&#237;a no menor a 90 (noventa) d&#237;as <br/>naturales, y contar con la capacidad t&#233;cnica para repararlo. <br/></p>
<p>73. Tienes derecho a que el proveedor o el fabricante, te entregue por escrito y en <br/>espa&#241;ol, un documento conocido como p&#243;liza de garant&#237;a, la cual debe de <br/>contener: los conceptos que cubre, limitaciones o excepciones, as&#237; como los <br/>procedimientos para hacerla efectiva. <br/></p>
<p>74. El proveedor deber&#225; informarte si la vigencia de la garant&#237;a es menor al plazo <br/>forzoso y si el equipo falla fuera del periodo de garant&#237;a, deber&#225; indicarte el <br/>proceso para repararlo. <br/></p>
<p>Reparaciones <br/></p>
<p>75. Tienes derecho a que cuando el equipo sea reparado, no se descuente de la <br/>garant&#237;a el tiempo que dure la reparaci&#243;n y, en el caso de reposici&#243;n del equipo, <br/>se renueve el plazo de la garant&#237;a. Cuando el bien haya sido reparado se iniciar&#225; <br/>la garant&#237;a respecto de las piezas repuestas y continuar&#225; con relaci&#243;n al resto. <br/></p>
<p>76. Tienes derecho a que te suspendan el cobro del servicio contratado durante la <br/>revisi&#243;n y reparaci&#243;n. No procede lo anterior, cuando el proveedor acredite que <br/>est&#225;s haciendo uso del servicio con un equipo propio o por uno proporcionado <br/>por el proveedor. <br/></p>
<p>77. Si tu proveedor te proporcion&#243; el equipo en arrendamiento o comodato debe <br/>hacerse responsable de las reparaciones durante el tiempo que dure el contrato, <br/>cuando las fallas no te sean atribuibles. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>Restituci&#243;n de equipos <br/></p>
<p>78. Tienes derecho a la restituci&#243;n del equipo terminal, la rescisi&#243;n del contrato o la <br/>reducci&#243;n del precio y, en cualquier caso, la bonificaci&#243;n o compensaci&#243;n, <br/>cuando el equipo terminal tenga defectos o vicios ocultos que hagan impropio su <br/>uso. <br/></p>
<p>VII. ACCESIBILIDAD Y NO DISCRIMINACI&#211;N <br/></p>
<p>Acceso en igualdad de condiciones <br/></p>
<p>79. Tienes derecho a que los proveedores te presten los servicios sin establecer <br/>privilegios o distinciones respecto de otros consumidores en la misma &#225;rea de <br/>cobertura y en las mismas condiciones de contrataci&#243;n. <br/></p>
<p>80. En la prestaci&#243;n de los servicios de telecomunicaciones estar&#225; prohibida toda <br/>discriminaci&#243;n motivada por origen &#233;tnico o nacional, g&#233;nero, edad, <br/>discapacidad, condici&#243;n social, condiciones de salud, religi&#243;n, opiniones, <br/>preferencias sexuales, estado civil o cualquier otra que atente contra la dignidad <br/>humana y tenga por objeto anular o menoscabar los derechos y libertades de las <br/>personas. <br/></p>
<p>De las personas con discapacidad <br/></p>
<p>81. Tienes derecho a solicitar y recibir atenci&#243;n y asesor&#237;a en igualdad de <br/>condiciones que las dem&#225;s personas usuarias sobre el uso de los servicios. <br/></p>
<p>Para lo anterior, los proveedores te deber&#225;n ofrecer mecanismos que te permitan <br/>suscribir contratos, inconformidades u otros documentos sin que recaben tu firma <br/>aut&#243;grafa, ya sea con el uso de tu huella digital o que te asistas de un tercero <br/>debidamente acreditado. <br/></p>
<p>Centros de atenci&#243;n <br/></p>
<p>82. Las instalaciones del proveedor deben contar con adaptaciones y personal <br/>capacitado para atender a personas con discapacidad. <br/></p>
<p>El personal debe conocer al menos: los derechos de los usuarios con discapacidad, <br/>recomendaciones de atenci&#243;n por tipo de discapacidad e informaci&#243;n de equipos <br/>terminales. <br/></p>
<p>83. Tienes derecho a que los centros de atenci&#243;n al p&#250;blico de tu proveedor cuenten <br/>con lo siguiente: <br/></p>
<p>Una ruta, &#225;rea y se&#241;alizaci&#243;n accesible <br/></p>
<p>Permitir el acceso acompa&#241;ado por animales de servicio <br/></p>
<p>&#193;reas de espera con asientos prioritarios </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>Personal capacitado <br/></p>
<p>Informaci&#243;n Accesible <br/></p>
<p>84. Tienes derecho a conocer las condiciones de los contratos y las tarifas de los <br/>servicios en formatos con funcionalidades de accesibilidad a trav&#233;s de las <br/>p&#225;ginas de Internet de los proveedores o por correo electr&#243;nico cuando lo <br/>solicites. <br/></p>
<p>85. Tienes derecho a solicitar a tu proveedor de servicios, te proporcione tus <br/>estados de cuenta con funcionalidades de accesibilidad. <br/></p>
<p>P&#225;ginas de Internet accesibles <br/></p>
<p>86. Tienes derecho a que los portales de Internet de los proveedores del servicio <br/>cuenten con funcionalidades de accesibilidad. <br/></p>
<p>Equipos y dispositivos accesibles <br/></p>
<p>87. Tienes derecho a que los proveedores cuenten, una vez que as&#237; lo hayas <br/>solicitado, con equipos terminales con funcionalidades de accesibilidad para <br/>personas con discapacidad motriz, visual y auditiva, y que te proporcionen el <br/>equipo de tu elecci&#243;n dentro de los 15 (quince) d&#237;as h&#225;biles siguientes a tu <br/>solicitud. <br/></p>
<p>El proveedor est&#225; obligado a contar con un cat&#225;logo de equipos terminales que cuenten <br/>con funcionalidades de accesibilidad. <br/></p>
<p>VIII. PROTECCI&#211;N DE DATOS PERSONALES Y PRIVACIDAD <br/></p>
<p>Datos personales <br/></p>
<p>88. Tienes derecho a que el proveedor proteja tus datos personales en t&#233;rminos de <br/>las leyes aplicables. <br/></p>
<p>Aviso de privacidad <br/></p>
<p>89. Tienes derecho a que el proveedor ponga a tu disposici&#243;n el Aviso de Privacidad <br/>por cualquier medio o tecnolog&#237;a que lo permita, as&#237; como indicarte donde lo <br/>puedes consultar. <br/></p>
<p>En el esquema de prepago, al activar la l&#237;nea, aceptas que el proveedor utilice tus <br/>datos personales de conformidad con su Aviso de Privacidad, a menos que con <br/>posterioridad y de manera expresa manifiestes lo contrario. <br/></p>
<p>90. El proveedor solo podr&#225; transferir tu informaci&#243;n a terceros con tu <br/>consentimiento expreso, por lo que deber&#225; poner a tu disposici&#243;n el Aviso de <br/>Privacidad para que puedas ejercer tus derechos referidos en la Ley Federal de <br/>Protecci&#243;n de Datos Personales en Posesi&#243;n de los Particulares. As&#237; tambi&#233;n el </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>proveedor solo podr&#225; utilizar tu informaci&#243;n con fines mercadot&#233;cnicos y <br/>publicitarios o para el env&#237;o de publicidad sobre bienes, productos o servicios, <br/>con tu consentimiento expreso. Este consentimiento estar&#225; expresado en el <br/>contrato de adhesi&#243;n, en un documento previsto para tal efecto, o por el mismo <br/>medio por el cual se realiz&#243; la contrataci&#243;n o por medios electr&#243;nicos con <br/>posterioridad a la contrataci&#243;n. <br/></p>
<p>Publicidad <br/></p>
<p>91. Solo con tu consentimiento podr&#225;s recibir llamadas de tu proveedor para <br/>promocionar servicios adicionales al contratado, paquetes, nuevo plan o <br/>producto, propio o de terceros, as&#237; como publicidad de terceros. <br/></p>
<p>Si deseas revocar el consentimiento, el proveedor debe hacerlo en un tiempo m&#225;ximo <br/>de 5 (cinco) d&#237;as h&#225;biles despu&#233;s de tu solicitud. <br/></p>
<p>92. La informaci&#243;n y publicidad que te proporcionen debe ser clara, comprobable, <br/>completa, veraz y no debe inducir a errores o confusiones. <br/></p>
<p>93. Tienes derecho a que los anuncios de las promociones y ofertas de los servicios <br/>indiquen condiciones, plazo de duraci&#243;n o el volumen de los servicios ofrecidos. <br/>Si no indican plazo ni volumen se presume que son indefinidas. <br/></p>
<p>IX. CALIDAD Y NEUTRALIDAD EN LA RED <br/></p>
<p>Calidad en el servicio <br/></p>
<p>94. Tienes derecho a recibir servicios de calidad conforme a los par&#225;metros <br/>establecidos por el IFT o, en su caso, los ofrecidos impl&#237;citamente o contratados <br/>los cuales no pueden ser inferiores a los definidos por el IFT. <br/></p>
<p>95. Tienes derecho a que se te provean los servicios con los par&#225;metros de calidad <br/>definidos por el IFT. <br/></p>
<p>Para el caso del servicio m&#243;vil en los servicios de voz, mensajer&#237;a de texto y <br/>transferencia de datos se miden: los intentos de llamadas fallidas, llamadas <br/>interrumpidas, tiempo de establecimiento de llamadas, integridad de mensajes, <br/>velocidad de datos promedio de carga y descarga, entre otros. <br/></p>
<p>Para el caso de servicio fijo, en la telefon&#237;a y el acceso a Internet se miden: los intentos <br/>exitosos de llamadas, el tiempo promedio de establecimiento de llamada, la velocidad <br/>de datos promedio de carga y descarga, entre otros. Asimismo, se miden las fallas, <br/>reparaciones y el tiempo promedio de reparaci&#243;n. <br/></p>
<p>En los servicios de Internet, tienes derecho a que la velocidad publicitada no debe <br/>referirse a velocidades m&#225;ximas sino a la velocidad promedio en horas de alta <br/>demanda. <br/></p>
<p>Neutralidad de la red </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>96. Tienes derecho a acceder sin limitaci&#243;n, degradaci&#243;n, restricci&#243;n o <br/>discriminaci&#243;n a contenidos, aplicaciones o servicios ofrecidos por el proveedor, <br/>dentro del marco legal aplicable. <br/></p>
<p>97. Tienes derecho a que tu proveedor no obstruya, interfiera, inspeccione, filtre o <br/>discrimine contenidos, aplicaciones o servicios. <br/></p>
<p>98. Tienes derecho a la protecci&#243;n de tus comunicaciones, as&#237; como los datos que <br/>identifiquen las mismas y que el proveedor preserve tu privacidad y la seguridad <br/>de la red. <br/></p>
<p>99. Tienes derecho a recibir la capacidad, velocidad y calidad que contrataste con <br/>independencia del contenido, origen, destino, terminal, aplicaci&#243;n o servicio que <br/>se te provean a trav&#233;s de Internet. <br/></p>
<p>X. FACTURACI&#211;N <br/></p>
<p>100. Tienes derecho a que tu proveedor te entregue gratuitamente, de forma <br/>desglosada, a trav&#233;s de medios f&#237;sicos o electr&#243;nicos: factura, estado de cuenta, <br/>recibo o comprobante de los servicios de telecomunicaciones y, en su caso, de <br/>los equipos terminales adquiridos. <br/></p>
<p>101. Tienes derecho a que el estado de cuenta, recibo y/o factura que te <br/>entregue el proveedor est&#233; desglosado en lo correspondiente a tus consumos, <br/>bonificaciones o devoluciones, y cuando adquieras un equipo terminal de <br/>contado o financiado, se debe incluir en la factura la compra de dicho equipo. <br/></p>
<p>Tu proveedor no puede cobrarte tarifas o conceptos diferentes a los que originalmente <br/>contrataste o aceptaste expresamente. <br/></p>
<p>102. Para el esquema de pospago, se podr&#225; consultar la factura, estado de <br/>cuenta, as&#237; como el recibo o comprobante, por cualquier medio que acuerden las <br/>partes, y el proveedor deber&#225; enviarlo cuando menos con 10 (diez) d&#237;as <br/>naturales antes de la fecha de pago y no podr&#225; modificar el ciclo de facturaci&#243;n <br/>sin previo aviso. <br/></p>
<p>En el estado de cuenta, recibo y/o factura que te entregue el proveedor, debe desglosar <br/>el costo del servicio y el costo del equipo, adem&#225;s de incluir en ellos cada uno de tus <br/>consumos y cargos, tales como: fecha y hora de llamadas, su duraci&#243;n en minutos o <br/>segundos, megas, mensajes, llamadas de larga distancia internacional, equipos <br/>terminales, servicios adicionales, y dem&#225;s cargos realizados, as&#237; como la fecha de <br/>corte y lugares de pago. <br/></p>
<p>103. Para el esquema de prepago, el proveedor te debe informar d&#243;nde <br/>consultar el estado de cuenta o detalle de consumos el cual debe contener la <br/>descripci&#243;n de los cargos, costos, conceptos y naturaleza de los servicios <br/>prestados. La entrega del estado de cuenta es a solicitud del usuario. <br/></p>
<p>Suspensi&#243;n del servicio </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>104. Tienes derecho a que el proveedor te notifique antes de la suspensi&#243;n de <br/>tu servicio y la raz&#243;n por la que se realiza dicha suspensi&#243;n. <br/></p>
<p>105. En caso de falta de pago de servicios adicionales, tu proveedor no debe <br/>suspender el servicio de telecomunicaciones principal. <br/></p>
<p>106. El servicio no podr&#225; ser interrumpido o suspendido si se encuentra en <br/>tr&#225;mite una queja. <br/></p>
<p>107. Cuando est&#233;s al corriente con tus pagos y el proveedor suspenda de <br/>forma indebida el servicio, tienes derecho a que se te devuelva el monto por el <br/>servicio no prestado y a una bonificaci&#243;n de al menos el veinte por ciento. <br/></p>
<p>Restablecimiento del servicio y reconexi&#243;n <br/></p>
<p>108. En caso de suspensi&#243;n por falta de pago, el proveedor deber&#225; reanudar el <br/>servicio de telecomunicaciones en un periodo m&#225;ximo de 48 horas a partir de <br/>que hayas realizado el pago o un m&#225;ximo de 72 horas cuando la reconexi&#243;n <br/>requiera de personal t&#233;cnico. En algunos casos aplican cargos por reconexi&#243;n. <br/></p>
<p>Cobro por duraci&#243;n de las comunicaciones <br/></p>
<p>109. Tienes derecho a que en los servicios medidos por duraci&#243;n de las <br/>comunicaciones, el proveedor te ofrezca la opci&#243;n de cobro por segundo, sin <br/>perjuicio de que tambi&#233;n te ofrezca la opci&#243;n de cobro por minuto, por evento, <br/>por capacidad o cualquier otra modalidad. <br/></p>
<p>XI. ROBO Y EXTRAV&#205;O &#8211; SERVICIOS DE EMERGENCIA <br/></p>
<p>Robo y extrav&#237;o <br/></p>
<p>110. Tienes derecho a acceder a un mecanismo expedito para reportar el robo <br/>o extrav&#237;o de tu equipo y a reportar la duplicaci&#243;n del IMEI de tu equipo, por lo <br/>que consecuentemente tienes derecho a solicitar la suspensi&#243;n y/o cancelaci&#243;n <br/>del servicio, as&#237; como el bloqueo o la suspensi&#243;n del IMEI; ello en el caso de <br/>equipos m&#243;viles. <br/></p>
<p>111. Tienes derecho a consultar si un equipo terminal m&#243;vil se encuentra <br/>reportado como robado o extraviado y por ello no deber&#225; ser activado en <br/>ninguna de las redes de los operadores. <br/></p>
<p>112. Tienes derecho a que una vez que reportaste tu equipo como robado o <br/>extraviado, el proveedor no podr&#225; seguirte cobrando el servicio durante los 10 <br/>(diez) d&#237;as naturales siguientes a tu reporte, siempre y cuando no hagas uso del <br/>servicio. <br/></p>
<p>Servicios de emergencia <br/></p>
<p>113. Tienes derecho a acceder gratuitamente al n&#250;mero de emergencia 911 el <br/>cual permitir&#225; identificar y ubicar geogr&#225;ficamente tu llamada y, en su caso, los <br/>mensajes de texto que se env&#237;en, para ser auxiliado. <br/></p>
<p>XII. ATENCI&#211;N GRATUITA DEL PROVEEDOR </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>114. Tienes derecho a acceder gratuitamente, las 24 horas del d&#237;a, todos los <br/>d&#237;as del a&#241;o, a un sistema de atenci&#243;n telef&#243;nica o electr&#243;nico del proveedor, en <br/>el que podr&#225;s realizar cualquier tipo de consulta, tr&#225;mites, presentar dudas, <br/>aclaraciones, cancelaciones y reclamaciones sobre el servicio que se te <br/>proporciona por el medio que tu decidas. <br/></p>
<p>115. Cuando elijas hablar con un representante del proveedor en los sistemas <br/>de atenci&#243;n telef&#243;nica, tienes derecho a que el tiempo de espera para que te <br/>atiendan no exceda de 60 segundos, salvo en casos fortuitos o de fuerza mayor. <br/></p>
<p>116. Tienes derecho a ser informado del procedimiento y estado de los <br/>tr&#225;mites que has realizado ante el proveedor, en el momento en el que lo <br/>solicites, respet&#225;ndose los tiempos promedio y m&#225;ximos de atenci&#243;n. <br/></p>
<p>117. Tienes derecho a recibir atenci&#243;n de personal debidamente capacitado y <br/>que el proveedor te garantice que la informaci&#243;n y orientaci&#243;n que requieres es <br/>certera. <br/></p>
<p>118. Tienes derecho a recibir una constancia o folio de las reclamaciones, <br/>solicitudes o tr&#225;mites que realices y a acceder a un sistema del proveedor que te <br/>permita conocer su estatus. <br/></p>
<p>119. En el caso de las quejas recibidas en los sistemas de atenci&#243;n del <br/>proveedor, este deber&#225; de atender las mismas en un plazo no mayor a 15 <br/>(quince) d&#237;as naturales, salvo que exista un tiempo m&#225;ximo establecido en otra <br/>normatividad. <br/></p>
<p>Cuando hayas presentado una queja v&#237;a telef&#243;nica, o por v&#237;a electr&#243;nica (chat en l&#237;nea <br/>o correo electr&#243;nico), tienes derecho a recibir un documento que acredite la <br/>presentaci&#243;n y contenido de la reclamaci&#243;n, incidencia o gesti&#243;n. <br/></p>
<p>XIII. TELEVISI&#211;N DE PAGA <br/></p>
<p>Recibir gratuitamente las se&#241;ales de televisi&#243;n abierta (TV Abierta) <br/></p>
<p>120. Tienes derecho a recibir gratuitamente las se&#241;ales de los canales de TV <br/>Abierta que se radiodifundan dentro de tu zona de cobertura geogr&#225;fica y, las <br/>se&#241;ales de los canales transmitidos por Instituciones P&#250;blicas Federales, si tu <br/>servicio es de televisi&#243;n de paga. <br/></p>
<p>Bloqueo de aplicaciones o servicios <br/></p>
<p>121. Tienes derecho a que los proveedores bloqueen los contenidos, <br/>aplicaciones o servicios que solicites. Podr&#225;s realizar esta petici&#243;n en los <br/>sistemas de atenci&#243;n del proveedor. <br/></p>
<p>Control parental <br/></p>
<p>122. Tienes derecho a que los proveedores te proporcionen un servicio de <br/>control parental previa solicitud de tu parte, y que publiquen de manera clara las <br/>caracter&#237;sticas operativas de este servicio y las instrucciones para que puedas <br/>operar las aplicaciones necesarias para su correcto funcionamiento. </p>

</div></div>
<div style="page-break-before:always; page-break-after:always"><div><p>AXE REDES E INFRAESTRUCTURA S.A DE C.V. <br/></p>
<p>XIV. PROTECCI&#211;N DE LAS INSTITUCIONES <br/></p>
<p>123. Tienes derecho a la protecci&#243;n y representaci&#243;n de la PROFECO y del <br/>IFT, en el &#225;mbito de sus respectivas atribuciones, para asegurar que tus <br/>derechos sean respetados, con vistas a la prevenci&#243;n de da&#241;os patrimoniales <br/>individuales y/o colectivos, garantizando tu protecci&#243;n jur&#237;dica, econ&#243;mica, <br/>administrativa y t&#233;cnica. <br/></p>
<p>La PROFECO y el IFT podr&#225;n efectuar las verificaciones y procedimientos <br/>correspondientes, en el &#225;mbito de sus atribuciones y, en su caso, iniciar un <br/>procedimiento de sanci&#243;n. <br/></p>
<p>124. Tienes derecho a que la PROFECO, promueva, proteja, asesore, <br/>defienda, concilie y te represente frente a tu proveedor cuando presentes una <br/>queja o denuncia por el incumplimiento de alguna de las cl&#225;usulas contenidas en <br/>el contrato de adhesi&#243;n. <br/></p>
<p>125. Tienes derecho a presentar una queja ante el Instituto Federal de <br/>Telecomunicaciones por fallas en la calidad de los servicios o incumplimientos <br/>de los operadores. <br/></p>
<p>Lo anterior, porque el IFT regula, monitorea y vigila la calidad de los servicios o <br/>incumplimientos de los operadores (concesionarios, autorizados o proveedores) a sus <br/>obligaciones. <br/></p>
<p>Representaci&#243;n colectiva <br/></p>
<p>126. Tienes derecho a ser representado en una acci&#243;n colectiva ante el <br/>incumplimiento de un proveedor. <br/></p>
<p>Para buscar que se declare que el proveedor incurri&#243; en conductas que da&#241;aron tus <br/>intereses y tu patrimonio y que lo condenen a la indemnizaci&#243;n de da&#241;os y perjuicios. <br/>El representante de tal colectividad o grupo puede ser PROFECO, una asociaci&#243;n de <br/>consumidores registrada o bien un grupo de por lo menos 30 consumidores afectados <br/>que firmen la demanda u otorguen su consentimiento para ser representados <br/>gratuitamente por la PROFECO. <br/></p>
<p> </p>

</div></div>
</body></html>`;
