import React from "react";

export default function TextListComponent({
  items,
  title,
}: {
  items: string[];
  title: string;
}) {
  return (
    <>
      {" "}
      <p className="font-semibold">{title}</p>
      <ul className="list-disc ">
        {items?.map((item: any, index: number) => (
          <li key={index} className="ml-10">
            <p className="text-sm">{item}</p>
          </li>
        ))}
      </ul>
    </>
  );
}
