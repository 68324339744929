import React from "react";
import CustomerValidationCard from "components/molecules/Checkout/CustomerValidationCard";
import ProductValidationCard from "components/molecules/Checkout/ProductValidationCard";
import PaymentSourceValidation from "components/molecules/Checkout/PaymentSourceValidation";
import InstallationValidation from "components/molecules/Checkout/InstallationValidation";
import useFlowInstance from "hooks/useFlowInstance";
import usePaynet from "hooks/usePaynet";
import { Instance } from "../Checkout/types/types";
import { getSessionItem } from "utils/session";
import useReferralCodeServices from "hooks/referralCodes/useReferralCodeServices";
import ReferralCodeValidationCard from "components/molecules/Checkout/ReferralCodeValidationCard";
import { isEmpty } from "lodash";

//Iconst
export default function InstanceValidationSales({
  instance,
}: {
  instance: Instance;
}) {
  const { createFlowInstanceCheckoutHiringUrl, updateFlowInstance } =
    useFlowInstance();

  const [paymentMethod, setPaymentMethod] = React.useState<any>({});
  const [referralCodeData, setReferralCodeData] = React.useState<any>({});
  const [referralCode, setReferralCode] = React.useState<any>("");
  const [validReferralCode, setValidReferralCode] =
    React.useState<boolean>(false);

  const { getPaymentMethod } = usePaynet();
  const { getReferralCode } = useReferralCodeServices();

  const onHandleReferralCodeData = async (code: string) => {
    const responde = await onFetchReferralCodeData(code);
    if (!isEmpty(responde)) {
      const updatedFlowInstance = await onFetchSetReferralCodeData(code);
      if (updatedFlowInstance.success) {
        sessionStorage.setItem("referralCode", code);
      }
    }
  };

  const onFetchSetReferralCodeData = async (code: string) => {
    const response = await updateFlowInstance(
      {
        referralCode: code,
      },
      true
    );
    return response;
  };

  const onFetchReferralCodeData = async (code: string) => {
    setValidReferralCode(false);
    setReferralCodeData({});
    const referralCodeData = await getReferralCode({
      query: code,
      fields: "type,value,customerId,customer,referralCode",
    });
    if (!isEmpty(referralCodeData)) {
      setReferralCodeData(referralCodeData);
      setValidReferralCode(true);
      return referralCodeData;
    }
    return setValidReferralCode(false);
  };

  React.useEffect(() => {
    async function getData() {
      if (instance?.payment?.paymentMethod) {
        const pm = await getPaymentMethod(instance.payment.paymentMethod);
        setPaymentMethod(pm);
      }
      if (instance?.referralCode) {
        setReferralCode(instance?.referralCode);
        await onFetchReferralCodeData(instance?.referralCode);
      }
    }

    getData();
  }, [instance]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-12">
        <ProductValidationCard
          offer={instance?.offer}
          paymentMethodType={instance?.payment?.paymentMethodType}
        />
      </div>
      <div className="col-span-12 lg:col-span-12">
        <ReferralCodeValidationCard
          referralCode={referralCode}
          setReferralCode={setReferralCode}
          validReferralCode={validReferralCode}
          data={referralCodeData}
          onHandleValidate={onHandleReferralCodeData}
        />
      </div>
      <div className="col-span-12 lg:col-span-12 ">
        <InstallationValidation data={instance?.installation} />
      </div>
      <div className="col-span-12 lg:col-span-12 ">
        <CustomerValidationCard
          data={instance?.prospect}
          location={instance?.location}
        />
      </div>
      <div className="col-span-12 flex justify-end lg:justify-center">
        <p className="text-sm text-red-500 rounded-md p-2 w-fit bg-red-100">
          {`Para finalizar la contratación, comparte el link con ${instance?.prospect?.firstName} ${instance?.prospect?.lastName}`}
        </p>
      </div>
      <div className="col-span-12 flex justify-end lg:justify-center">
        <button
          onClick={() => {
            createFlowInstanceCheckoutHiringUrl();
          }}
          className=" w-full lg:w-2/4 flex items-center justify-center text-white font-bold py-2 px-4 rounded primary-btn"
        >
          GUARDAR Y CONTINUAR
        </button>
      </div>
    </div>
  );
}
