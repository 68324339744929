export const stripeErrors: any = {
  card_declined: "La tarjeta fue rechazada.",
  expired_card: "La tarjeta ha caducado.",
  incorrect_cvc: "El código de seguridad de tu tarjeta es incorrecto.",
  incorrect_number: "El número de tarjeta es incorrecto.",
  invalid_cvc: "El código de seguridad no es válido.",
  invalid_expiry_month: "El mes de expiración no es válido.",
  invalid_expiry_year: "El año de expiración no es válido.",
  invalid_number: "El número de tu tarjeta no es válido.",
  missing: "No se ha encontrado la tarjeta.",
  insuficient_funds: "Tu tarjeta no tiene fondos suficientes.",
  processing_error:
    "Ha ocurrido un error durante el procesamiento de la tarjeta.",
  rate_limit: "Ha ocurrido un error debido a un límite de tasa.",
  insufficient_funds: "Tu tarjeta no tiene fondos suficientes.",
};
