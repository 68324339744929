import React from 'react'
import { privateAxiosNetVault } from 'utils/axios'
import { urls } from 'utils/urls'

const useNetvault = () => {
  async function prereservatePort(lat: number, lng: number) {
    try {
      const { data: response } = await privateAxiosNetVault.post(
        urls.API_NETVAULT_prereservatePort,
        {
          lat: lat,
          lng: lng
        }
      )
      return response
    } catch (error) {
      console.error(error)
      console.log(error)
    }
  }

  async function getCoverage(lat: number, lng: number) {
    try {
      const { data: response } = await privateAxiosNetVault.get(
        urls.API_NETVAULT_getCoverage,
        {
          params: {
            lat: lat,
            lng: lng
          }
        }
      )

      return response
    } catch (error) {
      console.error(error)
      console.log(error)
    }
  }

  return {
    prereservatePort,
    getCoverage
  }
}

export default useNetvault
