import React from "react";

const features = [
  {
    name: "iPhone 13 Pro Max",
    label: "10 clientes que contraten",
    description:
      "Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.",
    icon: (
      <img
        src="https://stanitroprodscus001.blob.core.windows.net/sales-images/smartphone-3.png"
        width="43%"
      ></img>
    ),
  },
  {
    name: "iPhone 13",
    label: "5 clientes que contraten",
    description:
      "Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.",
    icon: (
      <img
        src="https://stanitroprodscus001.blob.core.windows.net/sales-images/smartphone-4.png"
        width="40%"
      ></img>
    ),
  },
  {
    name: "Samsung Galaxy A34",
    label: "2 clientes que contraten",
    description:
      "Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.",
    icon: (
      <img
        src="https://stanitroprodscus001.blob.core.windows.net/sales-images/smartphone-1.png"
        width="40%"
      ></img>
    ),
  },
  {
    name: "Samsung Galaxy A04",
    label: "1 cliente que contrate",
    description:
      "Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.",
    icon: (
      <img
        src="https://stanitroprodscus001.blob.core.windows.net/sales-images/smartphone-2.png"
        width="40%"
      ></img>
    ),
  },
];

export default function CenteredGrid() {
  return (
    <div className="bg-white pt-20 pb-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className=" text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Invita a tus amigos y familiares que vivan en las zonas de cobertura
            a contratar <br />
            X-tremo y gánate un smartphone
          </h2>
        </div>
        <div className="mx-auto max-w-2xl mt-16  lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {feature.icon}
                </dt>
                <dt className="text-sm leading-7 text-gray-700">
                  {feature.name}
                </dt>
                <dd className=" text-lg font-semibold leading-7 ">
                  {feature.label}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
