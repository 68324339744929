import React from 'react'
import BasicChip from 'components/atoms/BasicChip'
import useProductsContext from 'hooks/useProductsContext'
import { getSessionItem } from 'utils/session'
import { HiOutlineCheckCircle, HiOutlineCheck } from 'react-icons/hi2'
import TextListComponent from '../TextListComponent'

export default function PackageItemCard({
  data,
  index = 0,
  isSelected = false,
  style = {}
}: {
  data: any
  index: number
  isSelected: boolean
  style: any
}) {
  const { setSelectedOffer } = useProductsContext()
  const flowType = getSessionItem('flowType')
  const handleSelect = (offer: any) => {
    setSelectedOffer(offer)
  }
  return (
    <div
      className='cursor-pointer w-full '
      onClick={() => {
        if (!isSelected) {
          handleSelect(data)
        }
      }}
    >
      <div
        className='w-full h-full p-5 border border-gray-200 rounded-lg rounded shadow-sm  basic-card'
        style={isSelected ? { border: '3px solid #00063d' } : {}}
      >
        <br />
        <div className='flex items-center justify-center gap-3'>
          <h3 className='font-semibold text-2xl '>{data?.name}</h3>{' '}
          {isSelected && (
            <HiOutlineCheckCircle className=' text-sky-600	text-4xl' />
          )}
        </div>

        {flowType !== 'real_estate' && (
          <div className='w-full flex justify-end mt-6 mb-3 text-justify	'>
            <p className='text-xs text-cyan-700 secondary_text '>
              {data?.description || ''}
            </p>
          </div>
        )}

        {!data?.siteFeatures && (
          <ul className='mt-3 mb-3'>
            {data?.features?.map((feature: any) => (
              <li className='text-sm flex gap-2 items-center'>
                {feature} <HiOutlineCheck />{' '}
              </li>
            ))}
          </ul>
        )}

        {flowType !== 'real_estate' && (
          <>
            {data?.siteFeatures && (
              <TextListComponent
                items={data?.siteFeatures}
                title={
                  data?.type === 'business' ? 'Sitios con:' : 'Hogares con:'
                }
              />
            )}
          </>
        )}

        <br />
        {data?.includedFeatures && (
          <TextListComponent
            items={data?.includedFeatures}
            title='Qué Incluye'
          />
        )}

        <div className='w-full flex justify-end mt-6'>
          <BasicChip background='transparent'>
            <p className=' 	text-black text-sm '>
              Renta mensual{' '}
              <strong className='text-2xl primary_text'>
                ${data?.stripeExpandOptions?.prices?.grossAmount}
              </strong>{' '}
              pesos
            </p>
            <p className='text-sm text-right'>IVA incluido</p>
          </BasicChip>
        </div>
        {data?.coupon && (
          <p className='text-sm text-black text-right font-semibold '>
            {data?.coupon?.name}
          </p>
        )}
      </div>
    </div>
  )
}
